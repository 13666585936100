import * as types from "../types/types";

const initialState = {
    notificationMessages: null,
    notificationMessagesErrors: {},
    notificationOrders: null,
    notificationOrdersErrors: {},
    notificationReviews: null,
    notificationReviewsErrors: {},

    notificationCart: null,
    notificationCartError: {},
};

function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_NOTIFICATION_MESSAGES:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    notificationMessages: null,
                };
            } else {
                return {
                    ...state,
                    notificationMessages: action.payload.length,
                }
            }
        case types.FAILED_GET_NOTIFICATION_MESSAGES:
            return {
                ...state,
                notificationMessagesErrors: action.payload
            };
        case types.CLOSE_NOTIFICATION_MESSAGES:
            return {
                ...state,
                notificationMessages: null,
            };
        case types.GET_NOTIFICATION_ORDERS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    notificationOrders: null,
                };
            } else {
                return {
                    ...state,
                    notificationOrders: action.payload.length,
                }
            }
        case types.FAILED_GET_NOTIFICATION_ORDERS:
            return {
                ...state,
                notificationOrdersErrors: action.payload
            };
        case types.CLOSE_NOTIFICATION_ORDERS:
            return {
                ...state,
                notificationOrders: null,
            };
        case types.GET_NOTIFICATION_REVIEWS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    notificationReviews: null,
                };
            } else {
                return {
                    ...state,
                    notificationReviews: action.payload.length,
                }
            }
        case types.FAILED_GET_NOTIFICATION_REVIEWS:
            return {
                ...state,
                notificationReviewsErrors: action.payload
            };
        case types.CLOSE_NOTIFICATION_REVIEWS:
            return {
                ...state,
                notificationReviews: null,
            };

        case types.GET_NOTIFICATION_CART:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    notificationCart: null,
                };
            } else {
                return {
                    ...state,
                    notificationCart: action.payload.length,
                }
            }
        case types.FAILED_GET_NOTIFICATION_CART:
            return {
                ...state,
                notificationCartErrors: action.payload
            };
        case types.CLOSE_NOTIFICATION_CART:
            return {
                ...state,
                notificationCart: null,
            };
        default:
            return state;
    }
}

export default notificationReducer;