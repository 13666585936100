// material-ui
import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function Savonnerie() {

    return (
        <MainCard title="L'Art de la Savonnerie : Histoire et Techniques Modernes">

            <Helmet>
                <title>L&apos;Artisanat de la Savonnerie : De l&apos;Histoire à la Saponification à Froid</title>
                <meta name="description" content="Découvrez l&apos;histoire riche de la savonnerie et comment les méthodes modernes, notamment la saponification à froid, renouvellent cette tradition ancestrale. Explorez les techniques qui respectent l&apos;environnement et la peau." />
            </Helmet>

            <Typography variant="h4" component="h1" gutterBottom>
                Introduction à la Savonnerie
            </Typography>
            <Typography variant="body1" paragraph>
                La fabrication de savon est un art ancien qui remonte à des milliers d&apos;années. Initialement considérée comme une nécessité pratique pour l&apos;hygiène, elle est devenue une forme d&apos;artisanat respectant à la fois la tradition et l&apos;innovation.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Histoire de la Savonnerie
            </Typography>
            <Typography variant="body1" paragraph>
                Les premières traces de fabrication de savon datent de l&apos;ancienne Babylone, où des documents mentionnent la cuisson de savon à partir de graisses et de cendres. Au fil des siècles, cette technique s&apos;est raffinée à travers le monde, intégrant des huiles locales et des parfums, transformant la savonnerie en un véritable art.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                La Saponification à Froid : Une Méthode Moderne
            </Typography>
            <Typography variant="body1" paragraph>
                Parmi les techniques modernes, la saponification à froid est particulièrement prisée pour ses nombreux bénéfices écologiques et dermatologiques. Ce processus permet de conserver la glycérine naturelle du savon, offrant une meilleure hydratation pour la peau et réduisant l&apos;impact environnemental en évitant l&apos;usage excessif de chaleur et d&apos;énergie.
            </Typography>

            <Typography variant="body1" paragraph>
                Les savonniers modernes utilisent une variété d&apos;huiles végétales et essentielles pour créer des savons qui ne sont pas seulement nettoyants, mais aussi nourrissants et thérapeutiques. Chaque savon est unique, souvent enrichi d&apos;ingrédients locaux et biologiques qui apportent leurs propres propriétés bénéfiques.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Implications Écologiques et Culturelles
            </Typography>
            <Typography variant="body1" paragraph>
                Adopter la saponification à froid reflète un engagement envers des pratiques durables. De plus, cette méthode soutient les économies locales en privilégiant les fournisseurs régionaux pour les huiles et autres composants essentiels, renforçant ainsi la culture locale et le patrimoine artisanal.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Conclusion
            </Typography>
            <Typography variant="body1" paragraph>
                L&apos;art de la savonnerie est une célébration de la tradition, de l&apos;innovation, et de la responsabilité écologique. En choisissant des savons fabriqués par des méthodes respectueuses de l&apos;environnement, les consommateurs participent à une histoire qui favorise la santé de la planète et de ses habitants.
            </Typography>

            <Typography variant="body1" paragraph>
                Nous vous invitons à découvrir et à soutenir les artisans savonniers de votre région, pour explorer les merveilles de la savonnerie artisanale et ses effets bénéfiques tant pour l&apos;individu que pour la communauté.
            </Typography>

            <Typography variant="body1" paragraph>
                Retrouvez nos producteurs de savons <Link size="small" href="/">ici</Link>.
            </Typography>

        </MainCard>
    );
}