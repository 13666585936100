// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomePortraitHistoireLocale() {

    return (
      <>
        <Helmet>
          <title>Portraits et Histoires Locales | Boutigou</title>
          <meta name="description" content="Rencontrez les visages derrière les produits locaux sur Boutigou. Plongez dans les histoires inspirantes de nos producteurs et artisans." />
        </Helmet>
        <MainCard title="Portraits et Histoires Locales, kesako ?" sx={{ mb: 4 }}>
          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Le Muscadet : Entre Tradition et Innovation dans le Vignoble Nantais
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/vin-muscadet-tradition-innovation">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    L&apos;Histoire du Crémant de Loire
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/histoire-cremant-de-loire">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Une pomme par jour : mythe ou réalité ?
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/une-pomme-par-jour-mythe-ou-realite">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Vin local : exploration des vignobles et variétés régionales
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/vins-locaux">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
          </Grid>
        </MainCard>
      </>
  );
}