// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeMetier() {

    return (
      <>
        <Helmet>
          <title>Découvrez les Métiers Traditionnels et Modernes | Boutigou</title>
          <meta name="description" content="Explorez la richesse des métiers traditionnels et modernes sur Boutigou. Découvrez les secrets, les techniques et les histoires derrière chaque profession." />
        </Helmet>
        <MainCard title="Les métiers, kesako ?" sx={{ mb: 4 }}>

          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Créateur d&apos;Artisanat : Bijoux, Bougies, Tissus et Plus
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/metier-createur-artisanat-bijoux-bougies-tissus">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Découvrez le Métier de Biscuitier : Un Art Entre Tradition et Innovation
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/metier-biscuitier-artisanat-innovation">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    L&apos;Apiculteur : Gardien des Abeilles et Architecte de la Biodiversité
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/metier-apiculteur-artisan-biodiversite">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    L&apos;Artisanat de la Savonnerie : De l&apos;Histoire à la Saponification à Froid
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/art-de-la-savonnerie-methode-a-froid">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Le Vigneron : Artisan de la Terre et du Vin
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/metier-vigneron-tradition-innovation">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Les Agriculteurs Locaux et la Vente Directe : Un Lien Renforcé avec les Consommateurs
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/agriculteurs-locaux-vente-directe">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Producteur de Conserves : Maîtrise des Saveurs et Diversité des Produits
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/metier-producteur-conserves-saveurs">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </MainCard>
      </>
  );
}