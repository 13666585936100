import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sortByDistance } from 'sort-by-distance';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, TextField, OutlinedInput, InputAdornment, Autocomplete, useMediaQuery, IconButton, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Popper from '@mui/material/Popper';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import product from 'assets/images/aboutus/product.png';
import geolocation from 'assets/images/aboutus/geolocation.png';
import order from 'assets/images/aboutus/order.png';
import payment from 'assets/images/aboutus/payment.png';

// assets
import { IconSearch } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';

// project imports
import ShopCard from './ShopCard';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  paddingLeft: 16,
  paddingRight: 16,
  transition: 'transform 0.3s ease', // Ajoute une transition pour le zoom
  '&:hover': {
    transform: 'scale(1.05)', // Agrandit légèrement l'input lors du survol
  },
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 4,
    background: '#fff'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home(props) {

    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const location = useSelector((state) => state.location);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [searchData, setSearchData] = React.useState([]);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    
    const carouselItems = [
      {
        img: geolocation,
        alt: "Carte des producteurs locaux proximité",
        description: (
          <span>
            Découvrez et <strong>connectez-vous avec des producteurs locaux</strong> près de chez vous pour des achats directs <strong>sans intermédiaires</strong>. Explorez la carte pour trouver des produits <strong>frais et authentiques</strong>.
          </span>
        )
      },
      {
        img: payment,
        alt: "Client payant directement un producteur",
        description: (
          <span>
            Profitez de la <strong>flexibilité des paiements en ligne</strong> ou optez pour un paiement direct sur place <strong>sans commission</strong>. Avec Boutigou, le producteur reçoit l&apos;<strong>intégralité de votre paiement</strong>, soutenant ainsi l&apos;<strong>économie locale</strong>.
          </span>
        )
      },
      {
        img: product,
        alt: "Producteur préparant des commandes de produits frais",
        description: (
          <span>
            Vos producteurs locaux préparent vos commandes avec <strong>soin</strong>, garantissant <strong>qualité et fraîcheur</strong>. Chaque achat soutient les <strong>méthodes de culture durable</strong> et renforce la <strong>communauté locale</strong>.
          </span>
        )
      },
      {
        img: order,
        alt: "Consommateur récupérant des produits locaux",
        description: (
          <span>
            Récupérez vos produits <strong>fraîchement récoltés</strong> directement chez le producteur ou au point de collecte le plus proche. Profitez de produits <strong>locaux, frais et de saison</strong>, tout en <strong>réduisant votre empreinte carbone</strong>.
          </span>
        )
      }
    ];

    const slidesToShow = isXs ? 1 : isMd ? 2 : 4;

    const totalItems = carouselItems.length;
    const lastIndex = totalItems - 1;

    const handlePrev = () => {
      setActiveIndex(prev => (prev === 0 ? lastIndex : prev - 1));
    };

    const handleNext = () => {
      setActiveIndex(prev => (prev === lastIndex ? 0 : prev + 1));
    };

    const generateCarouselSlice = () => {
      let sliceStart = activeIndex;
      let sliceEnd = activeIndex + slidesToShow;
      
      if (sliceEnd > totalItems) {
        return [...carouselItems.slice(sliceStart, totalItems), ...carouselItems.slice(0, sliceEnd - totalItems)];
      } else {
        return carouselItems.slice(sliceStart, sliceEnd);
      }
    };

    React.useEffect(() => {
      document.title = `Achetez Directement Chez des Producteurs Locaux - Fraîcheur et Qualité Garanties sur Boutigou`
      if (isInitialMount.current) {
        isInitialMount.current = false;
        props.getShops(props.dispatch);
      }
    }, []);

    const [openGeoloc, setOpenGeoloc] = React.useState(false);
    const [address, setAddress] = React.useState('');
    const [addressOptions, setAddressOptions] = React.useState([]);
    const [addressFullOptions, setAddressFullOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
      if (location.location) {
        if (!location.location.properties) {
          setOpenGeoloc(true)
        }
      }
      if (!location.location) {
        setOpenGeoloc(true)
      }
    }, [location]);

    React.useEffect(() => {
      if (location.location) {
        if (location.location.properties) {
          const opts = {
            yName: 'latitude',
            xName: 'longitude'
          };
          const origin = { longitude: location.location.geometry ? location.location.geometry.coordinates[0] : 0, latitude: location.location.geometry ? location.location.geometry.coordinates[1] : 0 }
          setData(sortByDistance(origin, props.homeRed.shops, opts));
        }
      }
    }, [location]);

    React.useEffect(() => {
      if (location.location) {
        if (location.location.properties) {
          const opts = {
            yName: 'latitude',
            xName: 'longitude'
          };
          const origin = { longitude: location.location.geometry ? location.location.geometry.coordinates[0] : 0, latitude: location.location.geometry ? location.location.geometry.coordinates[1] : 0 }
          setData(sortByDistance(origin, props.homeRed.shops, opts));
        } else {
          setData(props.homeRed.shops)
        }
      } else {
        setData(props.homeRed.shops)
      }
    }, [props.homeRed.shops]);

    const handleAddressChange = (e) => {
      setAddress(e.target.value);
      axios
        .get(`https://api-adresse.data.gouv.fr/search/?q=${e.target.value}`)
        .then((response) => {
          setAddressOptions(response.data.features.map((a) => {return {'label': a.properties.label, 'id': a.properties.id}}));
          setAddressFullOptions(response.data.features);
        })
        .catch((error) => {
          console.error('Erreur lors de la requête :', error);
        });
    }

    const handleAddressValidate = () => {
      dispatch({ type: 'GET_LOCATION', payload: addressFullOptions.find((feature) => feature.properties.id === address.id )})
      setOpenGeoloc(false)
    };

    const searchItems = (e) => {
      console.log(e)
      setSearch(e)
      let searchTerm = e.toLowerCase();
      setSearchData(props.homeRed.shops.filter(item => {
        const categoryMatch = item.category.toLowerCase().includes(searchTerm);
        const descriptionMatch = item.description.toLowerCase().includes(searchTerm);
        const nameMatch = item.name.toLowerCase().includes(searchTerm);
        const addressMatch = item.address.toLowerCase().includes(searchTerm);
        const cityMatch = item.city.toLowerCase().includes(searchTerm);
        const zipcodeMatch = item.zipcode.toLowerCase().includes(searchTerm);
        const productsMatch = item.products.toLowerCase().includes(searchTerm);
        const product_categoriesMatch = item.product_categories.toLowerCase().includes(searchTerm);
        return categoryMatch || descriptionMatch || nameMatch || addressMatch || cityMatch || zipcodeMatch || productsMatch || product_categoriesMatch;
      }));
    }


    React.useEffect(() => {
      if (location.location) {
        if (location.location.properties) {
          const opts = {
            yName: 'latitude',
            xName: 'longitude'
          };
          const origin = { longitude: location.location.geometry ? location.location.geometry.coordinates[0] : 0, latitude: location.location.geometry ? location.location.geometry.coordinates[1] : 0 }
          setData(sortByDistance(origin, searchData, opts));
        } else {
          setData(searchData)
        }
      } else {
        setData(props.homeRed.shops)
      }
    }, [searchData]);

    return (
    <Grid container spacing={gridSpacing} justifyContent="center">
      <Dialog
        open={openGeoloc}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="location"
      >
        <DialogTitle variant="h2">{"Renseignez votre zone de recherche?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Cela permettra à Boutigou de vous proposer les producteurs et artisans autour de chez vous.
          </DialogContentText>
          <Autocomplete
            disablePortal
            noOptionsText="Pas de résultat"
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setAddress(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            sx={{ mt: 2}}
            options={addressOptions}
            PaperComponent={({ children }) => (
              <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ backgroundColor: 'rgba(255, 255, 255)', boxShadow: 'none',  zIndex: 999999 }}>
                <div style={{ overflow: 'visible' }}>{children}</div>
              </Popper>
            )}
            renderInput={(params) => <TextField {...params} label="Zone de recherche" onChange={handleAddressChange}  onFocus={(event) => setAnchorEl(event.currentTarget)}/>}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={address === null ? true : (address.id === undefined ? true : false)}
            variant="outlined"
            onClick={handleAddressValidate}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'transparent' }}>
          <Typography variant="h1" gutterBottom style={{ fontFamily: 'PinaColadaCreation, sans-serif' }}>Votre marché virtuel dédié aux produits locaux et artisanaux</Typography>
        </Paper>
      </Grid>

      <Grid item xs={9} justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'transparent' }}>
          <OutlineInputStyle
            id="input-search-header"
            value={search}
            onChange={(e) => {searchItems(e.target.value)}}
            placeholder="Rechercher un producteur, un produit, une catégorie..."
            startAdornment={
              <InputAdornment position="start">
                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
              </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
          {data.map((s, idx) => (
            <Grid key={idx} item lg={4} md={6} sm={6} xs={12}>
              <ShopCard
                loading={props.homeRed.loading}
                name={s.name}
                cover={s.cover}
                profile={s.profile}
                category={s.category}
                category_article={s.category_article}
                id={s.id}
                address={`${s.address}, ${s.zipcode} ${s.city}`}
                location={location}
                latitude={s.latitude}
                longitude={s.longitude}
                labels={s.labels}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={9} justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'transparent' }}>
          <Typography variant="h2" gutterBottom>Comment ça marche ?</Typography>
        </Paper>
        <Grid container spacing={2} sx={{ borderRadius: '4px', position: 'relative', alignItems: 'center' }} direction="row">
          <IconButton 
            onClick={handlePrev} 
            aria-label="previous" 
            sx={{ 
              position: 'absolute', 
              left: 0, 
              top: '50%', 
              transform: 'translateY(-50%)', 
              zIndex: 2 
            }}>
            <ChevronLeftIcon />
          </IconButton>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Paper style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', padding: '20px', margin: 'auto', maxWidth: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', transition: 'transform 0.5s ease' }}>
                  {generateCarouselSlice().map((item, index) => (
                    <Grid container key={index} sx={{ flex: '1 0 auto', width: `${100 / slidesToShow}%` }}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={item.img} alt={item.alt} style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ textAlign: 'center', mt: 2, paddingX: 5 }}>
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <IconButton 
            onClick={handleNext} 
            aria-label="next" 
            sx={{ 
              position: 'absolute', 
              right: 0, 
              top: '50%', 
              transform: 'translateY(-50%)', 
              zIndex: 2 
            }}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'transparent' }}>

          <Typography variant="h2" component="h2" gutterBottom>
            Qui sommes-nous ?
          </Typography>
          <Typography variant="body1" paragraph>
            Chez Boutigou, nous renforçons les liens entre consommateurs et producteurs locaux passionnés par leur métier. Notre plateforme facilite une connexion authentique avec ceux qui cultivent et créent des produits frais et artisanaux dans votre région. Rejoignez notre communauté pour une expérience d&apos;achat locale, directe et responsable.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Notre mission
          </Typography>
          <Typography variant="body1" paragraph>
            Pour le producteur ou l&apos;artisan : Mettre en valeur votre savoir-faire authentique ; Augmenter votre visibilité et toucher un public plus large dans votre région ; Simplifier la gestion de vos ventes grâce à notre plateforme intuitive.
          </Typography>
          <Typography variant="body1" paragraph>
            Pour le consommateur : Assurer la fraîcheur et la qualité des produits directement depuis les champs ; Faciliter l&apos;accès à des aliments sains et goûteux, cultivés à proximité ; Offrir une alternative pratique et écologique au supermarché avec le retrait en click and collect.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Notre vision
          </Typography>
          <Typography variant="body1" paragraph>
            Boutigou est dédié à tisser des liens solides entre les consommateurs et les producteurs locaux. Nous plaçons l&apos;humain et les échanges directs au cœur de notre projet, en offrant une place de marché où les produits locaux et les pratiques durables prennent toute leur importance.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Nos engagements
          </Typography>
          <Typography variant="body1" paragraph>
            Qualité et origine des produits : Chaque article proposé sur Boutigou provient de producteurs locaux engagés envers une agriculture raisonnée, souvent bio, garantissant transparence et traçabilité complète.
          </Typography>
          <Typography variant="body1" paragraph>
            Soutien à l&apos;économie locale : En choisissant le click and collect, vous aidez à dynamiser l&apos;économie de votre région, soutenant ainsi directement les artisans et agriculteurs locaux.
          </Typography>
          <Typography variant="body1" paragraph>
            Réduction de l&apos;empreinte carbone : Opter pour le click and collect diminue les besoins en transport longue distance, réduisant ainsi les émissions de CO2 et favorisant une approche plus verte.
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Comment ça fonctionne ?
          </Typography>
          <Typography variant="body1" paragraph>
            Choisissez vos produits en ligne sur Boutigou et récupérez-les directement chez le producteur. Notre système de réservation en ligne vous permet de sélectionner les produits frais du jour et de les retirer à un point de collecte localisé. Simple, rapide et écologique !
          </Typography>

          <Typography variant="h2" component="h2" gutterBottom>
            Rejoignez la cause
          </Typography>
          <Typography variant="body1" paragraph>
            Envisagez de visiter une petite exploitation agricole locale, de vous engager dans un abonnement CSA, ou de participer à des campagnes de sensibilisation. Chaque petit geste compte et peut faire une grande différence.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
