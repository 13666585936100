import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography, Stack, Chip, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  height: '100%',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.01) rotateY(5deg) rotateX(2deg)',
  },
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const EarningCard = ({ loading, name, cover, profile, category, category_article, id, address, location, latitude, longitude, labels }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateShop = (name) => {
    navigate(`/boutiques/${name}`);
  };

  const calculateDistance = (point1, point2) => {
    const R = 6371; // Rayon de la Terre en kilomètres

    const toRadians = (angle) => (angle * Math.PI) / 180;

    const dLat = toRadians(point2.latitude - point1.latitude);
    const dLon = toRadians(point2.longitude - point1.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(point1.latitude)) *
      Math.cos(toRadians(point2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance.toFixed(1);
  };

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateShop(`${id}`)}>
          <Box
            sx={{
              p: 2.25,
              cursor: 'pointer',
            }}
          >
            <Grid container direction="column">
              <Box
                sx={{
                  backgroundImage: `url(${cover.replace('http://django', '')})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: "100% 100%",
                  height: {xs: '150px', sm: '200px'},
                  backgroundPosition: "center center",
                  borderRadius: "12px",
                }}
              >
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        src={profile.replace('http://django', '')}
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.largeAvatar,
                          backgroundColor: 'transparent',
                          m: 1
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2} justifyContent="space-between" mt={1} mb={2}>
                <Grid item xs={12} lg={7} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, textAlign: {xs: 'center',  md: 'left'} }}>
                  <Typography variant="h3">{name}</Typography>
                </Grid>
                <Grid item xs={12} lg={5} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'} }}>
                  <Tooltip title="En savoir plus">
                    <Chip
                      label={category}
                      sx={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.secondary[200] }}
                      href={category_article}
                      clickable
                      component="a"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={1} mb={1.25}>
                <Grid item xs={12} lg={9} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'} }}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <LocationOnIcon sx={{ color: '#697586' }} />
                    <Typography 
                      variant="h4"
                      sx={{ textTransform: 'capitalize', color: '#697586' }}
                    >
                      <Box sx={{ textTransform: 'capitalize', }}>{address}</Box>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'} }}>
                  <Chip
                    label={`${calculateDistance(
                      { latitude: latitude, longitude: longitude },
                      {
                        longitude: location.location.geometry
                          ? location.location.geometry.coordinates[0]
                          : 0,
                        latitude: location.location.geometry
                          ? location.location.geometry.coordinates[1]
                          : 0,
                      }
                    )} km`}
                    sx={{ fontSize: '0.75rem', fontWeight: 300}}
                  />
                </Grid>
              </Grid>
              {labels.length !== 0 ? 
              <Grid
                container
                alignItems="center"
                spacing={1}
                mb={1.25}
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                }}
              >
                {labels.map((label) => (
                  <Grid item key={label.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Chip
                      avatar={<Avatar alt={label.name} src={label.logo ? label.logo.replace('http://django', '') : null} sx={{ objectFit: 'cover', backgroundColor: 'transparent' }} />}
                      label={label.name}
                      variant="outlined"
                      sx={{ overflow: 'auto' }}
                    />
                  </Grid>
                ))}
              </Grid> : null}
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

EarningCard.propTypes = {
  isLoading: PropTypes.bool
};

export default EarningCard;
