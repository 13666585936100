import * as React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ReviewCard = ({ loading, created_at, amount, amount_total, status, id, is_seen_consumer}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();

  const navigateOrderDetail = (id) => {
    navigate(`/commandes/${id}`);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const zoomScale = isHovered ? 1.01 : 1; 

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateOrderDetail(`${id}`)}>
          <Box
            sx={{
              p: 2.25,
              cursor: 'pointer',
              transform: `scale(${zoomScale})`,
              transition: 'transform 0.3s ease-in-out',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            backgroundColor={is_seen_consumer ? 'transparent' : '#DCDCDC'}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h3" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                      Commande du {moment(created_at).format('Do MMMM YYYY à H[h]mm')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '1.75rem', fontWeight: 500, mt: 0.50, mb: 0.75, color: theme.palette.secondary[200] }}>
                      Total produits: {parseFloat(amount).toFixed(2)/100}€
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" sx={{ flexWrap: 'wrap', textAlign: 'center', width: '100%' }}>
                  <Grid item>
                    <Typography sx={{ fontSize: '1.75rem', fontWeight: 500, mb: 0.75, color: theme.palette.secondary[200] }}>
                      Total avec commission Boutigou: {parseFloat(amount_total).toFixed(2)/100}€
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '1.75rem', fontWeight: 500, mb: 0.75, color: theme.palette.secondary[200] }}>
                      Statut: {status}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ReviewCard.propTypes = {
  isLoading: PropTypes.bool
};

export default ReviewCard;
