// assets
import { IconDashboard, IconHome, IconBrandMessenger, IconHeart, IconShoppingCart, IconStar, IconNotes, IconReportSearch } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconHome, IconBrandMessenger, IconHeart, IconShoppingCart, IconStar, IconNotes, IconReportSearch };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Général',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Boutiques',
      type: 'item',
      url: 'boutiques',
      icon: icons.IconHome,
      breadcrumbs: false,
    },
    {
      id: 'messages',
      title: 'Messages',
      type: 'item',
      url: 'conversations',
      icon: icons.IconBrandMessenger,
      breadcrumbs: false
    },
    {
      id: 'favorites',
      title: 'Favoris',
      type: 'item',
      url: 'favoris',
      icon: icons.IconHeart,
      breadcrumbs: false
    },
    {
      id: 'cart',
      title: 'Panier',
      type: 'item',
      url: 'panier',
      icon: icons.IconShoppingCart,
      breadcrumbs: false
    },
    {
      id: 'reviews',
      title: 'Mes Avis',
      type: 'item',
      url: 'avis',
      icon: icons.IconStar,
      breadcrumbs: false
    },
    {
      id: 'orders',
      title: 'Mes Commandes',
      type: 'item',
      url: 'commandes',
      icon: icons.IconNotes,
      breadcrumbs: false
    },
    {
      id: 'kesako',
      title: 'Kesako ?',
      type: 'item',
      url: 'kesako',
      icon: icons.IconReportSearch,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
