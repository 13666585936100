import * as React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import SpaIcon from '@mui/icons-material/Spa';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({

  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ShopProduct = ({ loading, name, picture, shop_name, id, shop_id, discounted }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);

  const navigateProduct = (shop_id, product_id) => {
    navigate(`/boutiques/${shop_id}/produits/${product_id}`);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const zoomScale = isHovered ? 1.01 : 1; 

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateProduct(`${shop_id}`, `${id}`)}>
          <Box
            sx={{
              p: 2.25,
              cursor: 'pointer',
              transform: `scale(${zoomScale})`,
              transition: 'transform 0.3s ease-in-out',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    position: "relative",
                    width: "90%",
                    margin: "auto",
                    paddingTop: "90%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: "auto",
                      maxWidth: '200px',
                      maxHeight: '200px',
                      backgroundImage: `url(${picture.replace('http://django', '')})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "12px",
                    }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sx={{ mb: 1.25 }}>
                      <Typography
                        sx={{
                          mt: 1.75,
                        }}
                        variant="h2"
                      >
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 1.25 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: '#697586',
                          mb: 0.75,
                        }}
                      >
                        Boutique: {shop_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          { discounted && (
          <Box
            position="absolute"
            bottom={0}
            right={0}
            padding={2}
          >
            <Tooltip title="Produit antigaspi">
              <IconButton style={{ color: '#4CAF50' }} onClick={(e) => {e.stopPropagation();}}>
                <SpaIcon sx={{fontSize: '50px' }}/>
              </IconButton>
            </Tooltip>
          </Box>)}
        </CardWrapper>
      )}
    </>
  );
};

ShopProduct.propTypes = {
  isLoading: PropTypes.bool
};

export default ShopProduct;
