import { connect } from "react-redux";

import OrderHistory from "../views/orderHistory";

import {
    getOrderHistories
} from "../actions/orderHistoryActions.js";

const mapStateToProps = state => ({
    orderHistoryRed: state.orderHistory
});

const mapDispatchToProps = dispatch => ({
    getOrderHistories: (dispatch) =>
        dispatch(getOrderHistories(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
