import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getOrderHistories(dispatch) {
    dispatch({
        type: types.LOADING_HISTORIES
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_history/`)
            .then((res) => { return dispatch({ type: types.GET_ORDER_HISTORIES, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_ORDER_HISTORIES, payload: error.response.data }) })
    };
}

function getOrderHistory(order_id, dispatch) {
  dispatch({
    type: types.LOADING_HISTORY
  });

  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/order_history/${order_id}`);
      dispatch({ type: types.GET_ORDER_HISTORY, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/orders';
      } else {
        dispatch({ type: types.FAILED_GET_ORDER_HISTORY, payload: error.response.data });
      }
    }
  };
}

function getOrderHistoryDetail(order_id, dispatch) {
  dispatch({
    type: types.LOADING_HISTORY
  });

  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/order_history/details/?order_id=${order_id}`);
      dispatch({ type: types.GET_ORDER_HISTORY_DETAIL, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/orders';
      } else {
        dispatch({ type: types.FAILED_GET_ORDER_HISTORY_DETAIL, payload: error.response.data });
      }
    }
  };
}

export {
    getOrderHistory,
    getOrderHistories,
    getOrderHistoryDetail
};