import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest, registerRequest } from "../../actions/authActions";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Chip, Avatar, Breadcrumbs, Link, Typography, Backdrop } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import ReviewCard from './ReviewCard';
import { gridSpacing } from 'store/constant';
import gif_loading from 'assets/images/gif_loading.gif';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Review(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);
    const { review_order_id } = useParams();

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    React.useEffect(() => {
        if (isInitialMount.current && auth.authenticated) {
            isInitialMount.current = false;
            props.getOrderReview(review_order_id, props.dispatch);
            props.getReview(review_order_id, props.dispatch);
        }
    }, []);

    React.useEffect(() => {
        if (props.reviewRed.success && auth.authenticated) {
            isInitialMount.current = false;
            props.getReview(review_order_id, props.dispatch);
        }
    }, [props.reviewRed.success]);

    return ( auth.authenticated ?
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Laissez un Avis sur Boutigou - Partagez Votre Expérience</title>
        <meta name="description" content="Partagez votre expérience sur les produits ou boutiques de Boutigou. Votre avis est précieux !" />
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.reviewRed.loading}
      >
        <Box
          component="img"
          sx={{
            height: 250,
            mb: 4
          }}
          alt="Loading..."
          src={gif_loading}
        />
      </Backdrop>
      <Box sx={{ p: 2.25 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/avis" variant="h4">
            Mes avis
          </Link>
          <Typography color="text.primary" variant="h4">Détails</Typography>
        </Breadcrumbs>
      </Box>
      <Grid item xs={12}>
        <ReviewCard
          loading={props.reviewRed.loading}
          rate={props.reviewRed.review.rate}
          content={props.reviewRed.review.content}
          created_at={props.reviewRed.review.created_at}
          response={props.reviewRed.review.response}
          answered_at={props.reviewRed.review.answered_at}
          review_id={props.reviewRed.review.id}
          shop_name={props.reviewRed.review.shop ? props.reviewRed.review.shop.name : null}
          order_id={review_order_id}
          props={props}
        />
      </Grid>
    </Grid> :
    <MainCard>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}