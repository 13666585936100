import { connect } from "react-redux";

import Cart from "../views/cart";

import {
    getCart,
    getCartDetails,
    editProductFromCart,
    removeProductFromCart,
    getPaymentMethodOptions,
    choosePaymentMethodOptions,
    getOrderDeliveryLocationOptions,
    getOrderDeliveryDayOptions,
    getOrderDeliverySlotOptions,
    getOrderPaymentIntent,
    getOrderDeliveryValidate,
    closeAlertCart,
    addOrderPaymentIntent,
    addOrder,
    failedCardOrder,
    closeOrderPaymentIntent,
    closeOrderPaymentSuccess
} from "../actions/orderActions.js";

const mapStateToProps = state => ({
    orderRed: state.order
});

const mapDispatchToProps = dispatch => ({
    getCart: (dispatch) =>
        dispatch(getCart(dispatch)),
    getCartDetails: (dispatch) =>
        dispatch(getCartDetails(dispatch)),
    editProductFromCart: (item, dispatch) =>
        dispatch(editProductFromCart(item, dispatch)),
    removeProductFromCart: (item, dispatch) =>
        dispatch(removeProductFromCart(item, dispatch)),
    getPaymentMethodOptions: (dispatch) =>
        dispatch(getPaymentMethodOptions(dispatch)),
    choosePaymentMethodOptions: (item, dispatch) =>
        dispatch(choosePaymentMethodOptions(item, dispatch)),
    getOrderDeliveryLocationOptions: (dispatch) =>
        dispatch(getOrderDeliveryLocationOptions(dispatch)),
    getOrderDeliveryDayOptions: (id, dispatch) =>
        dispatch(getOrderDeliveryDayOptions(id, dispatch)),
    getOrderDeliverySlotOptions: (id, day, date, dispatch) =>
        dispatch(getOrderDeliverySlotOptions(id, day, date, dispatch)),
    getOrderPaymentIntent: (dispatch) =>
        dispatch(getOrderPaymentIntent(dispatch)),
    getOrderDeliveryValidate: (begin, end, dispatch) =>
        dispatch(getOrderDeliveryValidate(begin, end, dispatch)),
    closeAlertCart: (dispatch) =>
        dispatch(closeAlertCart(dispatch)),
    addOrderPaymentIntent: (item, dispatch) =>
        dispatch(addOrderPaymentIntent(item, dispatch)),
    failedCardOrder: (dispatch) =>
        dispatch(failedCardOrder(dispatch)),
    addOrder: (item, dispatch) =>
        dispatch(addOrder(item, dispatch)),
    closeOrderPaymentIntent: (dispatch) =>
        dispatch(closeOrderPaymentIntent(dispatch)),
    closeOrderPaymentSuccess: (dispatch) =>
        dispatch(closeOrderPaymentSuccess(dispatch)),
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
