import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import Logo from 'ui-component/Logo';
import AuthReset from '../auth-forms/AuthReset';
import { resetNoRequest } from "../../../../actions/authActions";

// assets

// ===============================|| AUTH3 - REGISTER ||=============================== //

const Reset = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = async () => {
    dispatch(resetNoRequest(dispatch))
  };

  return (
    <Modal
      open={auth.requestReset}
      onClose={handleClose}
      sx={{ display:'flex',alignItems:'center',justifyContent:'center' }}
    >
      <AuthWrapper1>
        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
              <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                <AuthCardWrapper>
                  <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-end">
                        <IconButton aria-label="close" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Link to="#">
                        <Logo />
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                        <Grid item>
                          <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                              Renseignez votre email
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <AuthReset />
                    </Grid>
                  </Grid>
                </AuthCardWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AuthWrapper1>
    </Modal>
  );
};

export default Reset;
