import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getShops(dispatch) {
  dispatch({
    type: types.LOADING_GET_SHOPS
  });
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/shop/`);
      if (res.status === 404) {
        window.location.href = '/';
      } else {
        dispatch({ type: types.GET_SHOPS, payload: res.data });
      }
    } catch (error) {
      dispatch({ type: types.FAILED_GET_SHOPS, payload: error.response.data });
    }
  };
}


export {
    getShops
};
