import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Rating } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  height: '100%',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ReviewCard = ({ loading, consumer, content, created_at, rate, response, answered_at}) => {
  const theme = useTheme();

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" sx={{ flexWrap: 'wrap', textAlign: 'center', width: '100%' }}>
                  <Grid item>
                    <Typography variant="h3">{consumer} le {moment(created_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  alignItems="center">
                <Typography
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  < Rating name="half-rating-read" value={rate} precision={1} readOnly /> ({rate}/5)
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" sx={{ flexWrap: 'wrap', textAlign: 'center', width: '100%' }}>
                  <Grid item>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mr: 1, mt: 0.50, mb: 0.75, color: theme.palette.secondary[200] }}>{content}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              { response ?
              <Grid item>
                <Grid container alignItems="center" sx={{ flexWrap: 'wrap', textAlign: 'center', width: '100%' }}>
                  <Grid item>
                    <Typography variant="h3">Réponse le {moment(answered_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
                  </Grid>
                </Grid>
              </Grid> : null }
              { response ?
              <Grid item>
                <Grid container alignItems="center" sx={{ flexWrap: 'wrap', textAlign: 'center', width: '100%' }}>
                  <Grid item>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mr: 1, mb: 0.75, color: theme.palette.secondary[200] }}>{response}</Typography>
                  </Grid>
                </Grid>
              </Grid>              
              : null}
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ReviewCard.propTypes = {
  isLoading: PropTypes.bool
};

export default ReviewCard;
