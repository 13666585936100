import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest, registerRequest } from "../../actions/authActions";
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Chip, Avatar, List, ListItem, ListItemIcon, ListItemText, Divider, TextField, Fab, Paper, Typography, IconButton, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
//import ShopProduct from './ShopProduct';
import NoConversation from './NoConversation';
//import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Conversation(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const listRef = React.useRef();
    const [message, setMessage] = React.useState(null);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);
    const gridRef = React.useRef(null);
    const { conversation_id } = useParams();
    
    const navigate = useNavigate();
    const isXsOrSmOrMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const navigateConversation = (conv_id) => {
      navigate(`/conversations/${conv_id}`);
    };

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    React.useEffect(() => {
      if (listRef.current) {
        const list = listRef.current;
        list.scrollTop = list.scrollHeight;
      }
    }, [props.conversationRed.messages]);

    React.useEffect(() => {
      if (isInitialMount.current && auth.authenticated) {
        isInitialMount.current = false;
        props.getConversations(props.dispatch);
      }
    }, []);

    React.useEffect(() => {
      if (auth.authenticated) {
        isInitialMount.current = false;
        props.getConversations(props.dispatch);
      }
    }, [auth.authenticated]);

    React.useEffect(() => {
        if (conversation_id !== undefined && auth.authenticated) {
            isInitialMount.current = false;
            props.getConversations(props.dispatch);
            props.getConversation(conversation_id, props.dispatch);
        }
    }, [conversation_id]);

    React.useEffect(() => {
        if (props.conversationRed.successMessage === true) {
          props.getConversations(props.dispatch);
          props.getConversation(conversation_id, props.dispatch)
        }
    }, [props.conversationRed.successMessage]);

    React.useEffect(() => {
        if (props.conversationRed.conversation.shop !== undefined && auth.authenticated) {
            props.getMessages(conversation_id, props.conversationRed.conversation.shop.id, props.dispatch);
        }
    }, [props.conversationRed.conversation]);

    React.useEffect(() => {
    if (auth.authenticated) {
      const intervalId = setInterval(() => {
        if (props.conversationRed.conversation.shop !== undefined && auth.authenticated) {
            props.getMessagesSilently(conversation_id, props.conversationRed.conversation.shop.id, props.dispatch);
        }
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [auth.authenticated]);

    const handleSendMessage = () => {
        const item = {
            'content': message,
            'conversation': conversation_id,
            'shop_id': props.conversationRed.conversation.shop.id
            }
        props.addMessage(item, props.dispatch)
        setMessage(null)
    }

    return ( auth.authenticated ?
    <MainCard sx={{ height: '80vh', overflow: 'inherit' }} contentSX={{ height: '100%' }}>
      <Helmet>
        <title>Vos Messages sur Boutigou - Communication Directe</title>
        <meta name="description" content="Accédez à vos messages sur Boutigou pour une communication fluide avec les producteurs et l'équipe Boutigou" />
      </Helmet>
      <Grid container component={Paper} sx={{ width: '100%', height: '100%' }}>
        <Grid item xs={12} md={3} sx={{ borderRight: {xs: 'none', md: '1px solid #e0e0e0'}, display: {xs: conversation_id ? 'none' : 'block', md: 'block'}  }}>
          <List>
            { props.conversationRed.conversations.map((c, idx) => (
              <ListItem button key={idx} onClick={() => navigateConversation(c.id)} sx={c.id == conversation_id ? {backgroundColor: theme.palette.secondary[200], borderRadius: '5px' } : null}>
                  <ListItemIcon>
                      <Avatar alt={c.shop_name} src={c.shop ? c.shop.profile.replace('http://django', '') : ''} sx={{ backgroundColor: 'transparent', }}/>
                  </ListItemIcon>
                  <ListItemText sx={c.is_seen_consumer ? { pl: 1} : { pl: 1, '& .MuiListItemText-primary': { fontWeight: 'bold' }, '& .MuiListItemText-secondary': { fontWeight: 'bold' } }} primary={c.shop_name} secondary={moment(c.last_update).format('Do MMMM YYYY à H[h]mm')} />
              </ListItem> ))}
            { props.conversationRed.conversations.length === 0 ? (
              <ListItem button key={0}>
                <Typography variant="h4">Vous n&apos;avez pas de conversation pour le moment.</Typography>
            </ListItem> ) : null}
            <Divider />
          </List>
        </Grid>
        <Grid item xs={12} md={9} sx={{ display: {xs: conversation_id ? 'block': 'none', md: 'block'}, height: '100%' }}>
          <List sx={{ display: {xs: conversation_id ? 'block' : 'none', md: 'none'}  }}>
            <ListItem>
              <Grid container alignItems="center" justifyContent="space-between" sx={{ flexWrap: "nowrap" }}>
                <Grid item>
                  <IconButton onClick={() => navigateConversation('')} edge="start" color="inherit" aria-label="retour">
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item sx={{ minWidth: 0, flex: 1 }}>
                  <ListItemText sx={{ pl: 1, '& .MuiListItemText-primary': { fontWeight: 'bold' } }} primary={props.conversationRed.conversation.shop ? props.conversationRed.conversation.shop.name : ''} />
                </Grid>
                <Grid item>
                  <ListItemIcon>
                    <Avatar sx={{ backgroundColor: 'transparent', }} alt={props.conversationRed.conversation.shop ? props.conversationRed.conversation.shop.name : ''} src={props.conversationRed.conversation.shop ? props.conversationRed.conversation.shop.profile.replace('http://django', '') : ''} />
                  </ListItemIcon>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          { conversation_id === undefined ? < NoConversation /> :
            <><List sx={{ height: `calc(100% - ${gridRef.current ? gridRef.current.offsetHeight : '50'}px - ${isXsOrSmOrMd ? '50' : '0'}px)`, overflowY: 'auto', flexGrow: 1 }} ref={listRef}>
              { props.conversationRed.messages.map((m, idx) => (
                <ListItem key={idx}>
                    <Grid container>
                        <Grid item xs={12}>
                          <ListItemText align={m.is_shop ? "left" : "right"} secondary={moment(m.created_at).format('Do MMMM YYYY à H[h]mm')}>
                            <Chip
                                sx={{
                                  height: 'auto',
                                  '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'pre-line',
                                  },
                                  typography: "h5",
                                  padding: 1
                                }}
                                style={{ backgroundColor: m.is_shop ? theme.palette.secondary.light : theme.palette.primary.light }}
                                label={m.content}
                              />
                            </ListItemText>
                        </Grid>
                    </Grid>
                </ListItem> ))}
            </List>
            <Divider />
            <Grid container style={{padding: '20px'}} ref={gridRef}>
              <Grid item xs={11}>
                  <TextField id="outlined-basic-email" label="Ecrire..." fullWidth value={message || ''} multiline={4} onChange={(e) => setMessage(e.target.value)}/>
              </Grid>
              <Grid xs={1} align="right">
                  <Fab disabled={message === null || message === ''} color="primary" aria-label="add" onClick={handleSendMessage}><SendIcon/></Fab>
              </Grid>
          </Grid></> }
        </Grid>
      </Grid>
    </MainCard> :
    <MainCard>
      <Helmet>
        <title>Vos Messages sur Boutigou - Communication Directe</title>
        <meta name="description" content="Accédez à vos messages sur Boutigou pour une communication fluide avec les producteurs et l'équipe Boutigou" />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}