import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getNotificationMessage(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/notification_message/`)
            .then((res) => { return dispatch({ type: types.GET_NOTIFICATION_MESSAGES, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_NOTIFICATION_MESSAGES, payload: error.response.data }) })
    };
}

function closeNotificationMessage(dispatch) {
    return dispatch({ type: types.CLOSE_NOTIFICATION_MESSAGES })
}

function getNotificationOrder(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/notification_order/`)
            .then((res) => { return dispatch({ type: types.GET_NOTIFICATION_ORDERS, payload: res.data })})
            .catch((error) => { return dispatch({ type: types.FAILED_GET_NOTIFICATION_ORDERS, payload: error.response.data }) })
    };
}

function closeNotificationOrder(dispatch) {
    return dispatch({ type: types.CLOSE_NOTIFICATION_MESSAGES })
}


function getNotificationReview(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/notification_review/`)
            .then((res) => { return dispatch({ type: types.GET_NOTIFICATION_REVIEWS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_NOTIFICATION_REVIEWS, payload: error.response.data }) })
    };
}

function closeNotificationReview(dispatch) {
    return dispatch({ type: types.CLOSE_NOTIFICATION_REVIEWS })
}

function getNotificationCart(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/cart_product/`)
            .then((res) => { return dispatch({ type: types.GET_NOTIFICATION_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_NOTIFICATION_CART, payload: error.response.data }) })
    };
}

function closeNotificationCart(dispatch) {
    return dispatch({ type: types.CLOSE_NOTIFICATION_CART })
}
export {
    getNotificationMessage,
    closeNotificationMessage,
    getNotificationOrder,
    closeNotificationOrder,
    getNotificationReview,
    closeNotificationReview,
    getNotificationCart,
    closeNotificationCart,
};