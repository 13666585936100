import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

// material-ui
import { Grid, Typography, Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Link, Breadcrumbs } from '@mui/material';

// project imports
import ShopProduct from './ShopProduct';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Product(props) {
    const isInitialMount = React.useRef(true);
    const { product_id, shop_id } = useParams();
    const [visibleCartSuccess, setVisibleCartSuccess] = React.useState(false);
    const [visibleCartError, setVisibleCartError] = React.useState(false);

    React.useEffect(() => {
      document.title = `Boutigou | ${props.productRed.product.product ? props.productRed.product.product.shop_name : ''} | ${props.productRed.product.product ? props.productRed.product.product.name : ''}`
    }, [props.productRed.product]);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getProduct(props.dispatch, product_id);
        }
    }, []);

    React.useEffect(() => {
        if (props.productRed.success) {
            props.getProductSilently(props.dispatch, product_id);
        }
    }, [props.productRed.success]);

    React.useEffect(() => {
      if (props.productRed.success_add_product === true) {
        setVisibleCartSuccess(true);
      } else if (props.productRed.success_add_product === false) {
        setVisibleCartError(true);
      }
      props.closeAlertCart(props.dispatch)
    }, [props.productRed.success_add_product]);

    return (
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Découvrez {props.productRed.product.product ? props.productRed.product.product.name : ''} de {props.productRed.product.product ? props.productRed.product.product.shop_name : ''} sur Boutigou</title>
        <meta name="description" content="Parcourez notre sélection de produits locaux sur Boutigou et découvrez les trésors de votre région." />
      </Helmet>
      <Box sx={{ p: 2.25 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/" variant="h4">
            Boutiques
          </Link>
          <Link underline="hover" color="inherit" href={`/boutiques/${shop_id}`} variant="h4">
            {props.productRed.product.product ? props.productRed.product.product.shop_name : null}
          </Link>
          <Typography color="text.primary" variant="h4">{props.productRed.product.product ? props.productRed.product.product.name : null}</Typography>
        </Breadcrumbs>
      </Box>
      <Dialog onClose={() => setVisibleCartSuccess(false)} open={visibleCartSuccess}>
        <DialogTitle variant="h2">Le produit a bien été ajouté à votre panier, que voulez-vous faire?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setVisibleCartSuccess(false)}>Continuer mes achats</Button>
          <Button variant="contained" href="/panier" autoFocus>
            Voir mon panier
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setVisibleCartError(false)} open={visibleCartError}>
        { (props.productRed.errors.code !== undefined ? props.productRed.errors.code.message : '') === "1" ? (
        <DialogTitle variant="h2">Le produit n&apos;est plus disponible en quantité suffisante, merci de la diminuer.</DialogTitle>) : null}
        { (props.productRed.errors.code !== undefined ? props.productRed.errors.code.message : '') === "2" ? (
        <><DialogTitle variant="h2">Ce producteur n&apos;est pas compatible avec les producteurs des produits de votre panier.</DialogTitle>
        <DialogContent>Pour commander chez {props.productRed.product.product ? props.productRed.product.product.shop_name : null}, vous devrez réaliser une nouvelle commande.</DialogContent></> ) : null} 
        { (props.productRed.errors.code !== undefined ? props.productRed.errors.code.message : '') === "3" ? (
        <><DialogTitle variant="h2">Ce produit n&apos;est pas compatible avec les autres produits de votre panier car il est en prix variable.</DialogTitle>
        <DialogContent>Pour commander ce produit, vous ne devez pas faire du multi-producteur.</DialogContent></> ) : null} 
        <DialogActions>
          <Button onClick={() => setVisibleCartError(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <ShopProduct
          props={props}
          loading={props.productRed.loading}
          name={props.productRed.product.product ? props.productRed.product.product.name : null}
          discounted={props.productRed.product.product ? props.productRed.product.product.discounted : false}
          variable_price={props.productRed.product.product ? props.productRed.product.product.variable_price : false}
          picture={props.productRed.product.product ? props.productRed.product.product.picture : ''}
          description={props.productRed.product.product ? props.productRed.product.product.description : null}
          category={props.productRed.product.product ? props.productRed.product.product.category_name : null}
          unit={props.productRed.product.product ? props.productRed.product.product.unit_name : null}
          price={props.productRed.product.product ? props.productRed.product.product.price : null}
          stock={props.productRed.product.product ? props.productRed.product.product.stock : null}
          labels={props.productRed.product.product ? props.productRed.product.product.labels : []}
          id={props.productRed.product.product ? props.productRed.product.product.id : null}
          is_favorite={props.productRed.product ? props.productRed.product.is_favorite : null}
          shop_name={props.productRed.product.product ? props.productRed.product.product.shop_name : null}
        />
      </Grid>
    </Grid>
  );
}