import * as React from 'react';
import { useState } from 'react';
// import { useSelector } from 'react-redux';
import { resetRequest } from "../../../../actions/authActions";
import gif_loading from 'assets/images/gif_loading.gif';

// material-ui
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Backdrop
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { authenticateAction, loginAction } from "../../../../actions/authActions";

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const scriptedRef = useScriptRef();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleResetPassword = async () => {
    dispatch(resetRequest(dispatch))
  };

  const handleSubmit = (event) => {
      setLoading(true)
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      dispatch(loginAction({ username: data.get('email'), password: data.get('password') }, dispatch)).then(response => {
          if (response.non_field_errors) {
              setErrorMessage({ password: response.non_field_errors[0] });
              setLoading(false)
          } else if (typeof response.access !== 'undefined') {
              setLoading(false)
              dispatch(authenticateAction(
                  response,
                  dispatch
              ));
              setErrorMessage({})
          } else {
              setLoading(false)
              setErrorMessage({ email: "Compte inconnu", password: "Compte inconnu" });
          }
      });
  };


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Se connecter avec l&apos;adresse e-mail</Typography>
          </Box>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-email-login">Adresse e-mail / Identifiant</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email Address / Username"
              />
              {errorMessage.email && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                  {errorMessage.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-password-login">Mot de passe</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errorMessage.password && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                  {errorMessage.password}
                </FormHelperText>
              )}
            </FormControl>
            <Stack direction="row" alignItems="right" justifyContent="space-between" spacing={1}>
              <Typography variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={handleResetPassword}>
                Mot de passe oublié?
              </Typography>
            </Stack>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                  Se connecter
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseLogin;