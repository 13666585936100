import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest } from "../../actions/authActions";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Chip, Button, IconButton, DialogTitle, Dialog, DialogActions, DialogContent, Tooltip } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SpaIcon from '@mui/icons-material/Spa';
import HelpIcon from '@mui/icons-material/Help';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
// import { fontSize } from '@mui/system';

const CardWrapper = styled(MainCard)(() => ({

  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ShopProduct = ({ loading, name, picture, description, category, price, unit, stock, labels, id, is_favorite, props, shop_name, discounted, variable_price }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [qty, setQty] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const [size, setSize] = React.useState(24)

  const borderStyle = {
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    marginRight: '8px', // Ajustez selon votre mise en page
    marginLeft: '8px', // Ajustez selon votre mise en page
  };
  
  const handleBuy = () => {
    if (auth.authenticated === false) {
      dispatch(authRequest(dispatch));
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteProductFavorite = () => {
    if (auth.authenticated === false) {
      dispatch(authRequest(dispatch));
    } else {
      const item = {
          'id': props.productRed.is_favorite_id
      };
      props.deleteProductFavorite(item, props.dispatch);
    }
  };

  const handleAddProductFavorite = () => {
    if (auth.authenticated === false) {
      dispatch(authRequest(dispatch));
    } else {
      const item = {
        'product': id
      };
      FavoriteIcon
      props.addProductFavorite(item, props.dispatch);
    }
  };

  const handleProductToCart = () => {
    const item = {
      'product': id,
      'quantity': qty,
      'price_unit': price,
    };
    props.addProductToCart(item, props.dispatch);
    setOpen(false)
  };

  const handleIncrement = () => {
    if (qty < stock) {
      setQty(qty + 1);
    }
  };

  const handleDecrement = () => {
    if (qty > 0) {
      setQty(qty - 1);
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Dialog onClose={handleClose} open={open} >
            <DialogTitle variant="h2">Ajouter ce produit à mon panier</DialogTitle>
            <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton onClick={handleDecrement} color="primary" aria-label="minus" style={borderStyle}>
                <RemoveIcon />
              </IconButton>
              <Typography variant="h2">{qty}</Typography>
               <IconButton onClick={handleIncrement} color="primary" aria-label="plus" style={borderStyle}>
                <AddIcon />
              </IconButton>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button variant="contained" onClick={handleProductToCart} autoFocus>
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    position: "relative",
                    width: "90%",
                    margin: "auto",
                    paddingTop: "90%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: "auto",
                      maxWidth: '500px',
                      maxHeight: '500px',
                      backgroundImage: `url(${picture.replace('http://django', '')})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "12px",
                    }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <IconButton color="inherit" onMouseEnter={() => setSize(30)} onMouseLeave={() => setSize(24)}>
                  { is_favorite ? <FavoriteIcon onClick={handleDeleteProductFavorite} sx={{fontSize: size, color: theme.palette.primary[200] }}/> : <FavoriteBorderIcon onClick={handleAddProductFavorite} sx={{fontSize: size, color: theme.palette.primary[200]}}/>}
                </IconButton>
              </Grid>
            </Grid>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} sx={{ mb: 1.25 }}>
                      <Typography
                        sx={{
                          mt: 1.75,
                          mb: 0.75,
                        }}
                        variant="h1"
                      >
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Chip
                        label={category}
                        sx={{ textAlign: 'right', fontSize: '1rem', fontWeight: 500, color: theme.palette.secondary[200] }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sx={{ mb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h3"
                    >
                      {description}
                    </Typography>
                  </Grid>
                  { labels.length !== 0 ?
                  <Grid item sx={{ mb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h3"
                    >
                      Labels
                    </Typography>
                    { labels.map((l, idx) => (
                      <Chip
                        label={l.name}
                        key={idx}
                        href={`/kesako/labels/${l.description}`}
                        component="a"
                        clickable
                        sx={{ textAlign: 'right', fontSize: '1rem', mr: 0.5, mt: 0.5,fontWeight: 500, color: theme.palette.secondary[200] }}
                      />
                    ))}
                  </Grid> : null}
                  <Grid item sx={{ mb: 1.25, alignSelf: 'flex-start' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h3">
                        Prix: {parseFloat(price).toFixed(2) / 100}€ {variable_price === true ? '(prix approximatif susceptible de varier)' : null}
                      </Typography>
                      {variable_price && (
                        <Box sx={{ ml: 1 }}>
                          <Tooltip title="Le prix de certains produits, surtout ceux vendus au poids, peut légèrement varier en fonction du poids exact à la livraison ou collecte. Cette variation permet de s'adapter aux petites différences de quantité et assure une rémunération juste pour les producteurs. Pour toute question, n'hésitez pas à contacter notre service client.">
                            <IconButton onClick={(e) => { e.stopPropagation(); }}>
                              <HelpIcon sx={{ fontSize: '15px' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid container direction="row" sx={{ mb: 1.25, justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" sx={{ color: '#697586' }}>
                        Unité d&apos;achat: {unit}
                      </Typography>
                      <Typography variant="h4" sx={{ color: '#697586' }}>
                        Stock: {stock}
                      </Typography>
                      <Typography variant="h4" sx={{ color: '#697586' }}>
                        Boutique: {shop_name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {discounted && (
                        <Box right={0} padding={2}>
                          <Tooltip title="Produit antigaspi">
                            <IconButton style={{ color: '#4CAF50' }} onClick={(e) => { e.stopPropagation(); }}>
                              <SpaIcon sx={{ fontSize: '50px' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: '#fff',
                        textAlign: 'right',
                      }}
                      onClick={() => handleBuy()}
                    >
                      Acheter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ShopProduct.propTypes = {
  isLoading: PropTypes.bool,
};

export default ShopProduct;