import * as React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Badge,
  IconButton,
  Tooltip
} from '@mui/material';

import MessageIcon from '@mui/icons-material/Message';
import { IconShoppingCart } from '@tabler/icons';


// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {

  const theme = useTheme();
  const notifications = useSelector((state) => state.notification);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2
          },
          display: { xs: 'none', md: 'block' }
        }}
      >
        <IconButton sx={{ mr: 2 }} href="/panier">
          <Tooltip title="Voir mon panier">
            <Badge badgeContent={notifications.notificationCart} color="primary">
              <IconShoppingCart color={theme.palette.primary.main}/>
            </Badge> 
          </Tooltip>
        </IconButton>

        <IconButton sx={{ mr: 2 }} href="/conversations">
          <Tooltip title="Voir mes messages">
            <Badge badgeContent={notifications.notificationMessages} color="secondary">
              <MessageIcon color="action" />
            </Badge>
          </Tooltip>
        </IconButton>

      </Box>
    </>
  );
};

export default NotificationSection;
