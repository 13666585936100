// material-ui
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Chip, Avatar, Grid, TextField, Button, Backdrop } from '@mui/material';
import { authRequest, registerRequest } from "../../actions/authActions";
import { useTheme } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import gif_loading from 'assets/images/gif_loading.gif';

// ==============================|| CGU PAGE ||============================== //


export default function Profile(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);
    const [username, setUserName] = React.useState(null);
    const [first_name, setFirstName] = React.useState(null);
    const [last_name, setLastName] = React.useState(null);
    const [email, setEmail] = React.useState(null);

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    const handleProfile = () => {
      const item = {
        'id': props.profileRed.profile.id,
        'username': username,
        'first_name': first_name,
        'last_name': last_name,
        'email': email
      };
      props.editProfile(item, props.dispatch)
    }

    React.useEffect(() => {
        if (isInitialMount.current && auth.authenticated) {
            isInitialMount.current = false;
            props.getProfile(props.dispatch);
        }
    }, []);

    React.useEffect(() => {
      if (JSON.stringify(props.profileRed.profile) != JSON.stringify({})) {
        setUserName(props.profileRed.profile.username);
        setFirstName(props.profileRed.profile.first_name);
        setLastName(props.profileRed.profile.last_name);
        setEmail(props.profileRed.profile.email);
      }
    }, [props.profileRed.profile]);

    React.useEffect(() => {
      if (props.profileRed.success) {
        props.getProfile(props.dispatch);
        if ((email !== null) && (email !== props.profileRed.profile.email)) {
          props.logoutAction(props.dispatch)
        } else {
          props.whoAmI(localStorage.getItem("access_token"), props.dispatch);
        }
      }
    }, [props.profileRed.success]);


    return ( auth.authenticated ?
    <MainCard title="Mon profil">
      <Helmet>
        <title>Votre Profil sur Boutigou - Personnalisez Votre Expérience</title>
        <meta name="description" content="Gérez votre profil sur Boutigou pour personnaliser votre expérience d'achat local." />
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.profileRed.loading}
      >
        <Box
          component="img"
          sx={{
            height: 250,
            mb: 4
          }}
          alt="loading"
          src={gif_loading}
        />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="username"
            label="Nom d'utilisateur"
            name="username"
            value={username || ''}
            onChange={(e) => setUserName(e.target.value)}
            helperText={props.profileRed.errors.username ? props.profileRed.errors.username.message : null}
            error={props.profileRed.errors.username ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Prénom"
            name="first_name"
            value={first_name || ''}
            onChange={(e) => setFirstName(e.target.value)}
            helperText={props.profileRed.errors.first_name ? props.profileRed.errors.first_name.message : null}
            error={props.profileRed.errors.first_name ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="last_name"
            required
            fullWidth
            id="last_name"
            label="Nom"
            value={last_name || ''}
            onChange={(e) => setLastName(e.target.value)}
            helperText={props.profileRed.errors.last_name ? props.profileRed.errors.last_name.message : null}
            error={props.profileRed.errors.last_name ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="email"
            label="Courriel"
            name="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            helperText={props.profileRed.errors.email ? props.profileRed.errors.email.message : null}
            error={props.profileRed.errors.email ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                onClick={handleProfile}
                variant="contained"
                sx={{ mt: 3, ml: 1 }}
                disabled={props.profileRed.loading}
            >
                Valider
            </Button>
          </Box>
        </Grid>
    </Grid>





    </MainCard> :
    <MainCard>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}