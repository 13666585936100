// material-ui
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Grid, Paper, Box, List, ListItem, ListItemText, ListItemIcon, Button, Link as RouterLink, IconButton  } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import logo from 'assets/images/logo.png';
import product from 'assets/images/aboutus/product.png';
import geolocation from 'assets/images/aboutus/geolocation.png';
import order from 'assets/images/aboutus/order.png';
import payment from 'assets/images/aboutus/payment.png';
import exchange from 'assets/images/aboutus/exchange.png';
import find from 'assets/images/aboutus/find.png';
import font_red from 'assets/images/aboutus/font_red.png';
import font_blue from 'assets/images/aboutus/font_blue.png';
import bullet from 'assets/images/aboutus/bullet.png';

// ==============================|| CGU PAGE ||============================== //


export default function AboutUs() {

  const [isHovered1, setIsHovered1] = React.useState(false);
  const [isHovered2, setIsHovered2] = React.useState(false);
  const [isHovered3, setIsHovered3] = React.useState(false);
  const [isHovered4, setIsHovered4] = React.useState(false);
  
  return (
    <MainCard>
      <Helmet>
        <title>À Propos de Boutigou - Renforcer le Lien Local entre Producteurs et Consommateurs</title>
        <meta name="description" content="Découvrez la mission et les valeurs de Boutigou, notre plateforme dédiée à la promotion d'une consommation responsable en soutenant les producteurs locaux." />
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: 100, paddingBottom: 100 }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{
                height: '50px',
                '@media (min-width:600px)': {
                  height: '100px',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}style={{ borderRight: '1px solid #e0e0e0' }}>
          <Paper>
            <Box
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)}
              style={{
                transform: isHovered1 ? 'rotate(5deg)' : 'none',
                transition: 'transform 0.3s ease',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={geolocation} alt="ff" style={{ width: '70%', height: 'auto' }} />
            </Box>
            <Typography variant="h4" align="center" style={{ textAlign: 'center' }} sx={{ paddingX: 5 }}>DECOUVREZ LES PRODUCTEURS AUTOUR DE CHEZ VOUS</Typography>
          </Paper>
        </Grid>

      <Grid item xs={12} sm={6} md={3} style={{ borderRight: '1px solid #e0e0e0' }}>
        <Paper>
          <Box
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            style={{
              transform: isHovered2 ? 'rotate(5deg)' : 'none',
              transition: 'transform 0.3s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={payment} alt="ff" style={{ width: '70%', height: 'auto' }} />
          </Box>
          <Typography variant="h4" align="center" style={{ textAlign: 'center' }} sx={{ paddingX: 5 }}>TROUVEZ LES PRODUITS QUI VOUS INTERESSENT, PASSEZ COMMANDE ET PAYEZ EN LIGNE</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={3}style={{ borderRight: '1px solid #e0e0e0' }}>
        <Paper>
          <Box
            onMouseEnter={() => setIsHovered3(true)}
            onMouseLeave={() => setIsHovered3(false)}
            style={{
              transform: isHovered3 ? 'rotate(5deg)' : 'none',
              transition: 'transform 0.3s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={product} alt="ff" style={{ width: '70%', height: 'auto' }} />
          </Box>
          <Typography variant="h4" align="center" style={{ textAlign: 'center' }} sx={{ paddingX: 5 }}>VOTRE COMMANDE EST PREPAREE PAR VOS PRODUCTEURS</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Paper>
          <Box
            onMouseEnter={() => setIsHovered4(true)}
            onMouseLeave={() => setIsHovered4(false)}
            style={{
              transform: isHovered4 ? 'rotate(5deg)' : 'none',
              transition: 'transform 0.3s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={order} alt="ff" style={{ width: '70%', height: 'auto' }} />
          </Box>
          <Typography variant="h4" align="center" style={{ textAlign: 'center' }} sx={{ paddingX: 5 }}>RECUPEREZ VOS PRODUITS FRAICHEMENT RECOLTES</Typography>
        </Paper>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: 100, paddingBottom: 100 }}>
        <Box
          sx={{
            position: 'relative',
            width: '70%',
            paddingTop: '40%',
            minWidth: '560px',
          }}
        >
          <iframe
            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, }}
            src="https://www.youtube-nocookie.com/embed/4FXqpinF0n4?autoplay=0&amp;rel=0&amp;loop=0&amp;controls=1&amp;cc_load_policy=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          >
          </iframe>
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2} style={{ backgroundImage: `url(${font_red})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed', }}>
      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Paper style={{ padding: '20px', textAlign: 'right', backgroundColor: 'transparent' }}>
          <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif' }}>Du Producteur au Consommateur</Typography>
          <Typography variant="h4" style={{ marginTop: '10px' }}>BOUTIGOU VOUS PERMET DE TROUVER DES PRODUCTEURS PRÈS DE CHEZ VOUS, DE COMMANDER DIRECTEMENT SUR LEUR BOUTIQUE EN LIGNE ET DE RÉCUPÉRER LEURS PRODUITS À UN JOUR FIXE.</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={exchange} alt="ff" style={{ width: '50%', height: 'auto' }} />
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2} style={{ backgroundImage: `url(${font_blue})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed', }}>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={find} alt="ff" style={{ width: '50%', height: 'auto' }} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Paper style={{ padding: '20px', textAlign: 'left', backgroundColor: 'transparent' }}>
          <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif' }}>Dégustez votre région</Typography>
          <Typography variant="h4" style={{ marginTop: '10px' }}>CONSOMMEZ DES PRODUITS CONÇUS PAR VOS VOISINS PRODUCTEURS ET DÉCOUVREZ AINSI LES MERVEILLES QUI VOUS ENTOURENT.</Typography>
        </Paper>
      </Grid>
    </Grid>

    <Grid container spacing={2} style={{ paddingTop: 100, paddingBottom: 100 }}>
      <Grid item xs={12} sm={6} style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif' }}>Vous êtes un futur consommateur ?</Typography>
        <Typography variant="h4">Boutigou vous permet de :</Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Trouver les producteurs autour de chez vous" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Acheter directement leurs produits" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Garantir une rémunération correcte aux producteurs en ne payant que 5€ maximum de commission" />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif' }}>Vous êtes un futur producteur ?</Typography>
        <Typography variant="h4">Pour un abonnement de 30 € par mois, Boutigou vous permet de :</Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Créer votre boutique en quelques clics" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Ajouter vos produits" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={bullet} alt="Bullet Icon" style={{ width: '50px', }} />
            </ListItemIcon>
            <ListItemText primary="Vendre directement aux consommateurs sans frais supplémentaires" />
          </ListItem>
        </List>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 100, paddingBottom: 25 }}>
        <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif', textAlign: 'center' }}>
          Vous souhaitez plus d&apos;informations ?
        </Typography>
        <Button style={{ marginTop: 20 }} component={ReactLink} to={'/contact'} variant="contained" color="primary">
          Contactez-nous
        </Button>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 25, paddingBottom: 25 }}>
        <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif', textAlign: 'center' }}>
          Vous souhaitez suivre nos aventures ?
        </Typography>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
          <RouterLink href="https://www.facebook.com/boutigoufr" target="_blank" rel="noopener noreferrer">
            <IconButton color="primary">
              <FacebookIcon fontSize="large" />
            </IconButton>
          </RouterLink>
          <RouterLink href="https://www.instagram.com/boutigoufr/" target="_blank" rel="noopener noreferrer">
            <IconButton color="primary">
              <InstagramIcon fontSize="large" />
            </IconButton>
          </RouterLink>
        </div>
      </Grid>
    </Grid>


    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="h1" style={{ fontFamily: 'PinaColadaCreation, sans-serif', paddingBottom: 20 }}>Remerciements</Typography>
        <Typography variant="h4">Un grand merci à Laurent DURU, Olivier PRUNEYRAC, Andy MAGNOUX, Laura KERVALET, Anaïs LEFEBVRE, Marine MOUHICA ainsi qu&apos;à toutes les autres personnes ayant participé au projet sans qui cette aventure n&apos;aurait pas pu voir le jour.</Typography>
        <Typography variant="h4">Un merci particulier à Tess FOUCHER pour l&apos;ensemble des visuels. Vous retrouverez son travail <a href="https://www.instagram.com/tf_graphisme/" target="_blank" rel="noopener noreferrer">ici</a>.</Typography>
      </Grid>
    </Grid>
  </MainCard>
  );
}