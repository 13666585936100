import * as types from "../types/types";

const initialState = {
    products: [],
    errors: {},
    success: false,
    loading: false
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FAVORITES:
            return {
                ...state,
                products: action.payload,
                loading: false,
            };
        case types.FAILED_GET_FAVORITES:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_FAVORITES:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}

export default shopReducer;