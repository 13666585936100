import React, { useState } from 'react';
import {
  Box,
  Button,
  Backdrop,
  Typography
} from '@mui/material';

import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import gif_loading from 'assets/images/gif_loading.gif';

const CheckoutForm = ({props}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {
    setLoading(true)
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false)
      return;
    }

    const { error: submitError } = await elements.submit();
        if (submitError) {
          // Show error to your customer
          setErrorMessage(submitError.message);
          setLoading(false)
          return;
        }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://boutigou.fr/panier?succeed=true",
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
      props.failedCardOrder(props.dispatch)
      setLoading(false)
    }
    setLoading(false)
  };

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={loading}
      >
          <Box
              component="img"
              sx={{
                  height: 250,
                  mb: 4
              }}
              alt="Loading..."
              src={gif_loading}
          />
      </Backdrop>
      <PaymentElement onReady={() => setLoading(false)}/>
      <Button onClick={handleSubmit} disabled={!stripe || !elements} variant="contained" sx={{ my: 1, width: '100%' }}>
        Payer {parseFloat(props.orderRed.cart_details.total).toFixed(2)/100}€
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </>
  );
};

export default CheckoutForm;