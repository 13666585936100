import { connect } from "react-redux";

import Profile from "../views/profile";

import {
    getProfile,
    editProfile,
    deleteProfile,
    cleanDeleteProfile
} from "../actions/profileActions.js";

import { logoutAction, whoAmI } from '../actions/authActions';


const mapStateToProps = state => ({
    profileRed: state.profile
});

const mapDispatchToProps = dispatch => ({
    getProfile: (dispatch) =>
        dispatch(getProfile(dispatch)),
    editProfile: (item, dispatch) =>
        dispatch(editProfile(item, dispatch)),
    deleteProfile: (item, dispatch) =>
        dispatch(deleteProfile(item, dispatch)),
    cleanDeleteProfile: (dispatch) =>
        dispatch(cleanDeleteProfile(dispatch)),
    logoutAction: (dispatch, push) =>
        dispatch(logoutAction(dispatch, push)),
    whoAmI: (token, dispatch) =>
        dispatch(whoAmI(token, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
