import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getShop(dispatch, shop_id) {
  dispatch({
    type: types.LOADING_GET_SHOP
  });

  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/shop/${shop_id}/`);
      dispatch({ type: types.GET_SHOP, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/';
      } else {
        dispatch({ type: types.FAILED_GET_SHOP, payload: error.response.data });
      }
    }
  };
}

function getShopProducts(dispatch, shop_id) {
    dispatch({
        type: types.LOADING_GET_SHOP_PRODUCTS
    });
    return async function () {
        axiosAPI
            .get(`${url}/shop_product/?shop__id=${shop_id}`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_PRODUCTS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_PRODUCTS, payload: error.response.data }) })
    };
}

function getShopDeliveryOptions(dispatch, shop_id) {
    dispatch({
        type: types.LOADING_GET_SHOP_DELIVERY_OPTIONS
    });
    return async function () {
        axiosAPI
            .get(`${url}/shop_delivery_options/?shop__id=${shop_id}`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_DELIVERY_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_DELIVERY_OPTIONS, payload: error.response.data }) })
    };
}

function getShopReviews(dispatch, shop_id) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_review/?shop__id=${shop_id}`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_REVIEWS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_REVIEWS, payload: error.response.data }) })
    };
}

function getShopEvents(dispatch, shop_id) {
    dispatch({
        type: types.LOADING_GET_SHOP_EVENT_OPTIONS
    });
    return async function () {
        axiosAPI
            .get(`${url}/shop_activity/?shop__id=${shop_id}`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_EVENT_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_EVENT_OPTIONS, payload: error.response.data }) })
    };
}

function getConversation(dispatch, conv_id, shop_id) {
    return async function () {
        axiosAPI
            .get(`${url}/conversation/?shop_id=${shop_id}&conv_id=${conv_id}`)
            .then((res) => { return dispatch({ type: types.GET_CONVERSATION, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CONVERSATION, payload: error.response.data }) })
    };
}

function addMessage(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/message/`, item)
            .then((res) => { return dispatch({ type: types.ADD_MESSAGE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_MESSAGE, payload: error.response.data }) })
    };
}

function getShopPartners(dispatch, shop_id) {
    dispatch({
        type: types.LOADING_GET_SHOP_PARTNERS
    });
    return async function () {
        axiosAPI
            .get(`${url}/shop_partner/?shop_id=${shop_id}`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_PARTNERS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_PARTNERS, payload: error.response.data }) })
    };
}

export {
    getShop,
    getShopProducts,
    getShopDeliveryOptions,
    getShopReviews,
    getConversation,
    addMessage,
    getShopPartners,
    getShopEvents
};
