// frontend/src/components/api/axiosApi.js

import axios from "axios";
import { logoutAction } from "../actions/authActions";
// import { backendUrl } from "http://localhost:8080/consumer/api/v1";

let backendUrl = "https://api.dev.boutigou.com/consumer/api/v1";

import { store } from '../store';

let url = process.env.REACT_APP_DEV_URL || backendUrl;

const baseURL = '';

const axiosAPI = axios.create({

    baseURL: baseURL,

    timeout: 100000,

    headers: {

        "Content-Type": "application/json",

        accept: "application/json",

    },

});



axiosAPI.interceptors.request.use(

    function (config) {

        const token = localStorage.getItem("access_token");

        if (token) {

            config.headers["Authorization"] = 'Bearer ' + token;

        }

        return config;

    },

    function (error) {

        return Promise.reject(error);

    }

);



axiosAPI.interceptors.response.use(

    (response) => response,

    async (error) => {

        try {

            const originalRequest = error.config;


            const {dispatch} = store;

            // Prevent infinite loops
            if (

                error.response.status === 401 &&

                originalRequest.url === url + "/token/refresh/"

            ) {

                localStorage.removeItem("access_token");

                localStorage.removeItem("refresh_token");

                dispatch(logoutAction(dispatch));

                window.location.href = "/";

                return Promise.reject(error);

            }

            if (

                error.response.status === 401

            ) {

                const refresh = localStorage.getItem("refresh_token");

                if (refresh) {

                    const tokenParts = JSON.parse(atob(refresh.split(".")[1]));

                    // exp date in token is expressed in seconds, while now() returns milliseconds:

                    const now = Math.ceil(Date.now() / 1000);

                    if (tokenParts.exp > now) {

                        try {

                            const response = await axiosAPI.post(`${url}/token/refresh/`, {

                                refresh,

                            });

                            setNewHeaders(response);

                            originalRequest.headers["Authorization"] =

                                "Bearer " + response.data.access;

                            return axiosAPI(originalRequest);

                        } catch (error) {

                            console.log(error);

                        }

                    } else {

                        console.log("Refresh token is expired", tokenParts.exp, now);

                        localStorage.removeItem("access_token");

                        localStorage.removeItem("refresh_token");

                        window.location.href = "/";

                    }

                } else {

                    console.log("Refresh token not available.");

                    localStorage.removeItem("access_token");

                    localStorage.removeItem("refresh_token");

                    dispatch(logoutAction(dispatch));

                    if (!['/login', '/register', '/reset'].includes(window.location.pathname) ) {
                    
                        window.location.href = "/";
                    }

                }

            }

            // specific error handling done elsewhere

            return Promise.reject(error);

        } catch (error) {

            console.log(error);

            localStorage.removeItem("access_token");

            localStorage.removeItem("refresh_token");

            dispatch(logoutAction(dispatch));

            window.location.href = "/";

        }

    }

);

export function setNewHeaders(response) {

    axiosAPI.defaults.headers["Authorization"] = "Bearer " + response.data.access;

    localStorage.setItem("access_token", response.data.access);

}

export default axiosAPI;
