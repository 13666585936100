import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest, registerRequest } from "../../actions/authActions";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Chip, Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import ReviewCard from './ReviewCard';
import NoReview from './NoReview';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Reviews(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    React.useEffect(() => {
        if (isInitialMount.current && auth.authenticated) {
            isInitialMount.current = false;
            props.getReviews(props.dispatch);
        }
    }, []);

    return ( auth.authenticated ?
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Mes Avis sur Boutigou - Votre Feedback Compte</title>
        <meta name="description" content="Consultez et gérez vos avis sur les produits et boutiques de Boutigou. Votre opinion aide la communauté !" />
      </Helmet>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          {props.reviewsRed.reviews.length === 0 ? <Grid item xs={12}>< NoReview /></Grid> :
          props.reviewsRed.reviews.map((p, idx) => (
            <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
              <ReviewCard
                loading={props.reviewsRed.loading}
                rate={p.rate}
                content={p.content}
                created_at={p.created_at}
                response={p.response}
                answered_at={p.answered_at}
                review_order_id={p.sub_order ? p.sub_order.id : null}
                shop_name={p.shop ? p.shop.name : null}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid> :
    <MainCard>
      <Helmet>
        <title>Mes Avis sur Boutigou - Votre Feedback Compte</title>
        <meta name="description" content="Consultez et gérez vos avis sur les produits et boutiques de Boutigou. Votre opinion aide la communauté !" />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}