import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, InputAdornment, OutlinedInput, Breadcrumbs, Link, Typography, Box } from '@mui/material';

// project imports
import ShopCard from './ShopCard';
import ShopProduct from './ShopProduct';
//import Breadcrumbs from './Breadcrumbs';
import { gridSpacing } from 'store/constant';

// assets
import { IconSearch } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 4,
    background: '#fff'
  }
}));

export default function Shop(props) {

    const isInitialMount = React.useRef(true);
    const theme = useTheme();
    const [openMessage, setOpenMessage] = React.useState(false)
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const { shop_id } = useParams();
    const auth = useSelector((state) => state.auth);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShop(props.dispatch, shop_id);
            props.getShopProducts(props.dispatch, shop_id);
            props.getShopDeliveryOptions(props.dispatch, shop_id);
            props.getShopReviews(props.dispatch, shop_id);
            props.getShopPartners(props.dispatch, shop_id);
            props.getShopEvents(props.dispatch, shop_id);
            if (auth.authenticated === true) {
              props.getConversation(props.dispatch, '-1', shop_id);
            }
        }
    }, []);

    React.useEffect(() => {
        isInitialMount.current = false;
        props.getShop(props.dispatch, shop_id);
        props.getShopProducts(props.dispatch, shop_id);
        props.getShopDeliveryOptions(props.dispatch, shop_id);
        props.getShopReviews(props.dispatch, shop_id);
        props.getShopPartners(props.dispatch, shop_id);
        if (auth.authenticated === true) {
          props.getConversation(props.dispatch, '-1', shop_id);
        }
    }, [shop_id]);

    React.useEffect(() => {
        setData(props.shopRed.products)
    }, [props.shopRed.products]);

    React.useEffect(() => {
      if (props.shopRed.successMessage) {
        props.getShop(props.dispatch, shop_id);
        props.getConversation(props.dispatch, '-1', shop_id);
        setOpenMessage(false)
      }
    }, [props.shopRed.successMessage]);

    const searchItems = (e) => {
        setSearch(e)
        let searchTerm = e.toLowerCase();
        setData(props.shopRed.products.filter(item => {
            const categoryMatch = item.category_name.toLowerCase().includes(searchTerm);
            const descriptionMatch = item.description.toLowerCase().includes(searchTerm);
            const nameMatch = item.name.toLowerCase().includes(searchTerm);
            return categoryMatch || descriptionMatch || nameMatch;
        }))
    }

    return (
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Achetez Directement à {props.shopRed.shop.name ? props.shopRed.shop.name : ''} sur Boutigou</title>
        <meta name="description" content={props.shopRed.shop.meta_description ? props.shopRed.shop.meta_description : ''} />
      </Helmet>
      <Box sx={{ p: 2.25 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/boutiques" variant="h4">
            Boutiques
          </Link>
          <Typography color="text.primary" variant="h4">{props.shopRed.shop.name}</Typography>
        </Breadcrumbs>
      </Box>
      <Grid item xs={12}>
        <ShopCard
          shop_id={props.shopRed.shop.id}
          loading={props.shopRed.shop.loading}
          name={props.shopRed.shop.name}
          cover={props.shopRed.shop ? props.shopRed.shop.cover : ''}
          profile={props.shopRed.shop ? props.shopRed.shop.profile : ''}
          category={props.shopRed.shop.category}
          category_article={props.shopRed.shop ? props.shopRed.shop.category_article : ''}
          description={props.shopRed.shop.description}
          address={`${props.shopRed.shop.address}, ${props.shopRed.shop.zipcode} ${props.shopRed.shop.city}`}
          delivery_options={props.shopRed.delivery_options}
          event_options={props.shopRed.event_options}
          avg_reviews={props.shopRed.avg_reviews}
          count_reviews={props.shopRed.count_reviews}
          conv={props.shopRed.conv}
          openMessage={openMessage}
          setOpenMessage={setOpenMessage}
          props={props}
          payment_methods={props.shopRed.shop ? props.shopRed.shop.payment_methods : []}
        />
      </Grid>
      <Grid item xs={12}>
        <OutlineInputStyle
          id="input-search-header"
          value={search}
          onChange={(e) => {searchItems(e.target.value)}}
          placeholder="Rechercher"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
          {data.map((p, idx) => (
            <Grid key={idx} item lg={4} md={6} sm={6} xs={12}>
              <ShopProduct
                loading={props.shopRed.loading}
                name={p.name}
                picture={p.picture || ''}
                id={p.id}
                shop_id={shop_id}
                category={p.category_name}
                price={p.price}
                unit={p.unit_name}
                stock={p.stock}
                labels={p.labels}
                discounted={p.discounted}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}