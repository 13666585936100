// material-ui imports
import * as React from 'react';
import { Typography, Link } from '@mui/material';
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| 3 raisons de composter chez soi ||============================== //

export default function Compost() {
  return (
    <MainCard title="3 raisons de composter chez soi">
      <Helmet>
        <title>3 raisons de composter chez soi</title>
        <meta name="description" content="Découvrez les avantages de faire votre compost à domicile : réduire vos déchets, enrichir votre sol et contribuer à un mode de vie durable." />
      </Helmet>

      <Typography variant="body1" paragraph>
        Découvre les avantages de faire ton compost à domicile ! Composter chez soi n&apos;est pas seulement une tendance éco-responsable, c&apos;est aussi un moyen efficace de réduire ses déchets et d&apos;enrichir son jardin. Tu te demandes pourquoi franchir le pas ? Voici trois raisons convaincantes pour t&apos;y mettre dès aujourd&apos;hui.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Réduisez vos déchets ménagers
      </Typography>

      <Typography variant="body1" paragraph>
        Réduire tes déchets ménagers est un des <strong>avantages de faire son compost</strong>. En triant tes déchets organiques, tu diminues significativement la quantité de déchets envoyés à la décharge. Cela contribue à la réduction des émissions de méthane, un gaz à effet de serre puissant.
      </Typography>

      <Typography variant="body1" paragraph>
        Un autre avantage de faire son compost est la transformation de ces déchets en une ressource précieuse : le compost. Ce dernier enrichit le sol de ton jardin, améliore sa structure et favorise une meilleure rétention d&apos;eau. Ainsi, tu réduis ta dépendance aux engrais chimiques.
      </Typography>

      <Typography variant="body1" paragraph>
        Faire ton compost chez toi te permet de participer activement à un mode de vie plus durable. Tu contribues à la préservation de l&apos;environnement tout en bénéficiant d&apos;un jardin plus fertile et en meilleure santé.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Améliorez la qualité de votre sol
      </Typography>

      <Typography variant="body1" paragraph>
        Faire son propre compost présente de nombreux <strong>avantages</strong> pour la qualité de ton sol. En compostant, tu ajoutes des nutriments essentiels qui favorisent la croissance des plantes.
      </Typography>

      <Typography variant="body1" paragraph>
        Le compost, riche en matières organiques, améliore la structure du sol. Il aide à retenir l&apos;eau et les nutriments, réduisant ainsi les besoins en arrosage et en engrais chimiques. Le sol devient plus fertile et résilient face aux conditions climatiques défavorables.
      </Typography>

      <Typography variant="body1" paragraph>
        Voici quelques bénéfices du compost pour ton sol :
      </Typography>

      <ul>
        <Typography component="li" paragraph>
          Augmentation de la matière organique
        </Typography>
        <Typography component="li" paragraph>
          Amélioration de la rétention d&apos;eau
        </Typography>
        <Typography component="li" paragraph>
          Réduction de l&apos;érosion
        </Typography>
        <Typography component="li" paragraph>
          Stimulation de l&apos;activité microbienne
        </Typography>
      </ul>

      <Typography variant="body1" paragraph>
        Les avantages de faire son compost chez soi sont multiples. Cela enrichit ton sol de manière naturelle et durable. Tu participes également à la réduction des déchets organiques.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Diminuez votre empreinte carbone
      </Typography>

      <Typography variant="body1" paragraph>
        Faire son compost chez soi contribue directement à la réduction de ton empreinte carbone. En compostant, tu transformes tes déchets organiques en matière fertile pour ton jardin. Cela diminue la quantité de déchets envoyés à la décharge, réduisant ainsi les émissions de gaz à effet de serre.
      </Typography>

      <Typography variant="body1" paragraph>
        Les avantages de faire son compost sont nombreux. Tu réduis la pollution liée au transport des déchets. De plus, tu enrichis ton sol de manière naturelle sans recourir à des engrais chimiques.
      </Typography>

      <Typography variant="body1" paragraph>
        En compostant, tu participes activement à la protection de l&apos;environnement. Voici quelques points à retenir :
      </Typography>

      <ul>
        <Typography component="li" paragraph>
          <strong>Réduction des déchets</strong> organiques
        </Typography>
        <Typography component="li" paragraph>
          <strong>Diminution des gaz à effet de serre</strong>
        </Typography>
        <Typography component="li" paragraph>
          Amélioration de la qualité du sol
        </Typography>
      </ul>

      <Typography variant="body1" paragraph>
        Adopter cette pratique écologique te permettra de contribuer à un avenir plus durable tout en bénéficiant des avantages de faire son compost.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Créez un engrais naturel et gratuit
      </Typography>

      <Typography variant="body1" paragraph>
        Créer ton propre engrais naturel te permet d&apos;avoir un jardin luxuriant sans dépenser un centime. Le compost maison est riche en nutriments et améliore la structure du sol. Tu transformes tes déchets organiques en un amendement précieux pour tes plantes.
      </Typography>

      <Typography variant="body1" paragraph>
        Les <strong>avantages de faire son compost</strong> sont nombreux. En recyclant tes déchets de cuisine et de jardin, tu réduis la quantité de déchets envoyés à la décharge. Tu participes ainsi à la préservation de l&apos;environnement tout en enrichissant ton sol de manière naturelle.
      </Typography>

      <Typography variant="body1" paragraph>
        Le compost maison est un engrais équilibré et naturel, parfait pour toutes les plantes. Tes légumes, fleurs et arbustes bénéficieront des nutriments essentiels à leur croissance. Profite des <strong>avantages de faire son compost</strong> pour obtenir un jardin sain et productif.
      </Typography>

      <Typography variant="body1" paragraph>
        Résumé : L&apos;article &quot;3 raisons de composter chez soi&quot; met en lumière les avantages de faire son compost : réduction des déchets, enrichissement du sol et contribution à un mode de vie durable. En adoptant cette pratique, tu participes activement à la protection de l&apos;environnement tout en bénéficiant d&apos;un jardin plus fertile.
      </Typography>

      <Typography variant="body1" paragraph>
        Conclusion : En conclusion, composter chez soi présente de multiples avantages qui vont bien au-delà de la simple gestion des déchets. En t&apos;engageant dans cette démarche écologique, tu contribues à la préservation de la planète tout en améliorant la santé de ton jardin. N&apos;attends plus, fais le choix du compost et deviens acteur du changement !
      </Typography>

      <Link size="small" href="/kesako/vracs/guide-portager-urbain" variant="body2">
        Cet article pourrait vous intéresser: 5 étapes pour débuter votre potager urbain
      </Link>

    </MainCard>
  );
}