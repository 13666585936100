// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeLabel() {

    return (
      <>
        <Helmet>
          <title>Découvrez les Labels de Qualité des Produits Locaux | Boutigou</title>
          <meta name="description" content="Explorez le guide Boutigou sur les labels de qualité certifiant l&apos;authenticité et l&apos;excellence des produits locaux. AOC, AOP, IGP et plus à découvrir." />
        </Helmet>
        <MainCard title="Les labels, kesako ?" sx={{ mb: 4 }}>

          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    AB
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Agriculture Biologique
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/labels/certification-ab">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    AOP
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Appellation d&apos;Origine Protégée
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/labels/aop-appellation-origine-protegee-guide">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    AOC
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Appellation d&apos;Origine Controlée
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/labels/decouverte-produits-aoc-excellence-tradition">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    IGP
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Indication Géographique Protégée
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/labels/les-secrets-de-l-igp">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </MainCard>
      </>
  );
}