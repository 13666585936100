import * as types from "../types/types";

const initialState = {
    review: {},
    loading: false,
    errors: {},
    success: null,
    orderReview: {}
};

function reviewsReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_REVIEW:
            return {
                ...state,
                review: action.payload.length === 0 ? {} : action.payload[0],
                loading: false,
            };
        case types.FAILED_GET_REVIEW:
            return {
                ...state,
                errors: action.payload[0],
                loading: false,
            };
        case types.LOADING_GET_REVIEW:
            return {
                ...state,
                review: {},
                loading: true,
                success: null
            };
        case types.ADD_REVIEW:
            return {
                ...state,
                success: true,
                errors: {},
            };
        case types.FAILED_ADD_REVIEW:
            return {
                ...state,
                errors: action.payload,
                success: false,
                loading: false
            };
        case types.GET_ORDER_REVIEW:
            return {
                ...state,
                orderReview: action.payload,
            };
        case types.FAILED_GET_ORDER_REVIEW:
            return {
                ...state,
                errors: action.payload
            };
        default:
            return state;
    }
}

export default reviewsReducer;