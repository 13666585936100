import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest, registerRequest } from "../../actions/authActions";


// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Chip, Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import ShopProduct from './ShopProduct';
import NoFavorite from './NoFavorite';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Favorite(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    React.useEffect(() => {
        if (isInitialMount.current && auth.authenticated) {
            isInitialMount.current = false;
            props.getFavorite(props.dispatch);
        }
    }, []);

    React.useEffect(() => {
        if (auth.authenticated) {
          isInitialMount.current = false;
          props.getFavorite(props.dispatch);
        }
    }, [auth.authenticated]);

    return ( auth.authenticated ?
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Produits Favoris sur Boutigou - Votre Sélection Personnelle</title>
        <meta name="description" content="Gérez votre liste de produits favoris sur Boutigou pour retrouver facilement vos coups de cœur locaux" />
      </Helmet>
    { props.favoriteRed.products.length === 0 ? <Grid item xs={12}><NoFavorite /></Grid> :
      props.favoriteRed.products.map((p, idx) => (
      <Grid key={idx} item lg={4} md={6} sm={6} xs={12}>
        <ShopProduct
          loading={props.favoriteRed.loading}
          name={p.product ? p.product.name : ''}
          picture={p.product ? p.product.picture : ''}
          description={p.product ? p.product.description : ''}
          id={p.product ? p.product.id : ''}
          shop_id={p.product ? p.product.shop : ''}
          discounted={p.product ? p.product.discounted : false}
          shop_name={p.shop_name}
        />
      </Grid>))
      }
    </Grid> :
    <MainCard>
      <Helmet>
        <title>Produits Favoris sur Boutigou - Votre Sélection Personnelle</title>
        <meta name="description" content="Gérez votre liste de produits favoris sur Boutigou pour retrouver facilement vos coups de cœur locaux" />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}