import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationMessage, getNotificationOrder, getNotificationReview, getNotificationCart } from "../../../actions/notificationActions";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import SearchSection from './SearchSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  React.useEffect(() => {
    if (auth.authenticated) {
      dispatch(getNotificationMessage(dispatch));
      dispatch(getNotificationOrder(dispatch));
      dispatch(getNotificationReview(dispatch));
      dispatch(getNotificationCart(dispatch));

      const intervalId = setInterval(() => {
        dispatch(getNotificationMessage(dispatch));
        dispatch(getNotificationOrder(dispatch));
        dispatch(getNotificationReview(dispatch));
        dispatch(getNotificationCart(dispatch))
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [auth.authenticated, dispatch]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      { location.pathname === '/' || location.pathname === '/boutiques' ?  < SearchSection /> : null }
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      { auth.authenticated ? <NotificationSection /> : null }
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
