import * as types from "../types/types";

const initialState = {
    profile: {},
    errors: {},
    loading: false,
    successDelete: null,
    success: null
};

function profileReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PROFILE:
            return {
                ...state,
                profile: action.payload[0],
                errors: {},
                loading: false,
                successDelete: null,
                success: null
            };
        case types.EDIT_PROFILE:
            return {
                ...state,
                loading: false,
                success: true
            };
        case types.FAILED_EDIT_PROFILE:
            return {
                ...state,
                errors: action.payload,
                loading: false,
                success: false
            };
        case types.DELETE_PROFILE:
            return {
                ...state,
                loading: false,
                successDelete: true
            };
        case types.FAILED_DELETE_PROFILE:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_PROFILE:
            return {
                ...state,
                successDelete: null,
                loading: true
            };
        default:
            return state;
    }
}

export default profileReducer;