import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getConversations(dispatch) {
    dispatch({
        type: types.LOADING_GET_CONVERSATIONS
    });
    return async function () {
        axiosAPI
            .get(`${url}/conversation/`)
            .then((res) => { return dispatch({ type: types.GET_CONVERSATIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CONVERSATIONS, payload: error.response.data }) })
    };
}

function getConversation(conv_id, dispatch) {
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/conversation/${conv_id}/`);
      dispatch({ type: types.GET_CONVERSATION, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/conversations';
      } else {
        dispatch({ type: types.FAILED_GET_CONVERSATION, payload: error.response.data });
      }
    }
  };
}

function getMessages(conversation_id, shop_id, dispatch) {
    dispatch({
        type: types.LOADING_MESSAGES
    });
    if (conversation_id)
        return async function () {
            axiosAPI
                .get(`${url}/message/?conversation__id=${conversation_id}&shop_id=${shop_id}`)
                .then((res) => { return dispatch({ type: types.GET_MESSAGES, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_MESSAGES, payload: error.response.data }) })
        };
    else {
        return async function () {
            axiosAPI
                .get(`${url}/message/?conversation__id=-1&shop_id=${shop_id}`)
                .then((res) => { return dispatch({ type: types.GET_MESSAGES, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_MESSAGES, payload: error.response.data }) })
        };
    }
}

function getMessagesSilently(conversation_id, shop_id, dispatch) {
    if (conversation_id)
        return async function () {
            axiosAPI
                .get(`${url}/message/?conversation__id=${conversation_id}&shop_id=${shop_id}`)
                .then((res) => { return dispatch({ type: types.GET_MESSAGES, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_MESSAGES, payload: error.response.data }) })
        };
}

function addMessage(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/message/`, item)
            .then((res) => { return dispatch({ type: types.ADD_MESSAGE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_MESSAGE, payload: error.response.data }) })
    };
}


export {
    getConversations,
    getConversation,
    getMessages,
    getMessagesSilently,
    addMessage
};
