import { connect } from "react-redux";

import ShopReview from "../views/shopReview";

import {
    getShopReviews
} from "../actions/shopReviewActions.js";

import {
    getShop
} from "../actions/shopActions.js";

const mapStateToProps = state => ({
    shopReviewRed: state.shopReview,
    shopRed: state.shop
});

const mapDispatchToProps = dispatch => ({
    getShopReviews: (dispatch, shop_id) =>
        dispatch(getShopReviews(dispatch, shop_id)),
    getShop: (dispatch, shop_id) =>
        dispatch(getShop(dispatch, shop_id)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopReview);
