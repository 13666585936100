import { connect } from "react-redux";

import Home from "../views/home";

import {
    getShops,
} from "../actions/homeActions.js";


const mapStateToProps = state => ({
    homeRed: state.home
});

const mapDispatchToProps = dispatch => ({
    getShops: (dispatch) =>
        dispatch(getShops(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
