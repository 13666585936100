import { connect } from "react-redux";

import Review from "../views/review";

import {
    getReview,
    addReview,
    getOrderReview
} from "../actions/reviewActions.js";

const mapStateToProps = state => ({
    reviewRed: state.review,
});

const mapDispatchToProps = dispatch => ({
    getReview: (sub_order_id, dispatch) =>
        dispatch(getReview(sub_order_id, dispatch)),
    addReview: (sub_order_id, dispatch) =>
        dispatch(addReview(sub_order_id, dispatch)),
    getOrderReview: (sub_order_id, dispatch) =>
        dispatch(getOrderReview(sub_order_id, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);

