import * as React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Rating, Button, OutlinedInput } from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 4,
    background: '#fff'
  }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ReviewCard = ({ loading, content, created_at, rate, response, answered_at, shop_name, order_id, review_id, props}) => {
  const theme = useTheme();
  const [nextRate, setNextRate] = React.useState(null);
  const [nextContent, setNextContent] = React.useState(null);
  const navigate = useNavigate();

  const navigateOrder = (order_id) => {
    navigate(`/commandes/${order_id}`);
  };

  const handleAddReview = () => {
    const item = {
      'content': nextContent,
      'rate': nextRate,
      'order': props.reviewRed.orderReview.order ? props.reviewRed.orderReview.order.id : null,
      'sub_order': order_id
    }
    props.addReview(item, props.dispatch)
  }

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            { review_id ?
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h3" sx={{ mt: 1.75, mb: 0.75 }}>Vous avez laissé un avis à {shop_name} le {moment(created_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  alignItems="center">
                  <Typography
                    sx={{ color: theme.palette.secondary[200] }}
                  >
                    < Rating name="half-rating-read" value={rate} precision={1} readOnly /> ({rate}/5)
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 0.50, mb: 0.75, color: theme.palette.secondary[200] }}>{content}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                { response ?
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h3" sx={{ mt: 1.75, mb: 0.75 }}>Réponse le {moment(answered_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
                    </Grid>
                  </Grid>
                </Grid> : null }
                { response ?
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mb: 0.75, color: theme.palette.secondary[200] }}>{response}</Typography>
                    </Grid>
                  </Grid>
                </Grid>              
                : null}
                <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: '#fff',
                        textAlign: 'right',
                      }}
                      onClick={() => navigateOrder(`${props.reviewRed.orderReview.order ? props.reviewRed.orderReview.order.id : null}`)}
                    >
                      Voir la commande associée
                    </Button>
                  </Grid>
                </Grid>
              </Grid> : null}

              { !review_id ?
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h3" sx={{ mt: 1.75, mb: 0.75 }}>Laissez votre avis</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item  alignItems="center" sx={{ mt: 3 }}>
                  <Typography
                    sx={{ color: theme.palette.secondary[200] }}
                  >
                    < Rating name="half-rating-read" value={nextRate} precision={1} onChange={(event, newValue) => {setNextRate(newValue)}} /> ({nextRate}/5)
                  </Typography>
                  {props.reviewRed.errors.rate && (
                      <Typography variant="body2" color="error">
                        {props.reviewRed.errors.rate.message}
                      </Typography>
                    )}
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12} sx={{ my: 3 }}>
                    <OutlineInputStyle
                      id="input-review-header"
                      maxWidth={false}
                      value={nextContent}
                      onChange={(e) => {setNextContent(e.target.value)}}
                      placeholder="Ecrire..."
                      aria-describedby="review-text"
                      inputProps={{
                        'aria-label': 'weight',
                        maxLength: 499,
                      }}
                      multiline
                      rows={4}
                    />
                    {props.reviewRed.errors.content && (
                      <Typography variant="body2" color="error">
                        {props.reviewRed.errors.content.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        textAlign: 'right',
                        mr: 2
                      }}
                      onClick={() => navigateOrder(`${order_id}`)}
                    >
                      Voir la commande associée
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: '#fff',
                        textAlign: 'right',
                      }}
                      onClick={handleAddReview}
                    >
                      Envoyer
                    </Button>
                  </Grid>
                </Grid>
              </Grid> : null}

          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ReviewCard.propTypes = {
  isLoading: PropTypes.bool
};

export default ReviewCard;
