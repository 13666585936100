import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
// material-ui
import { Grid, Typography, Box, Link, Breadcrumbs } from '@mui/material';
// project imports
import ReviewCard from './ReviewCard';
import NoReview from './NoReview';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function ShopReview(props) {
    const isInitialMount = React.useRef(true);
    const { shop_id } = useParams();

    React.useEffect(() => {
      props.getShopReviews(props.dispatch, shop_id);
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShop(props.dispatch, shop_id);
            props.getShopReviews(props.dispatch, shop_id);
        }
    }, []);

    return (
    <Grid container spacing={gridSpacing}>
      <Helmet>
        <title>Avis de {props.shopRed.shop ? props.shopRed.shop.name : null} sur Boutigou - Feedback des Consommateurs</title>
        <meta name="description" content="Découvrez les avis des consommateurs sur vos boutiques préférées sur Boutigou et choisissez en toute confiance." />
      </Helmet>
      <Box sx={{ p: 2.25 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/" variant="h4">
            Boutiques
          </Link>
          <Link underline="hover" color="inherit" href={`/boutiques/${shop_id}`} variant="h4">
            {props.shopRed.shop ? props.shopRed.shop.name : null}
          </Link>
          <Typography color="text.primary" variant="h4">Avis</Typography>
        </Breadcrumbs>
      </Box>
      <Grid item xs={12}>
        {props.shopReviewRed.reviews.length === 0 ? < NoReview shop_name={props.shopRed.shop ? props.shopRed.shop.name : null} /> :
        <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
          {props.shopReviewRed.reviews.map((p, idx) => (
            <Grid key={idx} item lg={4} md={6} sm={6} xs={12}>
              <ReviewCard
                loading={props.shopReviewRed.loading}
                rate={p.rate}
                content={p.content}
                created_at={p.created_at}
                response={p.response}
                answered_at={p.answered_at}
                consumer={p.consumer ? p.consumer.username : ''}
              />
            </Grid>
          ))}
        </Grid> }
      </Grid>
    </Grid>
  );
}