import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getCart(dispatch) {
    dispatch({
        type: types.LOADING_ORDER
    });
    return async function () {
        axiosAPI
            .get(`${url}/cart_product/`)
            .then((res) => { return dispatch({ type: types.GET_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CART, payload: error.response.data }) })
    };
}

function getCartDetails(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/cart_product/details/`)
            .then((res) => { return dispatch({ type: types.GET_CART_DETAILS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CART_DETAILS, payload: error.response.data }) })
    };
}

function addProductToCart(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/cart_product/`, item)
            .then((res) => { return dispatch({ type: types.ADD_PRODUCT_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_PRODUCT_CART, payload: error.response.data }) })
    };
}


function removeProductFromCart(item, dispatch) {
    return async function () {
        axiosAPI
            .delete(`${url}/cart_product/${item.id}/`)
            .then((res) => { return dispatch({ type: types.REMOVE_PRODUCT_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_REMOVE_PRODUCT_CART, payload: error.response.data }) })
    };
}

function editProductFromCart(item, dispatch) {
    return async function () {
        axiosAPI
            .put(`${url}/cart_product/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.EDIT_PRODUCT_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_EDIT_PRODUCT_CART, payload: error.response.data }) })
    };
}

function getPaymentMethodOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/order_payment_method_options/`)
            .then((res) => { return dispatch({ type: types.GET_PAYMENT_METHOD_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_PAYMENT_METHOD_OPTIONS, payload: error.response.data }) })
    };
}

function choosePaymentMethodOptions(item, dispatch) {
    dispatch({
        type: types.LOADING_ORDER_PAYMENT_METHOD
    });
    return async function () {
        axiosAPI
            .patch(`${url}/cart/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.ADD_PAYMENT_METHOD_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_PAYMENT_METHOD_OPTIONS, payload: error.response.data }) })
    };
}

function getOrderDeliveryLocationOptions(dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_delivery_location_options/`)
            .then((res) => { return dispatch({ type: types.GET_DELIVERY_LOCATION_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_DELIVERY_LOCATION_OPTIONS, payload: error.response.data }) })
    };
}

function getOrderDeliveryDayOptions(id, dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_delivery_day_options/${id}/`)
            .then((res) => { return dispatch({ type: types.GET_DELIVERY_DAY_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_DELIVERY_DAY_OPTIONS, payload: error.response.data }) })
    };
}

function getOrderDeliverySlotOptions(id, day, date, dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_delivery_slot_options/${id}/?day=${day}&date=${date}`)
            .then((res) => { return dispatch({ type: types.GET_DELIVERY_SLOT_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_DELIVERY_SLOT_OPTIONS, payload: error.response.data }) })
    };
}


function getOrderDeliveryValidate(begin, end, dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_delivery_validate/?begin=${begin}&end=${end}`)
            .then((res) => { return dispatch({ type: types.GET_DELIVERY_VALIDATE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_DELIVERY_VALIDATE, payload: error.response.data }) })
    };
}



function addOrder(item, dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .post(`${url}/order_payment/`, item)
            .then((res) => { return dispatch({ type: types.ADD_ORDER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_ORDER, payload: error.response.data }) })
    };
}

function failedCardOrder(dispatch) {
    return dispatch({
        type: types.FAILED_CARD_ORDER
    });
}


function getOrderPaymentIntent(dispatch) {
    dispatch({
        type: types.LOADING_ORDER_STEP
    });
    return async function () {
        axiosAPI
            .get(`${url}/order_payment_intent/`)
            .then((res) => { return dispatch({ type: types.GET_STRIPE_PUBLISHABLE_KEY, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_STRIPE_PUBLISHABLE_KEY, payload: error.response.data }) })
    };
}

function addOrderPaymentIntent(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/order_payment_intent/`, item)
            .then((res) => { return dispatch({ type: types.ADD_ORDER_PAYMENT_INTENT, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_ORDER_PAYMENT_INTENT, payload: error.response.data }) })
    };
}


function closeAlertCart(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_CART
    });
}


function closeOrderPaymentIntent(dispatch) {
    return dispatch({
        type: types.CLOSE_ORDER_PAYMENT_INTENT
    });
}

function closeOrderPaymentSuccess(dispatch) {
    return dispatch({
        type: types.SET_ORDER_PAYMENT_SUCCESS
    });
}

export {
    getCart,
    getCartDetails,
    addProductToCart,
    editProductFromCart,
    removeProductFromCart,
    getPaymentMethodOptions,
    choosePaymentMethodOptions,
    getOrderDeliveryLocationOptions,
    getOrderDeliveryDayOptions,
    getOrderDeliverySlotOptions,
    getOrderDeliveryValidate,
    addOrder,
    failedCardOrder,
    getOrderPaymentIntent,
    addOrderPaymentIntent,
    closeAlertCart,
    closeOrderPaymentIntent,
    closeOrderPaymentSuccess
};
