import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  Backdrop
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import gif_loading from 'assets/images/gif_loading.gif';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getTokenReset, passwordReset } from "../../../../actions/authActions";

// ===========================|| FIREBASE - REGISTER ||=========================== //

const FirebaseReset = ({ ...others }) => {
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1)
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [email, setEmail] = useState(null)
  const [token, setToken] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
    setPassword(value)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (auth.successGetToken === true) {
      setStep(2)
    }
    setLoading(false)
  }, [auth.successGetToken]);

  React.useEffect(() => {
    setLoading(false)
  }, [auth.successPasswordReset]);

  const sendResetPasswordEmail = () => {
    setLoading(true)
    const data = { 'email': email };
    dispatch(getTokenReset(data, dispatch));
  }

  const resetPasswordEmail = () => {
    setLoading(true)
    const data = { 'email': email, 'password': password, 'confirmPassword': confirmPassword, 'token': token };
    dispatch(passwordReset(data, dispatch));
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Vous recevrez un courriel si un compte existe.</Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
      >
        {({ errors,  isSubmitting, touched }) => (
          <form noValidate {...others}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            { step === 1 ? (
              <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-email-register">Adresse e-mail</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-register"
                  type="email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{}}
                />
                {auth.errorsGetToken.email && (
                  <FormHelperText error id="standard-weight-helper-text--register">
                    {auth.errorsGetToken.email.message}
                  </FormHelperText>
                )}
              </FormControl> ) : null}
            { step === 2 ? (
              <Grid container spacing={matchDownSM ? 0 : 1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Code"
                    margin="normal"
                    name="fname"
                    type="text"
                    defaultValue=""
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    sx={{ ...theme.typography.customInput }}
                    error={auth.errorsPasswordReset.token || auth.errorsPasswordReset.null ? true : false}
                    helperText={auth.errorsPasswordReset.token ? auth.errorsPasswordReset.token.message : auth.errorsPasswordReset.null ? auth.errorsPasswordReset.null.message : null }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={Boolean(auth.errorsPasswordReset.password || auth.errorsPasswordReset.null)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-password-register">Mot de passe</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password-register"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      name="password"
                      label="Password"
                      onChange={(e) => {
                        changePassword(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{}}
                    />
                    {(auth.errorsPasswordReset.password || auth.errorsPasswordReset.null) && (
                      <FormHelperText error id="standard-weight-helper-text-password-register">
                        {auth.errorsPasswordReset.password ? auth.errorsPasswordReset.password.message : auth.errorsPasswordReset.null ? auth.errorsPasswordReset.null.message : null }
                      </FormHelperText>
                    )}
                  </FormControl>                 
                </Grid>

                {strength !== 0 && (
                  <FormControl fullWidth>
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Box style={{ backgroundColor: level?.color }} sx={{ width: 85, height: 8, borderRadius: '7px' }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" fontSize="0.75rem">
                            {level?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormControl>
                )}

                <Grid item xs={12}>
                  <FormControl fullWidth error={Boolean(auth.errorsPasswordReset.password || auth.errorsPasswordReset.null)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-password-register">Confirmez le mot de passe</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password-register"
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      name="password"
                      label="Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                            size="large"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{}}
                    />
                  </FormControl>                 
                </Grid>
              </Grid> ) : null }
            

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                { step === 1 ? (
                <Button onClick={sendResetPasswordEmail} disableElevation disabled={isSubmitting} fullWidth size="large" variant="contained" color="secondary">
                  Recevoir mon code
                </Button>) : null }
                { step === 2 ? (
                <Button onClick={resetPasswordEmail} disableElevation disabled={isSubmitting} fullWidth size="large" variant="contained" color="secondary">
                  Valider
                </Button>) : null }
              </AnimateButton>
              <Box sx={{ mt: 2 }}>
              <AnimateButton>
                { step === 2 ? (
                <Button onClick={() => setStep(1)} disableElevation disabled={isSubmitting} fullWidth size="large" variant="outlined" color="secondary">
                  Renvoyer
                </Button>) : null }
              </AnimateButton>
            </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseReset;
