import * as types from "../types/types";

const initialState = {
    shop: {},
    products: [],
    delivery_options: [],
    event_options: [],
    shop_partners: [],
    loading: false,
    errors: {},
    count_reviews: 0,
    avg_reviews: 0,
    conv: {},
    successMessage: null,
    loadingMessage: false,
    errorsMessage: {}
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload,
                loading: false,
                errors: {},
                errorsMessage: {},
                successMessage: null,
                loadingMessage: false
            };
        case types.FAILED_GET_SHOP:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP:
            return {
                ...state,
                shop: {},
                loading: true,
            };
        case types.GET_SHOP_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false,
            };
        case types.FAILED_GET_SHOP_PRODUCTS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP_PRODUCTS:
            return {
                ...state,
                products: [],
                loading: true,
            };
        case types.GET_SHOP_DELIVERY_OPTIONS:
            return {
                ...state,
                delivery_options: action.payload,
                loading: false,
            };
        case types.FAILED_GET_SHOP_DELIVERY_OPTIONS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP_DELIVERY_OPTIONS:
            return {
                ...state,
                delivery_options: [],
                loading: true,
            };
        case types.GET_SHOP_REVIEWS:
            return {
                ...state,
                count_reviews: action.payload.length,
                avg_reviews: (action.payload.reduce((a, b) => a + b.rate, 0) / action.payload.length) || 0,
            };
        case types.GET_CONVERSATION:
            return {
                ...state,
                conv: action.payload.length > 0 ? action.payload[0] : {}
            };
        case types.ADD_MESSAGE:
            return {
                ...state,
                successMessage: true,
                loadingMessage: false,
                errorsMessage: {},
            };
        case types.FAILED_ADD_MESSAGE:
            return {
                ...state,
                successMessage: false,
                errorsMessage: action.payload,
                loadingMessage: false
            };
        case types.LOADING_MESSAGES:
            return {
                ...state,
                loadingMessage: true,
            };
        case types.GET_SHOP_PARTNERS:
            return {
                ...state,
                shop_partners: action.payload,
                loading: false,
            };
        case types.FAILED_GET_SHOP_PARTNERS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP_PARTNERS:
            return {
                ...state,
                shop_partners: [],
                loading: true,
            };
        case types.GET_SHOP_EVENT_OPTIONS:
            return {
                ...state,
                event_options: action.payload,
                loading: false,
            };
        case types.FAILED_GET_SHOP_EVENT_OPTIONS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP_EVENT_OPTIONS:
            return {
                ...state,
                event_options: [],
                loading: true,
            };
        default:
            return state;
    }
}

export default shopReducer;