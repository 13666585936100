import * as types from "../types/types";

const initialState = {
    cgu: {},
    loading: false,
    errors: {},
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CGU:
            return {
                ...state,
                cgu: action.payload[0],
                loading: false,
            };
        case types.FAILED_GET_CGU:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

export default shopReducer;