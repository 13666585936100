import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography, Chip, Stack, List, ListItem, ListItemIcon, ListItemText, Rating, Tooltip, Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    OutlinedInput,
    Backdrop,
    ListItemButton,
    ListItemAvatar,
    Divider,
    Container,
    Badge,
    Fab,
    Link } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import { shouldForwardProp } from '@mui/system';
import { authRequest } from "../../actions/authActions";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
// project imports
import gif_loading from 'assets/images/gif_loading.gif';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({

  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 4,
    background: '#fff'
  }
}));

function FormattedText({ text }) {

  const sections = text.split('\n\n').map(section => section.split('\n'));

  return (
    <Container maxWidth={false} disableGutters>
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {section.map((part, partIndex) => {
            let variant = 'body1';
            if (partIndex === 0 && section.length > 1) {
              variant = 'h3';
            }
            
            return (
              <Typography
                key={partIndex}
                variant={variant}
                paragraph
                component={partIndex === 0 && section.length > 1 ? 'h3' : 'p'}
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                {part}
              </Typography>
            );
          })}
        </React.Fragment>
      ))}
    </Container>
  );
}

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ShopCard = ({ shop_id, loading, name, cover, profile, category, category_article, description, address, delivery_options, avg_reviews, count_reviews, conv, props, openMessage, setOpenMessage, payment_methods, event_options }) => {
  const theme = useTheme();
  const [content, setContent] = React.useState(null)
  const [openEvent, setOpenEvent] = React.useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const navigateReviews = (shop_id) => {
    navigate(`/boutiques/${shop_id}/avis`);
  };

  const navigatePartners = (shop_id) => {
    navigate(`/boutiques/${shop_id}`);
  };

  const navigateConversation = (conv) => {
    if (auth.authenticated === false) {
      dispatch(authRequest(dispatch));
    } else if (conv.id) {
      navigate(`/conversations/${conv.id}`);
    } else {
      setOpenMessage(true)
    }
  };

  const handleAddMessage = () => {
    const item = {
      'content': content,
      'shop_id': shop_id
    }
    props.addMessage(item, props.dispatch)
  }

  const days = {
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi',
    7: 'Dimanche',
  }

  const numberToDate = (value) => {
    var seconds = value % 60;
    var minutes = Math.floor(value / 60);

    let secondsString, minutesString;

    if (seconds < 10) {
      secondsString = `0${seconds}`.toString();
    } else {
      secondsString = seconds.toString();
    }

    if (minutes < 10) {
      minutesString = `0${minutes}`.toString();
    } else {
      minutesString = minutes.toString();
    }

    return `${minutesString}:${secondsString}`.toString();
  };



  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.shopRed.loadingMessage}
          >
            <Box
              component="img"
              sx={{
                height: 250,
                mb: 4
              }}
              alt="Loading..."
              src={gif_loading}
            />
          </Backdrop>
          <Dialog maxWidth="lg" open={openMessage} fullWidth>
            <DialogTitle variant="h2">Contacter {name}:</DialogTitle>
            <DialogContent>
              <OutlineInputStyle
                id="input-search-header"
                maxWidth={false}
                value={content || ''}
                onChange={(e) => {setContent(e.target.value)}}
                placeholder="Ecrire..."
                inputProps={{ 'aria-label': 'weight', maxLength: 1000, }}
                multiline
              />
              {props.shopRed.errorsMessage.content && (
                <Typography variant="body2" color="error">
                  {props.shopRed.errorsMessage.content.message}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenMessage(false)}>Fermer</Button>
              <Button onClick={handleAddMessage} variant="contained" disabled={content === null || content === ''}>Envoyer</Button>
            </DialogActions>
          </Dialog>

          <Dialog maxWidth="lg" open={openEvent} fullWidth>
            <DialogTitle variant="h2">Evénements de {name}:</DialogTitle>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            { (event_options || []).map((it, idx) => (
              <><ListItem key={idx} alignItems="flex-start">
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        variant="h3"
                        color="text.primary"
                      >
                        {it.title}
                      </Typography>
                    </React.Fragment>}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        variant="h5"
                        color="text.primary"
                      >
                        {it.description}
                      </Typography>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <LocationOnIcon sx={{ color: '#697586' }} />
                        <Typography 
                          sx={{ color: '#697586' }}
                          variant="h5"
                        >
                          {it.address}, {it.zipcode} {it.city}
                        </Typography>
                      </Stack>
                      
                      <Stack direction="row" alignItems="center" gap={1}>
                        <AccessTimeIcon sx={{ color: '#697586' }} />
                        <Typography 
                          sx={{ color: '#697586' }}
                          variant="h5"
                        >
                          Du {moment(it.date_from).format('dddd Do MMMM YYYY à h:mm:ss')} au {moment(it.date_to).format('dddd Do MMMM YYYY à h:mm:ss')}
                        </Typography>
                      </Stack>

                      { it.link ? (<Stack direction="row" alignItems="center" gap={1}>
                        <OpenInNewIcon sx={{ color: '#697586' }} />
                        <Typography 
                          sx={{ color: '#697586' }}
                          variant="h5"
                        >
                          Lien: <Link target="_blank" href={it.link}>{it.link}</Link>
                        </Typography>
                      </Stack>) : null}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" /></>))}
            </List>
            <DialogActions>
              <Button onClick={() => setOpenEvent(false)}>Fermer</Button>
            </DialogActions>
          </Dialog>

          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Box
                sx={{
                  backgroundImage:`url(${cover ? cover.replace('http://django', '') : ''})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  backgroundPosition: "center center",
                  borderRadius: "12px",
                  height: {xs: '150px', sm: '300px', md: '500px'},
                  }}
              >
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        src={profile ? profile.replace('http://django', '') : ''}
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.largeAvatar,
                          color: '#D8D8D8',
                          backgroundColor: 'transparent',
                          m: 1,
                          height: {xs: '75px', sm: '150px', md: '200px'},
                          width: {xs: '75px', sm: '150px', md: '200px'},
                          cursor: 'auto',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Fab size="small" color="secondary" sx={{ m: 1 }} aria-label="add" style={{ display: event_options.length === 0 ? 'none' : 'inline-flex' }}>
                        <Badge badgeContent={event_options.length} color="primary">
                          <EventIcon onClick={() => setOpenEvent(true) } />
                        </Badge>
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              
              <Grid container spacing={2} justifyContent="space-between" mt={1} mb={2}>
                <Grid item xs={12} lg={9} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, textAlign: {xs: 'center'} }}>
                  <Typography
                    variant="h1"
                  >
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'} }}>
                  <Tooltip title="En savoir plus">
                    <Chip
                      label={category}
                      sx={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.secondary[200] }}
                      href={category_article}
                      clickable
                      component="a"
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent="space-between" mb={2}>
                <Grid item xs={12} lg={9} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'} }}>
                  { count_reviews === 0 ?
                    <Chip
                      label="Aucun avis"
                      sx={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.secondary[200], mr: 1 }}
                      onClick={() => navigateReviews(`${shop_id}`)}
                    />
                    :
                    <Tooltip title="Voir les avis">
                    <Chip
                      label={<Box sx={{ alignItems: 'center', display: 'flex' }}><Rating name="half-rating-read" value={avg_reviews} precision={1} readOnly /><Box sx={{ display: 'inline-block', ml: 2 }}>{count_reviews} avis</Box><NavigateNextIcon /></Box>}
                      sx={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.secondary[200], mr: 1 }}
                      onClick={() => navigateReviews(`${shop_id}`)}
                    />
                    </Tooltip>}
                </Grid>
                <Grid item xs={12} lg={3} sx={{ display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'} }}>
                <Button
                    variant="contained"
                    onClick={() => navigateConversation(conv)}
                >
                  Contacter
                </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent="space-between" mb={2}>
                <Grid item xs={12} lg={9}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <LocationOnIcon sx={{ color: '#697586' }} />
                    <Typography 
                      sx={{ color: '#697586' }}
                      variant="h4"
                    >
                      {address}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} mb={1.25} width="100%">
              <Grid item xs={12} md={6} sx={{ mb: 1.25 }}>
                <Typography
                  variant="h2"
                >
                  A propos de nous:
                </Typography>

                <FormattedText text={description || ''} />
              </Grid>

              <Grid item xs={12} md={6} sx={{ mb: 1.25 }}>
                <Grid item sx={{ mb: 1.25 }}>
                    <Typography
                      variant="h2"
                    >
                      Moyens de paiement acceptés:
                    </Typography>
                    <List
                      sx={{ width: '100%' }}
                    >
                      { (payment_methods || []).map((it, idx) => (
                        <ListItemButton key={idx}>
                          <ListItemAvatar>
                            <PaymentIcon />
                          </ListItemAvatar>
                          <ListItemText primary={it.type === false ? `En ligne via ${it.name}` : `Sur place via ${it.name}`}/>
                        </ListItemButton>
                      ))}
                    </List>
                  </Grid>
                { props.shopRed.shop_partners.length !== 0 ?
                  <Grid item sx={{ mb: 1.25 }}>
                    <Typography
                      variant="h2"
                    >
                      Partenaires:
                    </Typography>
                    <List
                      sx={{ width: '100%' }}
                    >
                      { props.shopRed.shop_partners.map((it, idx) => (
                        <ListItemButton key={idx} onClick={() => navigatePartners(it.from_shop ? (it.from_shop.id === shop_id ? it.to_shop.id : it.from_shop.id ) : null)}>
                          <ListItemAvatar>
                            <Avatar alt="Profil" sx={{ backgroundColor: 'transparent', }} src={it.from_shop ? (it.from_shop.id === shop_id ? it.to_shop.profile.replace('http://django', '') : it.from_shop.profile.replace('http://django', '') ) : null} />
                          </ListItemAvatar>
                          <ListItemText primary={it.from_shop ? (it.from_shop.id === shop_id ? it.to_shop.name : it.from_shop.name ) : null}/>
                        </ListItemButton>
                      ))}
                    </List>
                  </Grid> : null}
                <Grid item sx={{ mb: 1.25 }}>
                  <Typography
                    variant="h2"
                  >
                    Horaires:
                  </Typography>

                  { delivery_options.map((it, idx) => (
                    <List
                      key={idx}
                      sx={{ width: '100%' }}
                      component="nav"
                    >
                      <ListItem>
                        <ListItemIcon>
                          <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={it.name} secondary={`${it.address}, ${it.zipcode} ${it.city}`}/>
                      </ListItem>
                      <List component="div" disablePadding>
                        { Object.keys(it.slots).map((s, idxs) => (
                          <ListItem sx={{ pl: 4 }} key={idxs}>
                            <ListItemIcon>
                              <AccessTimeIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${days[s]} ${
                                it.slots[s].map(
                                  (sl) => `${numberToDate(sl.from)} - ${numberToDate(sl.to)}`
                                )
                              }`}
                            />
                          </ListItem>))}
                      </List>
                    </List>))}
                  </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ShopCard.propTypes = {
  isLoading: PropTypes.bool
};

export default ShopCard;
