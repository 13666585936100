import * as React from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  marginBottom: 8 
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const PaymentMethod = ({ loading, payment_method_options, cart_payment_method, props, cart_id }) => {

  const [selectedValue, setSelectedValue] = React.useState(cart_payment_method);

  React.useEffect(() => {
    setSelectedValue(cart_payment_method)
    }, [loading]);

  const handleChange = (event) => {
    setSelectedValue(parseInt(event.target.value, 10));
    const item = {
      'id': cart_id,
      'payment_methods': parseInt(event.target.value, 10)
    }
    props.choosePaymentMethodOptions(item, props.dispatch)
  };

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25}}>
            <Grid container direction="column" alignItems="left">
              <FormControl>
               <Typography variant="h2" id="radio-buttons-group-label">Moyen de paiement:</Typography>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={cart_payment_method}
                  name="radio-buttons-group"
                >
                  {payment_method_options.map((it) => (
                    <FormControlLabel
                        checked={selectedValue === it.id}
                        onChange={handleChange}
                        key={it.id}
                        value={it.id}
                        control={<Radio />}
                        label={
                            <Typography variant="h4"> 
                                {it.type === false ? `En ligne via ${it.name}` : `Sur place via ${it.name}`}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

PaymentMethod.propTypes = {
  isLoading: PropTypes.bool
};

export default PaymentMethod;