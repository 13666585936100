// material-ui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';


// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const NoConversation = () => {

  return (
    <CardWrapper border={false} content={false} onClick={() => navigateOrderDetail(`${id}`)}>
      <Box
        sx={{
          p: 2.25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          alt="No Data"
          style={{ maxWidth: '80%', maxHeight: '80%' }}
          src={nodata}
        />
        <Typography variant="h4">Choisissez une conversation sur votre gauche.</Typography>
      </Box>
    </CardWrapper>
  );
};


export default NoConversation;
