// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeSoutienProducteursLocaux() {

    return (
      <>
        <Helmet>
          <title>Soutien aux Producteurs Locaux - Achetez Responsable | Boutigou</title>
          <meta name="description" content="Découvrez comment Boutigou facilite le soutien aux producteurs locaux grâce à des initiatives responsables. De l&apos;achat direct à la promotion de l&apos;agriculture durable." />
        </Helmet>
        <MainCard title="L&apos;Économie Locale et le Développement Durable, kesako ?" sx={{ mb: 4 }}>

          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h2" component="div">
                    Acheter Directement aux Producteurs : Impact et Avantages
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/acheter-direct-producteurs-impact-avantages">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h2" component="div">
                    Découvrez les Avantages de l&lsquo;Achat Direct de Fruits et Légumes Chez les Producteurs Locaux
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/achat-direct-fruits-legumes-producteurs-locaux">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h2" component="div">
                    L&lsquo;importance du soutien aux petites exploitations agricoles locales
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/soutien-petites-exploitations-agricoles">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </MainCard>
      </>
  );
}