import { connect } from "react-redux";

import Conversation from "../views/conversation";

import {
    getConversations,
    getConversation,
    getMessages,
    addMessage,
    getMessagesSilently
} from "../actions/conversationActions.js";


const mapStateToProps = state => ({
    conversationRed: state.conversation
});

const mapDispatchToProps = dispatch => ({
    getConversations: (dispatch) =>
        dispatch(getConversations(dispatch)),
    getConversation: (conv_id, dispatch) =>
        dispatch(getConversation(conv_id, dispatch)),
    getMessages: (conversation_id, shop_id, dispatch) =>
        dispatch(getMessages(conversation_id, shop_id, dispatch)),
    getMessagesSilently: (conversation_id, shop_id, dispatch) =>
        dispatch(getMessagesSilently(conversation_id, shop_id, dispatch)),
    addMessage: (item, dispatch) =>
        dispatch(addMessage(item, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
