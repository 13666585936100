// material-ui imports
import * as React from 'react';
import { Typography, Link } from '@mui/material';
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| CONSTRUIRE UNE COMMUNAUTÉ AUTOUR DE L'ACHAT LOCAL PAGE ||============================== //

export default function Amap() {
    return (
    <MainCard title="Les AMAP, c&apos;est quoi ?">
        <Helmet>
            <title>Les AMAP, c&apos;est quoi ?</title>
            <meta name="description" content="Découvrez le modèle des AMAP (Associations pour le Maintien d&apos;une Agriculture Paysanne), un système de partenariat solidaire entre producteurs locaux et consommateurs." />
        </Helmet>

        <Typography variant="body1" paragraph>
            Découvrir les AMAP, c&apos;est plonger au cœur d&apos;un modèle de consommation responsable ancré dans nos territoires. Tu souhaites consommer des produits frais, locaux et soutenir les agriculteurs de ta région ? Les AMAP (Associations pour le Maintien d&apos;une Agriculture Paysanne) te proposent une alternative durable et solidaire. Ce système de partenariat direct entre producteurs et consommateurs repose sur des valeurs fortes : transparence, qualité et respect de l&apos;environnement. Avant de t&apos;engager, il est essentiel de comprendre comment fonctionnent ces associations et quels bénéfices elles offrent. Prépare-toi à une immersion dans un univers où la consommation rime avec éthique et proximité.
        </Typography>

        <Typography variant="h4" gutterBottom component="h2">
            Comprendre le concept des AMAP
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP : un modèle de consommation responsable au cœur de nos territoires, repose sur un partenariat direct entre un groupe de consommateurs et un ou plusieurs producteurs locaux. Ce système permet de soutenir l&apos;agriculture paysanne tout en favorisant une alimentation de qualité. En adhérant à une AMAP, tu t&apos;engages à acheter régulièrement des produits frais et de saison directement auprès des agriculteurs.
        </Typography>

        <Typography variant="body1" paragraph>
            Le fonctionnement des AMAP repose sur des contrats établis entre les consommateurs et les producteurs. Ces contrats définissent la quantité et la fréquence des livraisons. Tu recevras chaque semaine un panier de produits variés, souvent composés de fruits, légumes, œufs et parfois de viande ou de produits laitiers. Ce modèle réduit les intermédiaires et garantit une rémunération juste pour les agriculteurs.
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP : un modèle de consommation responsable au cœur de nos territoires, favorise aussi les échanges entre les membres et les producteurs. Des réunions et des visites de fermes sont souvent organisées. Ces moments de partage renforcent les liens entre les participants et permettent de mieux comprendre les enjeux de l&apos;agriculture locale. En rejoignant une AMAP, tu participes activement à un projet collectif qui valorise le travail des agriculteurs et préserve l&apos;environnement.
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP sont une réponse concrète aux défis de l&apos;agriculture industrielle. Elles encouragent une consommation plus responsable et locale. En choisissant cette voie, tu contribues à la préservation des terres agricoles et à la promotion de pratiques respectueuses de la nature. Les AMAP incarnent un modèle économique durable et solidaire.
        </Typography>

        <Typography variant="h4" gutterBottom component="h2">
            Découvrir les avantages pour les consommateurs
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP, un modèle de consommation responsable au cœur de nos territoires, offrent de nombreux avantages pour les consommateurs. En rejoignant une AMAP, tu bénéficies de produits frais et de saison, directement issus des producteurs locaux. Cette approche favorise une alimentation saine et diversifiée.
        </Typography>

        <Typography variant="body1" paragraph>
            En choisissant les AMAP, tu soutiens l&apos;économie locale et contribues à réduire l&apos;empreinte carbone liée au transport des aliments. Les produits parcourent moins de distance, ce qui garantit une fraîcheur incomparable.
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP te permettent aussi de tisser des liens sociaux forts avec les producteurs et les autres membres de l&apos;association. Cette relation de confiance se traduit par une meilleure transparence sur les méthodes de production et les conditions de travail. Tu sais exactement d&apos;où viennent tes aliments et comment ils sont cultivés.
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP te donnent accès à des produits souvent cultivés sans pesticides ni engrais chimiques. Cela favorise une agriculture plus respectueuse de l&apos;environnement et de la biodiversité.
        </Typography>

        <Typography variant="h4" gutterBottom component="h2">
            Explorer les bénéfices pour les producteurs locaux
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP offrent de nombreux bénéfices pour les producteurs locaux. En adhérant à ce système, tu soutiens directement les agriculteurs de ta région. Grâce à un engagement solidaire, les producteurs peuvent planifier leur production et réduire les risques financiers.
        </Typography>

        <Typography variant="body1" paragraph>
            Un des avantages majeurs pour les producteurs est la garantie d&apos;un revenu stable. Les paniers sont prépayés par les consommateurs, ce qui permet aux agriculteurs de recevoir une rémunération juste et équitable. Les AMAP favorisent ainsi une agriculture durable et respectueuse de l&apos;environnement.
        </Typography>

        <Typography variant="body1" paragraph>
            La proximité avec les consommateurs est un autre atout. Les échanges directs entre producteurs et consommateurs permettent de créer une relation de confiance. Les producteurs peuvent partager leurs savoir-faire et sensibiliser sur les enjeux de l&apos;agriculture locale. Cette relation enrichit l&apos;expérience de consommer des produits frais et de saison.
        </Typography>

        <Typography variant="h4" gutterBottom component="h2">
            Participer activement à une AMAP
        </Typography>

        <Typography variant="body1" paragraph>
            Participer activement à une AMAP, c&apos;est bien plus que simplement acheter des légumes. Tu t&apos;engages à soutenir directement les agriculteurs locaux. En choisissant ce modèle de consommation, tu contribues au développement de l&apos;agriculture durable et à la préservation de notre environnement.
        </Typography>

        <Typography variant="body1" paragraph>
            Les AMAP te permettent de recevoir des produits frais, de saison et souvent bio. Tu fais partie d&apos;une communauté solidaire où la transparence et la confiance règnent.
        </Typography>

        <Link size="small" href="/kesako/vracs/gaec-guide-complet" variant="body2">
            Cet article pourrait vous intéresser : GAEC : Guide complet
        </Link>

    </MainCard>
    );
}
