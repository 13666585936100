// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeEducationSensibilisation() {

    return (
      <>
        <Helmet>
          <title>Éducation et Sensibilisation à l&apos;Achat Local | Boutigou</title>
          <meta name="description" content="Engagez-vous pour un avenir durable avec Boutigou. Découvrez comment l&apos;éducation et la sensibilisation peuvent renforcer l&apos;achat local et soutenir notre planète." />
        </Helmet>
        <MainCard title="Éducation et Sensibilisation, kesako ?" sx={{ mb: 4 }}>
          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    5 avantages de la consommation responsable.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/5-avantages-de-la-consommation-responsable">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    L&apos;Agriculture Durable.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/education-sensibilisation-agriculture-durable">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Les bienfaits des plantes médicinales locales et comment les utiliser.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/plantes-medicinales-locales">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Régimes alimentaires et produits locaux : véganisme, sans gluten, etc.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/regimes-alimentaires-produits-locaux">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </MainCard>
      </>
  );
}