import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'; //useNavigate

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Container, Slide, Button } from '@mui/material'; //Fab, Tooltip
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CookieConsent from 'react-cookie-consent';
import { useCookies } from 'react-cookie';
import age_check from 'assets/images/age_check.png';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import Login from './Authentication/authentication3/Login3';
import Register from './Authentication/authentication3/Register3';
import Reset from './Authentication/authentication3/Reset3';

import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'types/types';

// assets
import { IconChevronRight } from '@tabler/icons';

import facebook from 'assets/images/icons8-facebook.svg';
import instagram from 'assets/images/icons8-instagram.svg';
import linkedin from 'assets/images/icons8-linkedin.svg';

const socialMediaLinks = {
  facebook: 'https://www.facebook.com/boutigoufr',
  instagram: 'https://www.instagram.com/boutigoufr/',
  linkedin: 'https://www.linkedin.com/company/boutigou/'
};

const Instagram = () => (
  <img src={instagram} alt="instagram" width="25" className="social-icon" />
);

const Facebook = () => (
  <img src={facebook} alt="facebook" width="25" className="social-icon" />
);

const Linkedin = () => (
  <img src={linkedin} alt="linkedin" width="25" className="social-icon" />
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  minHeight: '100vh',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: 'rgb(238, 242, 246)',
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const [cookies, setCookie] = useCookies(['ageVerified']);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      
        <Box position='fixed' top='42%' transform='translate(0px, 50%)' right='0' display='flex' flexDirection='column' alignItems='center' boxShadow='0 0 10px rgba(0, 0, 0, 0.1)'>
            <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook} target="_blank">
              <Facebook />
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram} target="_blank">
              <Instagram />
            </IconButton>
            <IconButton aria-label="LinkedIn" color="inherit" component="a" href={socialMediaLinks.linkedin} target="_blank">
              <Linkedin />
            </IconButton>
        </Box>
      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Container maxWidth={false} sx={{ minHeight: '100vh', }}>
          <Outlet/>
        </Container>
        <Footer />
        <CookieConsent buttonText="J'accepte">
          Ce site web utilise des cookies pour améliorer l&apos;expérience de l&apos;utilisateur.{" "}
        </CookieConsent>
        <Dialog
          open={cookies.ageVerified !== true}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="age_verification"
          scroll="paper"
        >
          <DialogContent>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 250,
                mb: 4,
                mt: 4
              }}
            >
              <Box
                component="img"
                sx={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
                alt="Age verification logo"
                src={age_check}
              />
            </Box>
            <DialogTitle variant="h2" sx={{ textAlign:"center" }}>{"Un petit contrôle d'âge ! 🎂"}</DialogTitle>
            <DialogContentText id="alert-dialog-slide-description" variant="h4">
              Pour découvrir les délices de Boutigou, nous devons d&apos;abord vérifier que vous avez au moins 18 printemps. On s&apos;assure juste que vous êtes assez grand pour nos grandes saveurs.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
            <Button
              component="a"
              href="https://google.fr"
              variant="outlined"
              rel="noopener noreferrer"
            >
              Retour
            </Button>
            <Button
              onClick={() => setCookie('ageVerified', true)}
              variant="contained"
            >
              Oui, j&apos;ai 18 ans ou plus.
            </Button>
          </DialogActions>
        </Dialog>
      </Main>
      <Login />
      <Register />
      <Reset />
    </Box>
  );
};

export default MainLayout;
