import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCgu } from "../../../../actions/cguActions";
import { signupAction } from "../../../../actions/authActions";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import gif_loading from 'assets/images/gif_loading.gif';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// ===========================|| FIREBASE - REGISTER ||=========================== //

function formatCGUText(text) {
  const sections = text.split(/\r\n\r\n/).filter(Boolean);

  return sections.map((section, index) => {
    const [title, ...content] = section.split(/\r\n/);

    return (
      <div key={index}>
        <Typography variant="h4" gutterBottom>{title}</Typography>
        {content.map((paragraph, pIndex) => (
          <Typography key={pIndex} variant="body1" paragraph>{'  '}{paragraph}</Typography>
        ))}
      </div>
    );
  });
}

const FirebaseRegister = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const cgu = useSelector((state) => state.cgu);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    console.log(loading)
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
    setPassword(value)
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(getCgu(dispatch))
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSignUpPressed = (e) => {
    e.preventDefault()
    setLoading(true)
    if (!checked) {
      alert("Vous devez accepter les conditions générales d'utilisation.");
      setLoading(false)
    } else {
      dispatch(signupAction({
        'username': username,
        'password': password,
        'passwordConfirm': passwordConfirm,
        'first_name': firstname,
        'last_name': lastname,
        'email': email,
        'accept_terms': checked
      }, dispatch));
      setLoading(false)
    }
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">S&apos;inscrire avec l&apos;adresse e-mail</Typography>
          </Box>
        </Grid>
      </Grid>
      <Dialog onClose={handleClose} open={open} sx={{ maxWidth: "500px" }}>
        <DialogTitle variant="h2">Conditions Générales</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {formatCGUText(cgu.cgu.content || '')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors,  isSubmitting, touched}) => (
          <form noValidate onSubmit={onSignUpPressed} {...others}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={auth.loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            <Grid container spacing={matchDownSM ? 0 : 2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Identifiant"
                  margin="normal"
                  name="fname"
                  type="text"
                  defaultValue=""
                  sx={{ ...theme.typography.customInput }}
                  onChange={(e) => setUsername(e.target.value)}
                  error={auth.errorsSignUp.username ? true : false}
                  helperText={auth.errorsSignUp.username ? auth.errorsSignUp.username.message : null}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Prénom"
                  margin="normal"
                  name="fname"
                  type="text"
                  defaultValue=""
                  sx={{ ...theme.typography.customInput }}
                  onChange={(e) => setFirstname(e.target.value)}
                  error={auth.errorsSignUp.first_name ? true : false}
                  helperText={auth.errorsSignUp.first_name ? auth.errorsSignUp.first_name.message : null}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nom"
                  margin="normal"
                  name="lname"
                  type="text"
                  defaultValue=""
                  sx={{ ...theme.typography.customInput }}
                  onChange={(e) => setLastname(e.target.value)}
                  error={auth.errorsSignUp.last_name ? true : false}
                  helperText={auth.errorsSignUp.last_name ? auth.errorsSignUp.last_name.message : null}
                />
              </Grid>
            </Grid>
            <FormControl fullWidth error={Boolean(touched.email || auth.errorsSignUp.email)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-email-register">Adresse e-mail</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={email}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                inputProps={{}}
              />
              {(auth.errorsSignUp.email) && (
                <FormHelperText error id="standard-weight-helper-text-email-register">
                  {auth.errorsSignUp.email ? auth.errorsSignUp.email.message : null }
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.password || auth.errorsSignUp.password)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-password-register">Mot de passe</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-register"
                type={showPassword ? 'text' : 'password'}
                value={password}
                name="password"
                label="Password"
                onChange={(e) => {
                  changePassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
              {(auth.errorsSignUp.password) && (
                <FormHelperText error id="standard-weight-helper-text-password-register">
                  {auth.errorsSignUp.password ? auth.errorsSignUp.password.message : null }
                </FormHelperText>
              )}
            </FormControl>

            {strength !== 0 && (
              <FormControl fullWidth>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box style={{ backgroundColor: level?.color }} sx={{ width: 85, height: 8, borderRadius: '7px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </FormControl>
            )}

            <FormControl fullWidth error={Boolean(touched.password || auth.errorsSignUp.passwordConfirm)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-password-register-confirm">Confirmez le mot de passe</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-register-confirm"
                type={showPasswordConfirm ? 'text' : 'password'}
                value={passwordConfirm}
                name="password"
                label="Password"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPasswordConfirm}
                      edge="end"
                      size="large"
                    >
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
              {(auth.errorsSignUp.passwordConfirm) && (
                <FormHelperText error id="standard-weight-helper-text-passwordConfirm-register">
                  {auth.errorsSignUp.passwordConfirm ? auth.errorsSignUp.passwordConfirm.message : null }
                </FormHelperText>
              )}
            </FormControl>

            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                  }
                  label={
                    <Typography variant="subtitle1">
                      J&apos;accepte les&nbsp;
                      <Typography variant="subtitle1" component={Link} to="#" onClick={() => setOpen(true)} color="secondary">
                        conditions générales.
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button disableElevation disabled={isSubmitting} type="submit" fullWidth size="large" variant="contained" color="secondary">
                  S&apos;inscrire
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseRegister;
