import * as types from "../types/types";

const initialState = {
    cart: [],
    cart_details: {},
    payment_method_options: [],
    delivery_location_options: [],
    delivery_day_options: [],
    delivery_slot_options: [],
    delivery_validate: {},
    loading: false,
    errors: {},
    errorsPayment: {},
    success_add_product: null,
    success_edit_product: null,
    success_remove_product: null,
    success_method_payment: null,
    orderPaymentSuccess: null,
    orderPaymentSuccessData: {},
    paymentIntentClientSecret: null,
    paymentIntentTransferGroup: null,
    stripePublishableKey: "",
    loading_step: false,
    loading_method_payment: false
};

function orderReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CART:
            return {
                ...state,
                cart: action.payload,
                loading: false,
                loading_step: false,
                loading_method_payment: false,
                success_add_product: null,
                success_edit_product: null,
                success_remove_product: null,
                success_method_payment: null,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                paymentIntentClientSecret: null,
                paymentIntentTransferGroup: null,
                delivery_day_options: [],
                delivery_slot_options: [],
                delivery_validate: {},
                errors: {},
                errorsPayment: {},
            };
        case types.FAILED_GET_CART:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.GET_CART_DETAILS:
            return {
                ...state,
                cart_details: action.payload
            };
        case types.LOADING_ORDER:
            return {
                ...state,
                loading: true,
                orderPaymentSuccess: null
            };
        case types.LOADING_ORDER_STEP:
            return {
                ...state,
                loading_step: true,
            };
        case types.ADD_PRODUCT_CART:
            return {
                ...state,
                errors: {},
                success_add_product: true
            };
        case types.FAILED_ADD_PRODUCT_CART:
            return {
                ...state,
                errors: action.payload,
                success_add_product: false
            };
        case types.REMOVE_PRODUCT_CART:
            return {
                ...state,
                success_remove_product: true
            };
        case types.FAILED_REMOVE_PRODUCT_CART:
            return {
                ...state,
                success_remove_product: false
            };
        case types.EDIT_PRODUCT_CART:
            return {
                ...state,
                success_edit_product: true
            };
        case types.FAILED_EDIT_PRODUCT_CART:
            return {
                ...state,
                success_edit_product: false,
                errors: action.payload
            };
        case types.CLOSE_ALERT_CART:
            return {
                ...state,
                success_add_product: null,
                success_edit_product: null,
                delivery_validate: {}
            };
        case types.LOADING_ORDER_PAYMENT_METHOD:
            return {
                ...state,
                loading_method_payment: true,
            };
        case types.ADD_PAYMENT_METHOD_OPTIONS:
            return {
                ...state,
                success_method_payment: true,
            };
        case types.FAILED_ADD_PAYMENT_METHOD_OPTIONS:
            return {
                ...state,
                success_method_payment: true,
                errors: action.payload
            };
        case types.GET_PAYMENT_METHOD_OPTIONS:
            return {
                ...state,
                payment_method_options: action.payload,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                errors: {},
            };
        case types.GET_DELIVERY_LOCATION_OPTIONS:
            return {
                ...state,
                delivery_location_options: action.payload,
                loading_step: false,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                errors: {},
            };
        case types.GET_DELIVERY_DAY_OPTIONS:
            return {
                ...state,
                delivery_day_options: action.payload.days,
                loading_step: false,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                errors: {},
            };
        case types.GET_DELIVERY_SLOT_OPTIONS:
            return {
                ...state,
                delivery_slot_options: action.payload.slots,
                loading_step: false,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                errors: {},
            };
        case types.GET_DELIVERY_VALIDATE:
            return {
                ...state,
                delivery_validate: action.payload[0],
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                paymentIntentClientSecret: null,
                paymentIntentTransferGroup: null,
                errors: {},
            };
        case types.FAILED_GET_DELIVERY_VALIDATE:
            return {
                ...state,
                delivery_validate: false,
                errors: action.payload,
                loading_step: false,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                paymentIntentTransferGroup: null,
                paymentIntentClientSecret: null
            };
        case types.GET_STRIPE_PUBLISHABLE_KEY:
            return {
                ...state,
                stripePublishableKey: action.payload.publishable_key
            };
        case types.ADD_ORDER:
            return {
                ...state,
                orderPaymentSuccessData: action.payload.instance,
                orderPaymentSuccess: true,
                loading_step: false,
            };
        case types.FAILED_ADD_ORDER:
            return {
                ...state,
                errorsPayment: action.payload,
                orderPaymentSuccess: false,
                orderPaymentSuccessData: {},
                loading_step: false,
            };
        case types.FAILED_CARD_ORDER:
            return {
                ...state,
                errorsPayment: action.payload,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                loading_step: false,
            };
        case types.ADD_ORDER_PAYMENT_INTENT:
            return {
                ...state,
                delivery_validate: {},
                paymentIntentClientSecret: action.payload.client_secret,
                paymentIntentTransferGroup: action.payload.transfert_group,
                loading_step: false,
            };
        case types.FAILED_ADD_ORDER_PAYMENT_INTENT:
            return {
                ...state,
                delivery_validate: {},
                paymentIntentClientSecret: null,
                paymentIntentTransferGroup: null,
                errorsPayment: action.payload,
                orderPaymentSuccess: false,
                orderPaymentSuccessData: {},
                loading_step: false,
            };
        case types.CLOSE_ORDER_PAYMENT_INTENT:
            return {
                ...state,
                paymentIntentClientSecret: null,
                paymentIntentTransferGroup: null,
                delivery_validate: {}
            };
        case types.SET_ORDER_PAYMENT_SUCCESS:
            return {
                ...state,
                orderPaymentSuccess: null,
                orderPaymentSuccessData: {},
                paymentIntentClientSecret: null,
                paymentIntentTransferGroup: null,
                cart: [],
                cart_details: {},
            };
        default:
            return state;
    }
}

export default orderReducer;