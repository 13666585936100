import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';

// dashboard routing
import Home from '../containers/homeContainer';
import Shop from '../containers/shopContainer';
import ShopReview from '../containers/shopReviewContainer';
import Product from '../containers/productContainer';
import Favorite from '../containers/favoriteContainer';
import Conversation from '../containers/conversationContainer';
import Reviews from '../containers/reviewsContainer';
import Review from '../containers/reviewContainer';
import OrderHistory from '../containers/orderHistoryContainer';
import OrderHistoryDetail from '../containers/orderHistoryDetailContainer';
import Cgu from '../containers/cguContainer';
import Cgv from '../views/cgv';
import Cart from '../containers/cartContainer';
import Profile from '../containers/profileContainer';
import AboutUs from '../views/aboutus';
import ContactUs from '../views/contactus';
import Privacy from '../views/privacy';
import Legal from '../views/legal';
import Press from '../views/press';

import Kesako from '../views/posts/home';

import HomeLabel from '../views/posts/labels/home';
import AB from '../views/posts/labels/ab';
import AOP from '../views/posts/labels/aop';
import AOC from '../views/posts/labels/aoc';
import IGP from '../views/posts/labels/igp';

import HomeSoutienProducteursLocaux from '../views/posts/soutien_producteur/home';
import DirectProducteur from '../views/posts/soutien_producteur/directproducteur';
import SupportLocalFarms from '../views/posts/soutien_producteur/localfarms';
import LocalProductArticle from '../views/posts/soutien_producteur/localproduct';

import HomeEcoLocaleDevDurable from '../views/posts/eco_locale_dev_durable/home';
import ClickAndCollect from '../views/posts/eco_locale_dev_durable/clickandcollect';
import LocalMarkets from '../views/posts/eco_locale_dev_durable/marcheslocaux';
import MonnaiesLocales from '../views/posts/eco_locale_dev_durable/monnaieslocales';

import HomeAlimGastroLocale from '../views/posts/alim_gastronomie_locale/home';
import LocalMarket from '../views/posts/alim_gastronomie_locale/marcheslocaux';
import PlatsLocaux from '../views/posts/alim_gastronomie_locale/platslocaux';
import ConfituresMaison from '../views/posts/alim_gastronomie_locale/confituresmaison';

import HomeCommunauteCulture from '../views/posts/communaute_culture/home';
import LocalCommunity from '../views/posts/communaute_culture/localcommunity';
import FestivalLocal from '../views/posts/communaute_culture/festivallocal';
import GAEC from '../views/posts/communaute_culture/gaec';
import Amap from '../views/posts/communaute_culture/amap';
import MarcheTraditionnel from '../views/posts/communaute_culture/marchetraditionnel'

import HomeEducationSensibilisation from '../views/posts/education_sensibilisation/home';
import DietaryChoices from '../views/posts/education_sensibilisation/dietarychoices';
import MedicinalPlants from '../views/posts/education_sensibilisation/medicinalplants';
import AgriDurable from '../views/posts/education_sensibilisation/agridurable';
import ConsommationReponsable from '../views/posts/education_sensibilisation/consommationresponsable';

import HomePortraitHistoireLocale from '../views/posts/portrait_histoire_locale/home';
import LocalWine from '../views/posts/portrait_histoire_locale/localwine';
import Muscadet from '../views/posts/portrait_histoire_locale/muscadet';
import CremantLoire from '../views/posts/portrait_histoire_locale/cremantloire';
import UnePommeparJour from '../views/posts/portrait_histoire_locale/unepommeparjour';

import HomePratiqueInitiativeDurable from '../views/posts/pratique_initiative_durable/home';
import UrbanGardening from '../views/posts/pratique_initiative_durable/urbangardening';
import Compost from '../views/posts/pratique_initiative_durable/compost';
import ContenantConsigne from  '../views/posts/pratique_initiative_durable/contenantconsigne';

import HomeMetier from '../views/posts/metier/home';
import Savonnerie from '../views/posts/metier/savonnerie';
import Viticulteur from '../views/posts/metier/viticulteur';
import Apiculteur from '../views/posts/metier/apiculteur';
import Biscuitier from '../views/posts/metier/biscuitier';
import Conserverie from '../views/posts/metier/conserverie';
import Createur from '../views/posts/metier/createur';
import Agriculteur from '../views/posts/metier/agriculteur';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Home />
    },
    {
      path: 'boutiques/:shop_id',
      element: <Shop />
    },
    {
      path: 'boutiques/:shop_id/produits/:product_id',
      element: <Product />
    },
    {
      path: 'boutiques/:shop_id/avis',
      element: <ShopReview />
    },
    {
      path: 'favoris',
      element: <Favorite />
    },
    {
      path: 'conversations',
      element: <Conversation />
    },
    {
      path: 'conversations/:conversation_id',
      element: <Conversation />
    },
    {
      path: 'avis',
      element: <Reviews />
    },
    {
      path: 'avis/:review_order_id',
      element: <Review />
    },
    {
      path: 'commandes',
      element: <OrderHistory />
    },
    {
      path: 'commandes/:order_id',
      element: <OrderHistoryDetail />
    },
    {
      path: 'cgu',
      element: <Cgu />
    },
    {
      path: 'cgv',
      element: <Cgv />
    },
    {
      path: 'panier',
      element: <Cart />
    },
    {
      path: 'profil',
      element: <Profile />
    },
    {
      path: 'a-propos-de-nous',
      element: <AboutUs />
    },
    {
      path: 'contact',
      element: <ContactUs />
    },
    {
      path: 'politique-de-confidentialite',
      element: <Privacy />
    },
    {
      path: 'mentions-legales',
      element: <Legal />
    },
    {
      path: 'presse',
      element: <Press />
    },
    {
      path: 'kesako',
      element: <Kesako />
    },
    {
      path: 'kesako/labels',
      element: <HomeLabel />
    },
    {
      path: 'kesako/labels/certification-ab',
      element: <AB />
    },
    {
      path: 'kesako/labels/aop-appellation-origine-protegee-guide',
      element: <AOP />
    },
    {
      path: 'kesako/labels/decouverte-produits-aoc-excellence-tradition',
      element: <AOC />
    },
    {
      path: 'kesako/labels/les-secrets-de-l-igp',
      element: <IGP />
    },

    {
      path: 'kesako/economie-locale-developpement-durable',
      element: <HomeEcoLocaleDevDurable />
    },
    {
      path: 'kesako/vracs/revolution-click-and-collect-consommation-locale',
      element: <ClickAndCollect />
    },
    {
      path: 'kesako/vracs/marches-producteurs-locaux-impact-economique-et-environnemental',
      element: <LocalMarkets />
    },
    {
      path: 'kesako/vracs/5-impacts-positifs-monnaies-locales',
      element: <MonnaiesLocales />
    },
    
    {
      path: 'kesako/soutien-aux-producteurs-locaux',
      element: <HomeSoutienProducteursLocaux />
    },
    {
      path: 'kesako/vracs/acheter-direct-producteurs-impact-avantages',
      element: <DirectProducteur />
    },
    {
      path: 'kesako/vracs/soutien-petites-exploitations-agricoles',
      element: <SupportLocalFarms />
    },
    {
      path: 'kesako/vracs/achat-direct-fruits-legumes-producteurs-locaux',
      element: <LocalProductArticle />
    },

    {
      path: 'kesako/alimentation-gastronomie-locale',
      element: <HomeAlimGastroLocale />
    },
    {
      path: 'kesako/vracs/marches-locaux',
      element: <LocalMarket />
    },
    {
      path: 'kesako/vracs/10-plats-locaux-a-decouvrir',
      element: <PlatsLocaux />
    },
    {
      path: 'kesako/vracs/confitures-maison-guide-complet',
      element: <ConfituresMaison />
    },
    
    {
      path: 'kesako/communaute-culture',
      element: <HomeCommunauteCulture />
    },
    {
      path: 'kesako/vracs/construire-communaute-achat-local',
      element: <LocalCommunity />
    },
    {
      path: 'kesako/vracs/festivals-locaux-traditions-culture-communaute',
      element: <FestivalLocal />
    },
    {
      path: 'kesako/vracs/gaec-guide-complet',
      element: <GAEC />
    },
    {
      path: 'kesako/vracs/amap-guide-complet',
      element: <Amap />
    },
    {
      path: 'kesako/vracs/5-raisons-visiter-marches-traditionnels',
      element: <MarcheTraditionnel />
    },

    {
      path: 'kesako/education-sensibilisation',
      element: <HomeEducationSensibilisation />
    },
    {
      path: 'kesako/vracs/regimes-alimentaires-produits-locaux',
      element: <DietaryChoices />
    },
    {
      path: 'kesako/vracs/plantes-medicinales-locales',
      element: <MedicinalPlants />
    },
    {
      path: 'kesako/vracs/education-sensibilisation-agriculture-durable',
      element: <AgriDurable />
    },
    {
      path: 'kesako/vracs/5-avantages-de-la-consommation-responsable',
      element: <ConsommationReponsable />
    },

    {
      path: 'kesako/portraits-histoires-locales',
      element: <HomePortraitHistoireLocale />
    },
    {
      path: 'kesako/vracs/vins-locaux',
      element: <LocalWine />
    },
    {
      path: 'kesako/vracs/vin-muscadet-tradition-innovation',
      element: <Muscadet />
    },
    {
      path: 'kesako/vracs/histoire-cremant-de-loire',
      element: <CremantLoire />
    },
    {
      path: 'kesako/vracs/une-pomme-par-jour-mythe-ou-realite',
      element: <UnePommeparJour />
    },

    {
      path: 'kesako/pratiques-initiatives-durables',
      element: <HomePratiqueInitiativeDurable />
    },
    {
      path: 'kesako/vracs/guide-portager-urbain',
      element: <UrbanGardening />
    },
    {
      path: 'kesako/vracs/3-raisons-de-composter-chez-soi',
      element: <Compost />
    },
    {
      path: 'kesako/vracs/5-avantages-des-contenants-consignes',
      element: <ContenantConsigne />
    },

    {
      path: 'kesako/les-metiers',
      element: <HomeMetier />
    },
    {
      path: 'kesako/vracs/art-de-la-savonnerie-methode-a-froid',
      element: <Savonnerie />
    },
    {
      path: 'kesako/vracs/metier-vigneron-tradition-innovation',
      element: <Viticulteur />
    },
    {
      path: 'kesako/vracs/metier-apiculteur-artisan-biodiversite',
      element: <Apiculteur />
    },
    {
      path: 'kesako/vracs/metier-biscuitier-artisanat-innovation',
      element: <Biscuitier />
    },
    {
      path: 'kesako/vracs/metier-producteur-conserves-saveurs',
      element: <Conserverie />
    },
    {
      path: 'kesako/vracs/metier-createur-artisanat-bijoux-bougies-tissus',
      element: <Createur />
    },
    {
      path: 'kesako/vracs/agriculteurs-locaux-vente-directe',
      element: <Agriculteur />
    },

    {
      path: '*',
      skipLazyLoad: true,
      element: <Navigate to="/" />,
    },
  ]
};

export default MainRoutes;
