// material-ui
import * as React from 'react';
import { Grid } from '@mui/material';
import Microlink from "@microlink/react";
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| CGU PAGE ||============================== //


export default function ContactUs() {

    return (
    <MainCard title="Presse">
      <Helmet>
        <title>Ils Parlent de Notre Engagement Local dans les Médias</title>
        <meta name="description" content="Découvrez ce que les médias disent de Boutigou et de notre engagement pour soutenir les producteurs locaux." />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Microlink size="large" url='https://www.capreussite.net/epreuve-mentorat/'/>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Microlink size="large" url='https://www.facebook.com/watch/?v=1384193008931978&ref=sharing.html'/>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Microlink size="large" url='https://actu.fr/pays-de-la-loire/maisdon-sur-sevre_44088/il-cree-une-market-place-pour-rapprocher-producteurs-et-consommateurs-du-vignoble-nantais_60725017.html'/>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Microlink size="large" url='https://www.ouest-france.fr/societe/alimentatio/la-petite-boutique-du-vignoble-nantais-qui-rapproche-producteurs-et-consommateurs-3aafba9c-d4bd-11ee-8773-9bf8bebf5829' />
        </Grid>
      </Grid>
    </MainCard>
  );
}