import { connect } from "react-redux";

import Shop from "../views/shop";

import {
    getShop,
    getShopProducts,
    getShopDeliveryOptions,
    getShopReviews,
    getConversation,
    getShopPartners,
    addMessage,
    getShopEvents
} from "../actions/shopActions.js";


const mapStateToProps = state => ({
    shopRed: state.shop
});

const mapDispatchToProps = dispatch => ({
    getShop: (dispatch, shop_id) =>
        dispatch(getShop(dispatch, shop_id)),
    getShopProducts: (dispatch, shop_id) =>
        dispatch(getShopProducts(dispatch, shop_id)),
    getShopPartners: (dispatch, shop_id) =>
        dispatch(getShopPartners(dispatch, shop_id)),
    getShopDeliveryOptions: (dispatch, shop_id) =>
        dispatch(getShopDeliveryOptions(dispatch, shop_id)),
    getShopReviews: (dispatch, shop_id) =>
        dispatch(getShopReviews(dispatch, shop_id)),
    getConversation: (dispatch, conv_id, shop_id) =>
        dispatch(getConversation(dispatch, conv_id, shop_id)),
    addMessage: (item, dispatch) =>
        dispatch(addMessage(item, dispatch)),
    getShopEvents: (dispatch, shop_id) =>
        dispatch(getShopEvents(dispatch, shop_id)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
