import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getCgu(dispatch) {
    dispatch({
        type: types.LOADING_GET_CGU
    });
    return async function () {
        axiosAPI
            .get(`${url}/cgu/`)
            .then((res) => { return dispatch({ type: types.GET_CGU, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CGU, payload: error.response.data }) })
    };
}

export {
    getCgu
};
