import { combineReducers } from 'redux';

// reducer import
import authReducer from './authReducer';
import customizationReducer from './customizationReducer';
import homeReducer from './homeReducer';
import shopReducer from './shopReducer';
import shopReviewReducer from './shopReviewReducer';
import reviewReducer from './reviewReducer';
import reviewsReducer from './reviewsReducer';
import productReducer from './productReducer';
import favoriteReducer from './favoriteReducer';
import conversationReducer from './conversationReducer';
import orderHistoryReducer from './orderHistoryReducer';
import orderHistoryDetailReducer from './orderHistoryDetailReducer';
import locationReducer from './locationReducer';
import cguReducer from './cguReducer';
import orderReducer from './orderReducer';
import notificationReducer from './notificationReducer';
import profileReducer from './profileReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  auth: authReducer,
  customization: customizationReducer,
  home: homeReducer,
  shop: shopReducer,
  shopReview: shopReviewReducer,
  review: reviewReducer,
  reviews: reviewsReducer,
  product: productReducer,
  favorite: favoriteReducer,
  conversation: conversationReducer,
  orderHistory: orderHistoryReducer,
  orderHistoryDetail: orderHistoryDetailReducer,
  location: locationReducer,
  cgu: cguReducer,
  order: orderReducer,
  notification: notificationReducer,
  profile: profileReducer
});

export default reducer;
