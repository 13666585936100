import { connect } from "react-redux";

import OrderHistoryDetail from "../views/orderHistoryDetail";

import {
    getOrderHistory,
    getOrderHistoryDetail
} from "../actions/orderHistoryActions.js";

const mapStateToProps = state => ({
    orderHistoryDetailRed: state.orderHistoryDetail
});

const mapDispatchToProps = dispatch => ({
    getOrderHistory: (order_id, dispatch) =>
        dispatch(getOrderHistory(order_id, dispatch)),
    getOrderHistoryDetail: (order_id, dispatch) =>
        dispatch(getOrderHistoryDetail(order_id, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryDetail);
