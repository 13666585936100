import * as types from "../types/types";

const initialState = {
    authenticated: false,
    loading: false,
    errors: {},
    requestAuth: false,
    requestRegister: false,
    requestReset: false,
    successGetToken: null,
    errorsGetToken: {},
    successPasswordReset: null,
    errorsPasswordReset: {},
    user: {},
    errorsSignUp: {},
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.WHO_AM_I:
            if (action.payload.id === null) {
                return {
                    ...state,
                    authenticated: false,
                    errors: {},
                    user: null
                };
            } else {
                return {
                    ...state,
                    authenticated: true,
                    user: action.payload
                }
            }
        case types.SIGN_IN:
            return {
                ...state,
                authenticated: true,
                requestAuth: false,
                loading: false,
                errors: {},
                errorsSignUp: {},
            };
        case types.FAILED_SIGN_IN:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_SIGN_IN:
            return {
                ...state,
                loading: true,
            };
        case types.SIGN_OUT:
            return {
                ...state,
                authenticated: false,
            };
        case types.REQUEST_AUTH:
            return {
                ...state,
                requestAuth: true,
                requestRegister: false,
            };
        case types.NO_REQUEST_AUTH:
            return {
                ...state,
                requestAuth: false,
            };
        case types.REQUEST_REGISTER:
            return {
                ...state,
                requestRegister: true,
                requestAuth: false,
            };
        case types.NO_REQUEST_REGISTER:
            return {
                ...state,
                requestRegister: false,
            };
        case types.REQUEST_RESET:
            return {
                ...state,
                requestReset: true,
                requestRegister: false,
                requestAuth: false,
            };
        case types.NO_REQUEST_RESET:
            return {
                ...state,
                requestReset: false,
            };
        case types.CLEAN_AUTH:
            return {
                ...state,
                authenticated: false,
                errors: {},
                successGetToken: null,
                errorsGetToken: {},
                successPasswordReset: null,
                errorsPasswordReset: {},
              };
        case types.AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                user: action.payload,
                not_allowed: false,
                requestAuth: false,
            };
        case types.UNAUTHENTICATED:
          return {
            ...initialState,
            authenticated: false,
            user: {},
        };
        case types.GET_TOKEN_RESET:
            return {
                ...state,
                successGetToken: true,
                errorsGetToken: {},
                errorsPasswordReset: {},
            };
        case types.FAILED_GET_TOKEN_RESET:
            return {
                ...state,
                errorsGetToken: action.payload,
                errorsPasswordReset: {},
                successGetToken: false,
            };
        case types.PASSWORD_RESET:
            return {
                ...state,
                successPasswordReset: true,
                errorsPasswordReset: {},
                requestReset: false,
                requestAuth: true,
            };
        case types.FAILED_PASSWORD_RESET:
            return {
                ...state,
                errorsPasswordReset: action.payload,
                successPasswordReset: false,
            };
        case types.LOADING_SIGN_UP:
            return {
                ...state,
                loading: true,
            };
        case types.SIGN_UP:
            localStorage.setItem("access_token", action.payload.access);
            localStorage.setItem("refresh_token", action.payload.refresh);
            return {
                ...state,
                authenticated: true,
                user: action.payload.user,
                errors: {},
                errorsSignUp: {},
                loading: false,
                requestRegister: false
            };
      case types.FAILED_SIGN_UP:
          return {
              ...state,
              errorsSignUp: action.payload,
              loading: false
          };
        default:
            return state;
    }
}

export default shopReducer;