// material-ui
import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {

  return (
    <img src={logo} alt="Boutigou" width="100" />
  );
};

export default Logo;
