import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getReviews(dispatch) {
    dispatch({
        type: types.LOADING_GET_REVIEWS
    });
    return async function () {
        axiosAPI
            .get(`${url}/review/`)
            .then((res) => { return dispatch({ type: types.GET_REVIEWS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_REVIEWS, payload: error.response.data }) })
    };
}

export {
    getReviews
};
