import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';

import reducer from '../reducers/reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);


const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

let persistor = persistStore(store);

export {
  store,
  persistor,
};


