import * as types from "../types/types";

const initialState = {
    reviews: [],
    loading: false,
    errors: {},
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SHOP_REVIEWS:
            return {
                ...state,
                reviews: action.payload,
                loading: false,
            };
        case types.FAILED_GET_SHOP_REVIEWS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_SHOP_REVIEWS:
            return {
                ...state,
                reviews: [],
                loading: true,
            };
        default:
            return state;
    }
}

export default shopReducer;