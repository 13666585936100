import { connect } from "react-redux";

import Reviews from "../views/reviews";

import {
    getReviews
} from "../actions/reviewsActions.js";


const mapStateToProps = state => ({
    reviewsRed: state.reviews,
});

const mapDispatchToProps = dispatch => ({
    getReviews: (dispatch) =>
        dispatch(getReviews(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
