import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getReview(sub_order_id, dispatch) {
  dispatch({
    type: types.LOADING_GET_REVIEW
  });
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/review/?sub_order__id=${sub_order_id}`);
      dispatch({ type: types.GET_REVIEW, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/reviews';
      } else {
        dispatch({ type: types.FAILED_GET_REVIEW, payload: error.response.data });
      }
    }
  };
}

function addReview(item, dispatch) {
    dispatch({ type: types.LOADING_GET_REVIEW });
    return async function () {
        axiosAPI
            .post(`${url}/review/`, item)
            .then((res) => { return dispatch({ type: types.ADD_REVIEW, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_REVIEW, payload: error.response.data }) })
    };
}

function getOrderReview(order_id, dispatch) {
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/sub_order_history/${order_id}/`);
      dispatch({ type: types.GET_ORDER_REVIEW, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/reviews';
      } else {
        dispatch({ type: types.FAILED_GET_ORDER_REVIEW, payload: error.response.data });
      }
    }
  };
}

export {
    getReview,
    addReview,
    getOrderReview
};
