// material-ui
import * as React from 'react';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function Privacy() {

    return (
    <MainCard title="Politique de Confidentialité de Boutigou">
      
      <Helmet>
        <title>Politique de Confidentialité de Boutigou : Protégez Vos Données Personnelles</title>
        <meta name="description" content="Consultez la politique de confidentialité de Boutigou pour comprendre comment nous collectons, utilisons, et protégeons vos informations personnelles. Notre engagement : transparence et sécurité pour tous nos utilisateurs." />
      </Helmet>

      <Typography variant="h4" gutterBottom pb={5}>Dernière mise à jour : 05/02/2024</Typography>

      <Typography variant="h4" gutterBottom>1. Introduction</Typography>

      <Typography variant="body1" paragraph>
        Bienvenue sur Boutigou ! Nous attachons une grande importance à la protection de vos informations personnelles et nous nous engageons à respecter votre vie privée. Cette politique de confidentialité explique comment nous recueillons, utilisons, divulguons et protégeons vos données personnelles lorsque vous utilisez notre marketplace.
      </Typography>
      
      <Typography variant="h4" gutterBottom>2. Informations que nous recueillons</Typography>

      <Typography variant="body1" paragraph>
        2.1. Informations fournies par l&apos;utilisateur : Lorsque vous créez un compte ou effectuez des transactions sur Boutigou, nous recueillons les informations suivantes : nom, prénom, identifiant, adresse e-mail, et, si activé sur l&apos;application mobile, la localisation.
      </Typography>

      <Typography variant="h4" gutterBottom>3. Utilisation des informations</Typography>

      <Typography variant="body1" paragraph>
        Nous utilisons vos informations personnelles pour le fonctionnement de l&apos;outil, notamment en fournissant aux producteurs les plus proches de vous. Nous cherchons à personnaliser votre expérience, améliorer nos services, et prévenir la fraude.
      </Typography>

      <Typography variant="h4" gutterBottom>4. Divulgation des informations</Typography>

      <Typography variant="body1" paragraph>
        Nous ne divulguons aucune information personnelle à des tiers.
      </Typography>

      <Typography variant="h4" gutterBottom>5. Sécurité des informations</Typography>

      <Typography variant="body1" paragraph>
        Vos données sont stockées dans nos datacenters, et nous avons mis en place des mesures de sécurité pour protéger vos informations contre tout accès non autorisé.
      </Typography>

      <Typography variant="h4" gutterBottom>6. Cookies et technologies similaires</Typography>

      <Typography variant="body1" paragraph>
        Nous n&apos;utilisons aucun cookie, sauf l&apos;utilisation du localstorage du navigateur pour améliorer l&apos;expérience utilisateur.
      </Typography>

      <Typography variant="h4" gutterBottom>7. Vos choix</Typography>

      <Typography variant="body1" paragraph>
        Vous pouvez modifier toutes vos informations depuis votre compte utilisateur.
      </Typography>

      <Typography variant="h4" gutterBottom>8. Modifications de la politique de confidentialité</Typography>

      <Typography variant="body1" paragraph>
        Les modifications dans la politique de confidentialité peuvent être apportées en raison de mises à jour légales ou de nouveaux développements. Nous vous informerons de ces changements via des avis sur la page d&apos;accueil de Boutigou ou par e-mail.
      </Typography>

    </MainCard>
  );
}