// material-ui
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Grid, Link } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| CGU PAGE ||============================== //


export default function ContactUs() {

    return (
    <MainCard title="Nous contacter">
      <Helmet>
        <title>Contactez Boutigou - Soutien aux Producteurs Locaux et Consommation Responsable</title>
        <meta name="description" content="Contactez l&apos;équipe de Boutigou pour toute question sur notre marketplace dédiée aux produits locaux. Rejoignez-nous pour promouvoir une consommation locale et responsable." />
      </Helmet>
      <Typography variant="h4" paragraph>
        Vous pouvez nous contacter de différentes manières :
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Adresse
          </Typography>
          <Typography variant="body1">
            11 Impasse Jutton, 44000 Nantes
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Téléphone
          </Typography>
          <Typography variant="body1">
            0780901060
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Email
          </Typography>
          <Typography variant="body1">
            <Link href="mailto:contact@boutigou.fr">contact@boutigou.fr</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Réseaux sociaux
          </Typography>
          <Typography variant="body1">
            Suivez-nous sur{' '}
            <Link href="https://www.facebook.com/boutigoufr" target="_blank" rel="noopener">
              Facebook
            </Link>{' '}
            et{' '}
            <Link href="https://www.instagram.com/boutigoufr/" target="_blank" rel="noopener">
              Instagram
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
}