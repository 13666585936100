import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getProduct(dispatch, product_id) {
  dispatch({
    type: types.LOADING_GET_PRODUCT
  });
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/shop_product/${product_id}/`);
      dispatch({ type: types.GET_PRODUCT, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = `/`;
      } else {
        dispatch({ type: types.FAILED_GET_PRODUCT, payload: error.response.data });
      }
    }
  };
}

function getProductSilently(dispatch, product_id) {
  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/shop_product/${product_id}/`);
      dispatch({ type: types.GET_PRODUCT, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = `/`;
      } else {
        dispatch({ type: types.FAILED_GET_PRODUCT, payload: error.response.data });
      }
    }
  };
}

function addProductFavorite(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/product_favorite/`, item)
            .then((res) => { return dispatch({ type: types.ADD_PRODUCT_FAVORITE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_PRODUCT_FAVORITE, payload: error.response.data }) })
    };
}

function deleteProductFavorite(item, dispatch) {
    return async function () {
        axiosAPI
            .delete(`${url}/product_favorite/${item.id}/`)
            .then((res) => { return dispatch({ type: types.DELETE_PRODUCT_FAVORITE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_DELETE_PRODUCT_FAVORITE, payload: error.response.data }) })
    };
}

function addProductToCart(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/cart_product/`, item)
            .then((res) => { return dispatch({ type: types.ADD_PRODUCT_CART, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_PRODUCT_CART, payload: error.response.data }) })
    };
}

function closeAlertCart(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_CART
    });
}

export {
    getProduct,
    getProductSilently,
    addProductFavorite,
    deleteProductFavorite,
    addProductToCart,
    closeAlertCart
};
