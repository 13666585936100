import React from 'react';
import { Box, Grid, Link, Typography, Container, IconButton } from '@mui/material';
import { GooglePlayButton, AppStoreButton, ButtonsContainer } from "react-mobile-app-button";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LogoSection from '../LogoSection';
// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: 'https://www.facebook.com/boutigoufr',
  instagram: 'https://www.instagram.com/boutigoufr/',
  youtube: 'https://www.youtube.com/channel/UCpU_WxsFjX5_OFWTk21LTPw',
  linkedin: 'https://www.linkedin.com/company/boutigou/'
};

const Footer = () => {
  return (
    <Box
      sx={{
        color: 'text.secondary',
        py: 3,
        mt: 1,
        borderTop: '1px solid',
        borderColor: 'divider',
        width: '100%'
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <LogoSection />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              En savoir plus
            </Typography>
            <Link href="/a-propos-de-nous" color="inherit" display="block">
              A propos de nous
            </Link>
            <Link href="/contact" color="inherit" display="block">
              Nous contacter
            </Link>
            <Link href="/presse" color="inherit" display="block">
              Presse
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Mentions légales
            </Typography>
            <Link href="/cgu" color="inherit" display="block">
              Conditions générales d&apos;utilisation
            </Link>
            <Link href="/cgv" color="inherit" display="block">
              Conditions générales de vente
            </Link>
            <Link href="/politique-de-confidentialite" color="inherit" display="block">
              Politique de confidentialité
            </Link>
            <Link href="/mentions-legales" color="inherit" display="block">
              Mentions légales
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Espace producteur
            </Typography>
            <Link href="https://pro.boutigou.fr" target="_blank" color="inherit" display="block">
              Portail
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Réseaux Sociaux
            </Typography>
            <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook} target="_blank">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram} target="_blank">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="YouTube" color="inherit" component="a" href={socialMediaLinks.youtube} target="_blank">
              <YouTubeIcon />
            </IconButton>
            <IconButton aria-label="LinkedIn" color="inherit" component="a" href={socialMediaLinks.linkedin} target="_blank">
              <LinkedInIcon />
            </IconButton>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Notre application mobile
            </Typography>
            <ButtonsContainer gap={20} direction="column">
              <AppStoreButton
                url={'https://apps.apple.com/fr/app/boutigou/id6449875666'}
                theme={"dark"}
                title="Télécharger sur l'"
              />
              <GooglePlayButton
                url={'https://play.google.com/store/apps/details?id=com.boutigou.consumer&gl=FR&pli=1'}
                theme={"dark"}
                title="Disponible sur"
              />
            </ButtonsContainer>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 4 }}>
        {'Copyright © '}
            <Link color="inherit" href="https://www.boutigou.fr/"  target="_blank" rel="noopener">
                Boutigou
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
