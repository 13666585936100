// material-ui
import * as React from 'react';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function Cgv() {

    return (
    <MainCard title="Conditions Générales de Vente">
    <Helmet>
      <title>Conditions Générales de Vente de Boutigou - Transparence et Conformité</title>
      <meta name="description" content="Informations légales et réglementaires concernant l&apos;utilisation de la plateforme Boutigou." />
    </Helmet>
    
    <Typography variant="h4" gutterBottom>1. Identification du vendeur</Typography>
    <Typography variant="body1" paragraph>Les produits vendus sur Boutigou sont proposés par les producteurs inscrits sur notre plateforme. Chaque producteur est responsable de ses produits et doit fournir ses informations légales, notamment son nom, son adresse, et son numéro SIRET.</Typography>

    <Typography variant="h4" gutterBottom>2. Produits</Typography>
    <Typography variant="body1" paragraph>Les produits proposés sur Boutigou sont décrits et présentés avec la plus grande exactitude possible. En cas de divergence ou d&apos;erreur dans la description des produits, Boutigou ne saurait être tenu responsable. Les producteurs sont seuls responsables des informations fournies.</Typography>

    <Typography variant="h4" gutterBottom>3. Prix</Typography>
    <Typography variant="body1" paragraph>Les prix des produits sont indiqués en euros, toutes taxes comprises (TTC). Boutigou se réserve le droit de modifier les prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de la validation de la commande.</Typography>

    <Typography variant="h4" gutterBottom>4. Commandes</Typography>
    <Typography variant="body1" paragraph>Les commandes sont passées en ligne sur la plateforme Boutigou. La validation de la commande implique l&apos;acceptation des présentes CGV. Un email de confirmation vous sera envoyé après validation de votre commande.</Typography>

    <Typography variant="h4" gutterBottom>5. Paiement et retrait</Typography>
    <Typography variant="body1" paragraph>Les paiements pour les produits s&apos;effectuent en ligne via les méthodes de paiement acceptées par Boutigou (carte bancaire, PayPal, etc.). Toutes les transactions sont sécurisées.</Typography>
    <Typography variant="body1" paragraph>Les détails concernant les frais et les modalités de retrait des produits sont fournis par les producteurs et vous sont communiqués avant la finalisation de la commande. Vous êtes responsable de vous rendre au point de retrait indiqué par le producteur pour récupérer votre commande dans les délais convenus.</Typography>

    <Typography variant="h4" gutterBottom>6. Droit de rétractation</Typography>
    <Typography variant="body1" paragraph>Conformément à la législation en vigueur, vous disposez d&apos;un droit de rétractation de 14 jours à compter de la réception des produits. Pour exercer ce droit, vous devez notifier votre décision de rétractation au producteur concerné et retourner les produits dans leur état d&apos;origine. Les frais de retour sont à la charge du consommateur.</Typography>

    <Typography variant="h4" gutterBottom>7. Garanties</Typography>
    <Typography variant="body1" paragraph>Les produits bénéficient de la garantie légale de conformité et de la garantie contre les vices cachés. En cas de non-conformité ou de défaut, vous devez contacter le producteur pour convenir des modalités de retour et de remplacement ou de remboursement.</Typography>

    <Typography variant="h4" gutterBottom>8. Responsabilité</Typography>
    <Typography variant="body1" paragraph>Boutigou agit en tant qu&apos;intermédiaire et n&apos;est pas responsable des produits vendus par les producteurs. En cas de litige concernant un produit, vous devez contacter directement le producteur.</Typography>

    <Typography variant="h4" gutterBottom>9. Protection des données personnelles</Typography>
    <Typography variant="body1" paragraph>Vos données personnelles sont collectées et traitées conformément à notre politique de confidentialité. Vous avez le droit d&apos;accéder, de rectifier, et de supprimer vos données personnelles, ainsi que le droit de vous opposer à leur traitement.</Typography>

    <Typography variant="h4" gutterBottom>10. Litiges</Typography>
    <Typography variant="body1" paragraph>En cas de litige, nous vous encourageons à contacter directement le producteur pour trouver une solution amiable. Si aucune solution amiable n&apos;est trouvée, les tribunaux français seront compétents pour régler le litige.</Typography>
</MainCard>
  );
}