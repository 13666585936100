import * as types from "../types/types";

const initialState = {
    conversations: [],
    conversation: {},
    messages: [],
    errors: {},
    successMessage: null,
    success: false,
    loading: false
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload,
                loading: false,
            };
        case types.FAILED_GET_CONVERSATIONS:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_CONVERSATIONS:
            return {
                ...state,
                loading: true,
            };
        case types.GET_CONVERSATION:
            return {
                ...state,
                conversation: action.payload,
                loading: false,
            };
        case types.FAILED_GET_CONVERSATION:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.GET_MESSAGES:
            return {
                ...state,
                messages: action.payload.reverse(),
                successMessage: null,
                loading: false,
            };
        case types.FAILED_GET_MESSAGES:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.ADD_MESSAGE:
            return {
                ...state,
                successMessage: true,
                errors: {},
            };
        case types.FAILED_ADD_MESSAGE:
            return {
                ...state,
                successMessage: false,
                errors: action.payload,
            };
        case types.LOADING_MESSAGES:
            return {
                ...state,
                loading: true,
                messages: []
            };
        default:
            return state;
    }
}

export default shopReducer;