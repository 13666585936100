import { connect } from "react-redux";

import Cgu from "../views/cgu";

import {
    getCgu
} from "../actions/cguActions.js";


const mapStateToProps = state => ({
    cguRed: state.cgu
});

const mapDispatchToProps = dispatch => ({
    getCgu: (dispatch) =>
        dispatch(getCgu(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Cgu);
