import * as types from "../types/types";

const initialState = {
    location: {},
    errors: {},
};

function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_LOCATION:
            return {
                ...state,
                location: action.payload,
                loading: false,
            };
        case types.FAILED_GET_LOCATION:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.DELETE_LOCATION:
            return {
                ...state,
                location: {},
                loading: false,
            };
        default:
            return state;
    }
}

export default shopReducer;