import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ShopProduct = ({ loading, name, picture, price_unit, shop, quantity }) => {
  const navigate = useNavigate();

  const navigateProduct = (shop_id, product_id) => {
    navigate(`/boutiques/${shop_id}/produits/${product_id}`);
  };


  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateProduct(`${shop_id}`, `${id}`)}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    position: "relative",
                    width: "90%",
                    margin: "auto",
                    paddingTop: "90%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: "auto",
                      maxWidth: '200px',
                      maxHeight: '200px',
                      backgroundImage: `url(${picture.replace('http://django', '')})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "12px",
                    }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} sx={{ mb: 1.25 }}>
                      <Typography
                        sx={{
                          mt: 1.75,
                          mb: 0.75,
                        }}
                        variant="h2"
                      >
                        {name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ mb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h3"
                    >
                      Prix: {parseFloat(price_unit*quantity).toFixed(2)/100}€ ({quantity}*{parseFloat(price_unit).toFixed(2)/100})
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h4"
                      sx={{ color: '#697586' }}
                    >
                      Prix unitaire: {price_unit}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ color: '#697586' }}
                    >
                      Quantité: {quantity}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ color: '#697586' }}
                    >
                      Boutique: {shop}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ShopProduct.propTypes = {
  isLoading: PropTypes.bool
};

export default ShopProduct;
