import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authRequest, registerRequest } from "../../actions/authActions";
import { Helmet } from 'react-helmet';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Chip, Avatar, Typography, Breadcrumbs, Link, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

// project imports
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import Product from './Product';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function OrderHistory(props) {
    const theme = useTheme();
    const isInitialMount = React.useRef(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);
    const { order_id } = useParams();

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    const handleNavigateReview = (order_id) => {
      navigate(`/avis/${order_id}`);
    }
    

    React.useEffect(() => {
        if (isInitialMount.current && auth.authenticated) {
            isInitialMount.current = false;
            props.getOrderHistory(order_id, props.dispatch)
            props.getOrderHistoryDetail(order_id, props.dispatch);
        }
    }, []);

    return ( auth.authenticated ?
      <Grid container spacing={gridSpacing}>
        <Helmet>
          <title>Votre Historique de Commandes sur Boutigou - Suivi et Gestion</title>
          <meta name="description" content="Accédez et gérez facilement l&apos;historique de vos commandes sur Boutigou pour un suivi optimal de vos achats locaux." />
        </Helmet>
        <Box sx={{ p: 2.25 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/commandes" variant="h4">
              Mes Commandes
            </Link>
            <Typography color="text.primary" variant="h4">Détails</Typography>
          </Breadcrumbs>
        </Box>
        <Grid item xs={12}>
          <MainCard title={`Commande ${props.orderHistoryDetailRed.order.status ? props.orderHistoryDetailRed.order.status.description : ''}`} border={true}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={6}>
                <MainCard title="Général" border={true}>
                  <Grid container direction="column" alignItems="center">
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Date d&apos;achat
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {moment(props.orderHistoryDetailRed.order.created_at).format('Do MMM YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Date de réception
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {moment(props.orderHistoryDetailRed.order.delivered_at).format('Do MMM YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Créneau de réception
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {moment(props.orderHistoryDetailRed.order.delivery_slot_begin, 'HH:mm:ss').format('HH[h]mm')} - {moment(props.orderHistoryDetailRed.order.delivery_slot_end, 'HH:mm:ss').format('HH[h]mm')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Option de réception
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {props.orderHistoryDetailRed.order.delivery_option ? props.orderHistoryDetailRed.order.delivery_option.name : null}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Adresse de réception
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {`${props.orderHistoryDetailRed.order.delivery_option ? props.orderHistoryDetailRed.order.delivery_option.address : null}, ${props.orderHistoryDetailRed.order.delivery_option ? props.orderHistoryDetailRed.order.delivery_option.zipcode : null} ${props.orderHistoryDetailRed.order.delivery_option ? props.orderHistoryDetailRed.order.delivery_option.city : null}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    { ["pending", "validated", "completed"].includes(props.orderHistoryDetailRed.order.status ? props.orderHistoryDetailRed.order.status.name : 'none') ?
                      <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Code de réception
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {props.orderHistoryDetailRed.order.validation_code}
                        </Typography>
                      </Grid>
                    </Grid>
                    : null }
                  </Grid>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MainCard title={`Paiement ${props.orderHistoryDetailRed.order.payment_methods ? (props.orderHistoryDetailRed.order.payment_methods.type === false ? 'réalisé en ligne' : 'à faire sur place'): ''} via ${props.orderHistoryDetailRed.order.payment_methods ? props.orderHistoryDetailRed.order.payment_methods.name : ''}`} border={true}>
                  <Grid container direction="column" alignItems="center">
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Total produits
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {props.orderHistoryDetailRed.order.amount/100} €
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Commission Boutigou
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {(props.orderHistoryDetailRed.order.amount_total/100 - props.orderHistoryDetailRed.order.amount/100).toFixed(2)} €
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          Total global
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>
                          {props.orderHistoryDetailRed.order.amount_total/100} €
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  { props.orderHistoryDetailRed.order.payment_methods ? (props.orderHistoryDetailRed.order.payment_methods.type === false ?
                  (["completed"].includes(props.orderHistoryDetailRed.order.status ? props.orderHistoryDetailRed.order.status.name : 'none') ?
                  <Grid container alignItems="center">
                    <Button
                      sx={{ mr: 1, mt: 1.75, mb: 0.75 }}
                      variant="contained"
                      target="_blank"
                      href={props.orderHistoryDetailRed.order_details.receipt_url}
                    >
                      Télécharger mon reçu
                    </Button>
                  </Grid> : null ) : null) : null}
                </MainCard>
              </Grid>

              <Grid item xs={12}>
                { ["completed"].includes(props.orderHistoryDetailRed.order.status ? props.orderHistoryDetailRed.order.status.name : 'none') ?
                <MainCard title="Avis"  border={true}>
                  <Grid container alignItems="center">
                    {(props.orderHistoryDetailRed.order_details.shops || []).filter((shop, index, self) =>
                      index === self.findIndex(s =>
                        s.review.sub_order ? s.review.sub_order.id : null === shop.review.sub_order ? shop.review.sub_order.id : null
                      )
                    ).map((s, idx) => (
                      <Grid item xs={12} key={idx}>
                        <Button mode="contained" style={{ margin: 3 }} onClick={() => handleNavigateReview(s.review.sub_order ? s.review.sub_order.id : null)}>
                          Laisser un avis à {s.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </MainCard> : null}
              </Grid>

              <Grid item xs={12}>
                <MainCard title="Produits"  border={true}>
                  <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
                    { props.orderHistoryDetailRed.order_details.products ?
                      props.orderHistoryDetailRed.order_details.products.map((p, idx) => (
                        <Grid key={idx} item xs={12} sm={12} md={6}>
                          <Product
                            loading={props.orderHistoryDetailRed.loading}
                            name={p.product__name}
                            picture={`/media/${p.product__picture}`}
                            price_unit={p.price_unit}
                            shop={p.product__shop__name}
                            quantity={p.quantity}
                          />
                        </Grid>
                      )) : null}
                    </Grid>
                </MainCard>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid> :
    <MainCard>
      <Helmet>
        <title>Votre Historique de Commandes sur Boutigou - Suivi et Gestion</title>
        <meta name="description" content="Accédez et gérez facilement l&apos;historique de vos commandes sur Boutigou pour un suivi optimal de vos achats locaux." />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}