import * as types from "../types/types";

const initialState = {
    orders: [],
    loading: false,
    errors: {},
};

function orderHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ORDER_HISTORIES:
            return {
                ...state,
                orders: action.payload,
                loading: false,
            };
        case types.FAILED_GET_ORDER_HISTORIES:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_HISTORIES:
            return {
                ...state,
                orders: [],
                loading: true,
            };
        default:
            return state;
    }
}

export default orderHistoryReducer;