// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeEcoLocaleDevDurable() {

    return (
      <>
        <Helmet>
          <title>Soutien à l&apos;Économie Locale et Pratiques Durables | Boutigou</title>
          <meta name="description" content="Découvrez comment soutenir l&apos;économie locale et contribuer au développement durable avec Boutigou. Des pratiques telles que le click and collect, l&apos;achat direct aux producteurs, et plus." />
        </Helmet>
        <MainCard title="L&apos;Économie Locale et le Développement Durable, kesako ?" sx={{ mb: 4 }}>

          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    5 impacts positifs des monnaies locales
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/5-impacts-positifs-monnaies-locales">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Click and Collect
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/revolution-click-and-collect-consommation-locale">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Les Marchés de Producteurs Locaux : Un Pilier de l&apos;Économie Durable et du Développement Communautaire
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/marches-producteurs-locaux-impact-economique-et-environnemental">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </MainCard>
      </>
  );
}