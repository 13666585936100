import { connect } from "react-redux";

import Product from "../views/product";

import {
    getProduct,
    getProductSilently,
    addProductFavorite,
    deleteProductFavorite,
    addProductToCart,
    closeAlertCart
} from "../actions/productActions.js";


const mapStateToProps = state => ({
    productRed: state.product
});

const mapDispatchToProps = dispatch => ({
    getProduct: (dispatch, product_id) =>
        dispatch(getProduct(dispatch, product_id)),
    getProductSilently: (dispatch, product_id) =>
        dispatch(getProductSilently(dispatch, product_id)),
    addProductFavorite: (item, dispatch) =>
        dispatch(addProductFavorite(item, dispatch)),
    deleteProductFavorite: (item, dispatch) =>
        dispatch(deleteProductFavorite(item, dispatch)),
    addProductToCart: (item, dispatch) =>
        dispatch(addProductToCart(item, dispatch)),
    closeAlertCart: (dispatch) =>
        dispatch(closeAlertCart(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
