import * as types from "../types/types";

const initialState = {
    product: {},
    loading: false,
    is_favorite: false,
    is_favorite_id: null,
    errors: {},
    success: false,
    success_add_product: null,
};

function productReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PRODUCT:
            return {
                ...state,
                product: action.payload,
                loading: false,
                is_favorite: action.payload.is_favorite,
                is_favorite_id: action.payload.is_favorite_id,
                success: false,
                success_add_product: null,
            };
        case types.FAILED_GET_PRODUCT:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_GET_PRODUCT:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_PRODUCT_FAVORITE:
            return {
                ...state,
                success: true
            };
        case types.DELETE_PRODUCT_FAVORITE:
            return {
                ...state,
                success: true
            };
        case types.ADD_PRODUCT_CART:
            return {
                ...state,
                errors: {},
                success_add_product: true
            };
        case types.FAILED_ADD_PRODUCT_CART:
            return {
                ...state,
                errors: action.payload,
                success_add_product: false
            };
        case types.CLOSE_ALERT_CART:
            return {
                ...state,
                success_add_product: null,
            };
        default:
            return state;
    }
}

export default productReducer;