import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getShopReviews(dispatch, shop_id) {
  dispatch({
    type: types.LOADING_GET_SHOP_REVIEWS
  });

  return async function () {
    try {
      const res = await axiosAPI.get(`${url}/shop_review/?shop__id=${shop_id}`);
      dispatch({ type: types.GET_SHOP_REVIEWS, payload: res.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = '/';
      } else {
        dispatch({ type: types.FAILED_GET_SHOP_REVIEWS, payload: error.response.data });
      }
    }
  };
}
export {
    getShopReviews
};
