import * as React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Rating } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ReviewCard = ({ loading, content, created_at, rate, response, answered_at, review_order_id, shop_name}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();

  const navigateReview = (review_order_id) => {
    navigate(`/avis/${review_order_id}`);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const zoomScale = isHovered ? 1.01 : 1; 

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateReview(`${review_order_id}`)}>
          <Box
            sx={{
              p: 2.25,
              cursor: 'pointer',
              transform: `scale(${zoomScale})`,
              transition: 'transform 0.3s ease-in-out',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item alignItems="center">
                <Typography variant="h3" sx={{ mt: 1.75, mb: 0.75 }}>Vous avez laissé un avis à {shop_name} le {moment(created_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
              </Grid>
              <Grid item  alignItems="center">
                <Typography
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  < Rating name="half-rating-read" value={rate} precision={1} readOnly /> ({rate}/5)
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mr: 1, mt: 0.50, mb: 0.75, color: theme.palette.secondary[200] }}>{content}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              { response ?
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h3" sx={{ mr: 1, mt: 1.75, mb: 0.75 }}>Réponse le {moment(answered_at).format('Do MMMM YYYY à H[h]mm')}</Typography>
                  </Grid>
                </Grid>
              </Grid> : null }
              { response ?
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mr: 1, mb: 0.75, color: theme.palette.secondary[200] }}>{response}</Typography>
                  </Grid>
                </Grid>
              </Grid>              
              : null}
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ReviewCard.propTypes = {
  isLoading: PropTypes.bool
};

export default ReviewCard;
