import * as types from "../types/types";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";

function getProfile(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/profile/`)
            .then((res) => { return dispatch({ type: types.GET_PROFILE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_PROFILE, payload: error.response.data }) })
    };
}

function editProfile(item,  dispatch) {
    dispatch({ type: types.LOADING_PROFILE });
    return async function () {
        axiosAPI
            .patch(`${url}/profile/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.EDIT_PROFILE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_EDIT_PROFILE, payload: error.response.data }) })
    };
}

function deleteProfile(item,  dispatch) {
    dispatch({ type: types.LOADING_PROFILE });
    return async function () {
        axiosAPI
            .post(`${url}/profile/delete/`, item)
            .then((res) => { return dispatch({ type: types.DELETE_PROFILE, payload: res.data }) })
            .catch((error) => { console.log(error.response); return dispatch({ type: types.FAILED_DELETE_PROFILE, payload: error.response.data }) })
    };
}

function cleanDeleteProfile(dispatch) {
    return dispatch({ type: types.LOADING_PROFILE });
}

export {
    getProfile,
    editProfile,
    deleteProfile,
    cleanDeleteProfile
};
