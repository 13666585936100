// material-ui
import * as React from 'react';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function SupportLocalFarms() {

    return (
        <MainCard title="L'importance du soutien aux petites exploitations agricoles locales">

            <Helmet>
                <title>Soutenez les petites exploitations agricoles locales sur Boutigou</title>
                <meta name="description" content="Découvrez pourquoi et comment soutenir les petites exploitations agricoles locales est crucial pour la santé de notre communauté et de notre environnement. Boutigou vous guide à travers les impacts positifs de ce soutien." />
            </Helmet>

            <Typography variant="h4" component="h1" gutterBottom>
                Pourquoi soutenir les petites exploitations?
            </Typography>
            <Typography variant="body1" paragraph>
                Les petites exploitations agricoles sont souvent à la pointe de pratiques agricoles innovantes et durables, contribuant significativement à la diversification de l&apos;alimentation locale et à la résilience des systèmes alimentaires. Soutenir ces exploitations aide à maintenir la diversité des cultures, protège la biodiversité, et soutient l&apos;économie locale en créant des emplois et en maintenant l&apos;argent dans la communauté.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Défis rencontrés par les petites exploitations
            </Typography>
            <Typography variant="body1" paragraph>
                Malgré leur importance, les petites exploitations font face à de nombreux défis, notamment la compétition avec les grandes exploitations industrielles, l&apos;accès limité aux marchés, et les difficultés financières liées à l&apos;investissement initial élevé en agriculture durable. La pression des importations et les fluctuations du marché peuvent également menacer leur viabilité économique.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Comment pouvez-vous aider?
            </Typography>
            <Typography variant="body1" paragraph>
                Les consommateurs peuvent jouer un rôle clé dans le soutien aux petites exploitations en choisissant d&apos;acheter directement leurs produits, en participant à des systèmes de soutien tels que les abonnements aux paniers de saison (CSA), et en plaidant pour des politiques qui favorisent les petites exploitations. Participer à des événements locaux et éduquer les autres sur l&apos;importance de soutenir l&apos;agriculture locale sont également des moyens efficaces de contribuer.
            </Typography>

            <Typography variant="body1" paragraph>
                Encourager les restaurants et les entreprises locales à utiliser des produits provenant de petites exploitations est une autre manière puissante de soutenir ces agriculteurs. Cela crée une demande stable qui peut aider à assurer leur survie et leur prospérité à long terme.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Bénéfices de soutenir les petites exploitations
            </Typography>
            <Typography variant="body1" paragraph>
                Le soutien aux petites exploitations agricoles locales ne bénéficie pas seulement aux agriculteurs mais à toute la communauté. Il assure une alimentation plus fraîche et plus saine, réduit l&apos;empreinte écologique des aliments, et renforce les économies locales. De plus, il favorise une connexion plus étroite entre les consommateurs et la source de leur nourriture, encourageant une compréhension plus profonde et un respect de l&apos;environnement.
            </Typography>

            <Typography variant="h4" component="h1" gutterBottom>
                Rejoignez la cause
            </Typography>
            <Typography variant="body1" paragraph>
                Envisagez de visiter une petite exploitation agricole locale, de vous engager dans un abonnement CSA, ou de participer à des campagnes de sensibilisation. Chaque petit geste compte et peut faire une grande différence.
            </Typography>

    </MainCard>
  );
}