import * as types from "../types/types";

const initialState = {
    order: {},
    order_details: {},
    loading: false,
    errors: {},
};

function orderHistoryDetailReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ORDER_HISTORY:
            return {
                ...state,
                order: action.payload,
                loading: false,
            };
        case types.FAILED_GET_ORDER_HISTORY:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.GET_ORDER_HISTORY_DETAIL:
            return {
                ...state,
                order_details: action.payload,
                loading: false,
            };
        case types.FAILED_GET_ORDER_HISTORY_DETAIL:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case types.LOADING_HISTORY:
            return {
                ...state,
                order_details: {},
                order: {},
                loading: true,
            };
        default:
            return state;
    }
}

export default orderHistoryDetailReducer;