import PropTypes from 'prop-types';
// material-ui
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const Summary = ({ loading, fees, total, total_products, total_variable }) => {

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column" alignItems="center">
              <Grid item sx={{ mb: 2 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h2">
                      Total produits: {parseFloat(total_products).toFixed(2)/100}€
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h2">
                      Commission Boutigou: {parseFloat(fees).toFixed(2)/100}€
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h2">
                      Total avec commission Boutigou: {parseFloat(total).toFixed(2)/100}€
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              { total_variable === true ?
              <Grid item sx={{ mb: 2 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h5" color="#bd181d">
                      Votre panier contient au moins un produit à prix variable. Le prix total est donc à titre informatif et est susceptible de légèrement varier.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> : null}
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

Summary.propTypes = {
  isLoading: PropTypes.bool
};

export default Summary;