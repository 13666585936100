// material-ui
import * as React from 'react';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| CGU PAGE ||============================== //

function formatCGUText(text) {
  const sections = text.split(/\r\n\r\n/).filter(Boolean);

  return sections.map((section, index) => {
    const [title, ...content] = section.split(/\r\n/);

    return (
      <div key={index}>
        <Typography variant="h4" gutterBottom>{title}</Typography>
        {content.map((paragraph, pIndex) => (
          <Typography key={pIndex} variant="body1" paragraph>{'  '}{paragraph}</Typography>
        ))}
      </div>
    );
  });
}

export default function Cgu(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getCgu(props.dispatch);
        }
    }, []);

    return (
    <MainCard title="Conditions Générales d&apos;Utilisation">
      <Helmet>
        <title>CGU de Boutigou - Marketplace de Produits Locaux</title>
        <meta name="description" content="Consultez les conditions générales d'utilisation de Boutigou pour une expérience d'achat en toute confiance." />
      </Helmet>
      <Typography variant="body2">
        {formatCGUText(props.cguRed.cgu.content || '')}
      </Typography>
    </MainCard>
  );
}