import * as React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, ButtonGroup, Button, IconButton, DialogTitle, Dialog, DialogActions, DialogContent } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

const CardWrapper = styled(MainCard)(() => ({
  color: '#fff',
  overflow: 'hidden',
  position: 'relative'
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const Product = ({ props, loading, name, picture, price_unit, quantity, id, shop_id, shop_name, stock, cart_id }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [qty, setQty] = React.useState(quantity);
  const [isHovered, setIsHovered] = React.useState(false);

  const borderStyle = {
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    marginRight: '8px',
    marginLeft: '8px',
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const zoomScale = isHovered ? 1.01 : 1; 

  const handleIncrement = (e) => {
    e.stopPropagation();
    if (qty < stock) {
      setQty(qty + 1);
    }
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const navigateProduct = (shop_id, product_id) => {
    navigate(`/boutiques/${shop_id}/produits/${product_id}`);
  };

  const handleCloseEdit = (e) => {
    e.stopPropagation();
    setOpenEdit(false)
  }

  const handleCloseDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(false)
  }

  const handleEditProductFromCart = (e) => {
    e.stopPropagation()
    const item_data = {
      'id': cart_id,
      'product': id,
      'quantity': qty,
      'price_unit': price_unit
    }
    props.editProductFromCart(item_data, props.dispatch);
    setOpenEdit(false)
  }

  const handleRemoveProduct = (e) => {
    e.stopPropagation();
    const item_data = {
      'id': cart_id,
    }
    props.removeProductFromCart(item_data, props.dispatch);
    setOpenDelete(false)
  }

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false} onClick={() => navigateProduct(`${shop_id}`, `${id}`)}>
          <Dialog onClose={handleCloseDelete} open={openDelete}>
            <DialogTitle variant="h2">Voulez-vous vraiment supprimer le produit {name} de votre panier?</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDelete}>Annuler</Button>
              <Button variant="contained" onClick={handleRemoveProduct} autoFocus>
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog onClose={handleCloseEdit} open={openEdit}>
            <DialogTitle variant="h2">Modifiez la quantité de {name} de votre panier:</DialogTitle>
            <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton onClick={handleDecrement} color="primary" aria-label="minus" style={borderStyle}>
                <RemoveIcon />
              </IconButton>
              <Typography variant="h2">{qty}</Typography>
               <IconButton onClick={handleIncrement} color="primary" aria-label="plus" style={borderStyle}>
                <AddIcon />
              </IconButton>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEdit}>Annuler</Button>
              <Button variant="contained" onClick={handleEditProductFromCart} autoFocus>
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              p: 2.25,
              cursor: 'pointer',
              transform: `scale(${zoomScale})`,
              transition: 'transform 0.3s ease-in-out',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    margin: "auto",
                    paddingTop: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: "auto",
                      maxWidth: '200px',
                      maxHeight: '200px',
                      backgroundImage: `url(${picture.replace('http://django', '')})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container sx={{ pb: 1.25 }} justifyContent="flex-end" alignItems="flex-end">
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                  >
                    <IconButton aria-label="delete" color="primary" onClick={(e) => {e.stopPropagation(); setOpenEdit(true)}}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="primary" onClick={(e) => {e.stopPropagation(); setOpenDelete(true)}}>
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </Grid>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sx={{ pb: 1.25 }}>
                      <Typography
                        sx={{
                          pt: 1.75,
                          pb: 0.75,
                        }}
                        variant="h2"
                      >
                        {name}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  <Grid item sx={{ pb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h3"
                    >
                      Prix: {parseFloat(price_unit*quantity).toFixed(2)/100}€ ({quantity}*{parseFloat(price_unit).toFixed(2)/100})
                    </Typography>
                  </Grid>
                  <Grid item sx={{ pb: 1.25, alignSelf: 'flex-start' }}>
                    <Typography
                      variant="h4"
                      sx={{ color: '#697586' }}
                    >
                      Quantité: {quantity}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ color: '#697586' }}
                    >
                      Boutique: {shop_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

Product.propTypes = {
  isLoading: PropTypes.bool
};

export default Product;
