import * as types from "../types/types";
import axios from "axios";
import axiosAPI from "../utils/axiosApi";
let url = "/consumer/api/v1";


function authLogout(dispatch) {
    return dispatch({ type: types.SIGN_OUT })
}

function authRequest(dispatch) {
    return dispatch({ type: types.REQUEST_AUTH })
}

function authNoRequest(dispatch) {
    return dispatch({ type: types.NO_REQUEST_AUTH })
}

function registerRequest(dispatch) {
    return dispatch({ type: types.REQUEST_REGISTER })
}

function registerNoRequest(dispatch) {
    return dispatch({ type: types.NO_REQUEST_REGISTER })
}

function resetRequest(dispatch) {
    return dispatch({ type: types.REQUEST_RESET })
}

function resetNoRequest(dispatch) {
    return dispatch({ type: types.NO_REQUEST_RESET })
}

function whoAmI(token, dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/me/`)
            .then((res) => { return dispatch({ type: types.WHO_AM_I, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.UNAUTHENTICATED, payload: error }) })
    };
}

function authenticateAction(userData, dispatch) {
    return async function () {
        let response = await fetch(`${url}/me/`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + userData.access
            }
        });
        let responseJson = await response.json();
        try {
            localStorage.setItem("access_token", userData.access);
            localStorage.setItem("refresh_token", userData.refresh);
            dispatch({ type: types.AUTHENTICATED, payload: responseJson })
        } catch (error) {
            return dispatch({ type: types.NOT_ALLOWED })
        }
    };
}

function loginAction(data, dispatch) {
    dispatch({
        type: types.CLEAN_AUTH
    });
    var data_strip = { username: data.username.trim(), password: data.password.trim() };
    return async function () {
        let response = await fetch(`${url}/token/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data_strip)
        });
        let responseJson = await response.json();
        return responseJson
    };
}

function logoutAction(dispatch) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch({ type: types.UNAUTHENTICATED });
    return dispatch({ type: 'default' });
}

function getTokenReset(data, dispatch) {
    dispatch({type: types.CLEAN_AUTH })
    return async function () {
        axios
            .post(`${url}/passwordreset/`, data)
            .then((res) => { return dispatch({ type: types.GET_TOKEN_RESET, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_TOKEN_RESET, payload: error.response.data }) })
    };
}

function passwordReset(data, dispatch) {
    return async function () {
        axios
            .post(`${url}/passwordreset/confirm/`, data)
            .then((res) => { return dispatch({ type: types.PASSWORD_RESET, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_PASSWORD_RESET, payload: error.response.data }) })
    };
}

function signupAction(data, dispatch) {
    dispatch({
        type: types.LOADING_SIGN_UP
    });
    return function () {
        axios
            .post(`${url}/register_web/`, data)
            .then((res) => { return dispatch({ type: types.SIGN_UP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_SIGN_UP, payload: error.response.data }) })
    };
}

export {
    authLogout,
    authRequest,
    authNoRequest,
    registerRequest,
    registerNoRequest,
    authenticateAction,
    whoAmI,
    loginAction,
    logoutAction,
    resetRequest,
    resetNoRequest,
    getTokenReset,
    passwordReset,
    signupAction
};
