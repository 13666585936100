// material-ui
import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function AB() {

    return (
    <MainCard title="Découvrez les Avantages des Produits Bio certifiés AB">

      <Helmet>
        <title>Découvrez les Avantages des Produits Bio certifiés AB sur Boutigou</title>
        <meta name="description" content="Explorez notre sélection de produits certifiés Agriculture Biologique (AB) sur Boutigou. Découvrez comment choisir des produits bio contribue à une alimentation saine et soutient les pratiques agricoles durables." />
      </Helmet>

      <Typography variant="body1" paragraph>
        L&apos;agriculture biologique (AB) est plus qu&apos;une certification ; c&apos;est un engagement envers une production alimentaire responsable et durable. Sur Boutigou, nous sommes fiers de promouvoir une gamme étendue de produits certifiés AB, offrant ainsi à nos consommateurs des options saines tout en soutenant les agriculteurs qui respectent notre environnement.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Qu&apos;est-ce que le label Agriculture Biologique (AB) ?
      </Typography>

      <Typography variant="body1" paragraph>
        Le label AB garantit que les produits sont cultivés sans utilisation de produits chimiques de synthèse, OGM ou engrais chimiques, et avec un souci particulier pour le bien-être animal. Ce label est le symbole d&apos;une qualité et d&apos;une traçabilité irréprochables, assurant aux consommateurs une confiance totale dans les produits qu&apos;ils achètent.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Pourquoi choisir des produits certifiés AB ?
      </Typography>

      <Typography variant="body1" paragraph>
        Choisir des produits certifiés AB, c&apos;est opter pour des aliments plus sains et plus nutritifs, réduire son empreinte écologique et participer à une économie qui valorise le travail des agriculteurs engagés pour une planète plus verte. Sur Boutigou, chaque produit AB est une fenêtre ouverte sur des pratiques agricoles éthiques et durables.
      </Typography>

      <Typography variant="h4" gutterBottom component="h2">
        Les avantages des produits AB pour la santé et l&apos;environnement
      </Typography>

      <Typography variant="body1" paragraph>
        Les produits AB réduisent l&apos;exposition aux pesticides et aux substances chimiques, soutiennent la biodiversité et contribuent à la préservation des ressources naturelles. Consommer bio, c&apos;est aussi favoriser une alimentation riche en nutriments, essentielle pour une bonne santé.
      </Typography>

      <Typography variant="body1" paragraph>
        En choisissant des produits certifiés AB sur Boutigou, vous contribuez à un mouvement global pour une agriculture respectueuse de l&apos;environnement et du bien-être animal, garantissant ainsi l&apos;avenir de notre planète pour les générations futures.
      </Typography>

      <Typography variant="body1" paragraph>
        Explorez notre sélection de produits AB sur Boutigou et découvrez la différence que peut faire un choix conscient dans votre quotidien et pour le monde.
      </Typography>


      <Typography variant="h4" gutterBottom component="h2">
        Envie de plus de contenu ?
      </Typography>

      <Typography variant="body1" paragraph>
        Si vous avez apprécié cet article sur le label IGP nous vous recommandons d&apos;aller voir un de nos autres articles suivants : <Link size="small" href="/kesako/labels/decouverte-produits-aoc-excellence-tradition">Appellation d&apos;Origine Controlée</Link>, <Link size="small" href="/kesako/labels/aop-appellation-origine-protegee-guide">Appellation d&apos;Origine Protégée</Link>, <Link size="small" href="/kesako/labels/les-secrets-de-l-igp">Indication Géographique Protégée</Link>.
      </Typography>

    </MainCard>
  );
}