import { connect } from "react-redux";

import Favorite from "../views/favorite";

import {
    getFavorite
} from "../actions/favoriteActions.js";


const mapStateToProps = state => ({
    favoriteRed: state.favorite
});

const mapDispatchToProps = dispatch => ({
    getFavorite: (dispatch) =>
        dispatch(getFavorite(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
