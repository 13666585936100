// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// LOCATION
export const GET_LOCATION = 'GET_LOCATION';
export const FAILED_GET_LOCATION = 'FAILED_GET_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const FAILED_DELETE_LOCATION = 'FAILED_DELETE_LOCATION';

// AUTH
export const WHO_AM_I = 'WHO_AM_I';
export const SIGN_IN = 'SIGN_IN';
export const FAILED_SIGN_IN = 'FAILED_SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const FAILED_SIGN_UP = 'FAILED_SIGN_UP';
export const LOADING_SIGN_IN = 'LOADING_SIGN_IN';
export const LOADING_SIGN_UP = 'LOADING_SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';

export const AUTHENTICATED = 'AUTHENTICATED';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const CLEAN_AUTH = 'CLEAN_AUTH';
export const NOT_ALLOWED = 'NOT_ALLOWED';

export const REQUEST_AUTH = 'REQUEST_AUTH';
export const NO_REQUEST_AUTH = 'NO_REQUEST_AUTH';
export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const NO_REQUEST_REGISTER = 'NO_REQUEST_REGISTER';
export const REQUEST_RESET = 'REQUEST_RESET';
export const NO_REQUEST_RESET = 'NO_REQUEST_RESET';

export const GET_TOKEN_RESET = 'GET_TOKEN_RESET';
export const FAILED_GET_TOKEN_RESET = 'FAILED_GET_TOKEN_RESET';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const FAILED_PASSWORD_RESET = 'FAILED_PASSWORD_RESET';

// SHOP
export const GET_SHOPS = 'GET_SHOPS';
export const FAILED_GET_SHOPS = 'FAILED_GET_SHOPS';
export const LOADING_GET_SHOPS = 'LOADING_GET_SHOPS';

export const GET_SHOP = 'GET_SHOP';
export const FAILED_GET_SHOP = 'FAILED_GET_SHOP';
export const LOADING_GET_SHOP = 'LOADING_GET_SHOP';

export const GET_SHOP_PRODUCTS = 'GET_SHOP_PRODUCTS';
export const FAILED_GET_SHOP_PRODUCTS = 'FAILED_GET_SHOP_PRODUCTS';
export const LOADING_GET_SHOP_PRODUCTS = 'LOADING_GET_SHOP_PRODUCTS';

export const GET_SHOP_DELIVERY_OPTIONS = 'GET_SHOP_DELIVERY_OPTIONS';
export const FAILED_GET_SHOP_DELIVERY_OPTIONS = 'FAILED_GET_SHOP_DELIVERY_OPTIONS';
export const LOADING_GET_SHOP_DELIVERY_OPTIONS = 'LOADING_GET_SHOP_DELIVERY_OPTIONS';

export const GET_SHOP_EVENT_OPTIONS = 'GET_SHOP_EVENT_OPTIONS';
export const FAILED_GET_SHOP_EVENT_OPTIONS = 'FAILED_GET_SHOP_EVENT_OPTIONS';
export const LOADING_GET_SHOP_EVENT_OPTIONS = 'LOADING_GET_SHOP_EVENT_OPTIONS';

export const GET_SHOP_PARTNERS = 'GET_SHOP_PARTNERS';
export const FAILED_GET_SHOP_PARTNERS = 'FAILED_GET_SHOP_PARTNERS';
export const LOADING_GET_SHOP_PARTNERS = 'LOADING_GET_SHOP_PARTNERS';

// SHOP REVIEW
export const GET_SHOP_REVIEWS = 'GET_SHOP_REVIEWS';
export const FAILED_GET_SHOP_REVIEWS = 'FAILED_GET_SHOP_REVIEWS';
export const LOADING_GET_SHOP_REVIEWS = 'LOADING_GET_SHOP_REVIEWS';

// PRODUCT
export const GET_PRODUCT = 'GET_PRODUCT';
export const FAILED_GET_PRODUCT = 'FAILED_GET_PRODUCT';
export const LOADING_GET_PRODUCT = 'LOADING_GET_PRODUCT';

export const ADD_PRODUCT_FAVORITE = 'ADD_PRODUCT_FAVORITE';
export const FAILED_ADD_PRODUCT_FAVORITE = 'FAILED_ADD_PRODUCT_FAVORITE';
export const DELETE_PRODUCT_FAVORITE = 'DELETE_PRODUCT_FAVORITE';
export const FAILED_DELETE_PRODUCT_FAVORITE = 'FAILED_DELETE_PRODUCT_FAVORITE';

// FAVORITE
export const GET_FAVORITES = 'GET_FAVORITES';
export const FAILED_GET_FAVORITES = 'FAILED_GET_FAVORITES';
export const LOADING_GET_FAVORITES = 'LOADING_GET_FAVORITES';

// CONVERSATION
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const FAILED_GET_CONVERSATIONS = 'FAILED_GET_CONVERSATIONS';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const FAILED_GET_CONVERSATION = 'FAILED_GET_CONVERSATION';
export const LOADING_GET_CONVERSATIONS = 'LOADING_GET_CONVERSATIONS';

// MESSAGE
export const GET_MESSAGES = 'GET_MESSAGES';
export const FAILED_GET_MESSAGES = 'FAILED_GET_MESSAGES';
export const LOADING_MESSAGES = 'LOADING_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const FAILED_ADD_MESSAGE = 'FAILED_ADD_MESSAGE';

// REVIEW
export const GET_REVIEWS = 'GET_REVIEWS';
export const FAILED_GET_REVIEWS = 'FAILED_GET_REVIEWS';
export const LOADING_GET_REVIEWS = 'LOADING_GET_REVIEWS';

export const GET_REVIEW = 'GET_REVIEW';
export const FAILED_GET_REVIEW = 'FAILED_GET_REVIEW';
export const LOADING_GET_REVIEW = 'LOADING_GET_REVIEW';

export const ADD_REVIEW = 'ADD_REVIEW';
export const FAILED_ADD_REVIEW = 'FAILED_ADD_REVIEW';
export const GET_ORDER_REVIEW = 'GET_ORDER_REVIEW';
export const FAILED_GET_ORDER_REVIEW = 'FAILED_GET_ORDER_REVIEW';

// ORDER HISTORY
export const LOADING_HISTORIES = 'LOADING_HISTORIES';
export const GET_ORDER_HISTORIES = 'GET_ORDER_HISTORIES';
export const FAILED_GET_ORDER_HISTORIES = 'FAILED_GET_ORDER_HISTORIES';

export const LOADING_HISTORY = 'LOADING_HISTORY';
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const FAILED_GET_ORDER_HISTORY = 'FAILED_GET_ORDER_HISTORY';

export const GET_ORDER_HISTORY_DETAIL = 'GET_ORDER_HISTORY_DETAIL';
export const FAILED_GET_ORDER_HISTORY_DETAIL = 'FAILED_GET_ORDER_HISTORY_DETAIL';

// CGU
export const GET_CGU = 'GET_CGU';
export const FAILED_GET_CGU = 'FAILED_GET_CGU';
export const LOADING_GET_CGU = 'LOADING_GET_CGU';


export const LOADING_ORDER = 'LOADING_ORDER';
export const LOADING_ORDER_STEP = 'LOADING_ORDER_STEP';
export const GET_CART = 'GET_CART';
export const FAILED_GET_CART = 'FAILED_GET_CART';
export const GET_CART_DETAILS = 'GET_CART_DETAILS';
export const FAILED_GET_CART_DETAILS = 'FAILED_GET_CART_DETAILS';
export const ADD_PRODUCT_CART = 'ADD_PRODUCT_CART';
export const FAILED_ADD_PRODUCT_CART = 'FAILED_ADD_PRODUCT_CART';
export const REMOVE_PRODUCT_CART = 'REMOVE_PRODUCT_CART';
export const FAILED_REMOVE_PRODUCT_CART = 'FAILED_REMOVE_PRODUCT_CART';
export const EDIT_PRODUCT_CART = 'EDIT_PRODUCT_CART';
export const FAILED_EDIT_PRODUCT_CART = 'FAILED_EDIT_PRODUCT_CART';
export const CLOSE_ALERT_CART = 'CLOSE_ALERT_CART';

export const LOADING_ORDER_PAYMENT_METHOD = 'LOADING_ORDER_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_OPTIONS = 'GET_PAYMENT_METHOD_OPTIONS';
export const FAILED_GET_PAYMENT_METHOD_OPTIONS = 'FAILED_GET_PAYMENT_METHOD_OPTIONS';

export const ADD_PAYMENT_METHOD_OPTIONS = 'ADD_PAYMENT_METHOD_OPTIONS';
export const FAILED_ADD_PAYMENT_METHOD_OPTIONS = 'FAILED_ADD_PAYMENT_METHOD_OPTIONS';

export const GET_DELIVERY_LOCATION_OPTIONS = 'GET_DELIVERY_LOCATION_OPTIONS';
export const FAILED_GET_DELIVERY_LOCATION_OPTIONS = 'FAILED_GET_DELIVERY_LOCATION_OPTIONS';
export const GET_DELIVERY_DAY_OPTIONS = 'GET_DELIVERY_DAY_OPTIONS';
export const FAILED_GET_DELIVERY_DAY_OPTIONS = 'FAILED_GET_DELIVERY_DAY_OPTIONS';
export const GET_DELIVERY_SLOT_OPTIONS = 'GET_DELIVERY_SLOT_OPTIONS';
export const FAILED_GET_DELIVERY_SLOT_OPTIONS = 'FAILED_GET_DELIVERY_SLOT_OPTIONS';
export const GET_DELIVERY_VALIDATE = 'GET_DELIVERY_VALIDATE';
export const FAILED_GET_DELIVERY_VALIDATE = 'FAILED_GET_DELIVERY_VALIDATE';


export const GET_STRIPE_PUBLISHABLE_KEY = 'GET_STRIPE_PUBLISHABLE_KEY';
export const FAILED_GET_STRIPE_PUBLISHABLE_KEY = 'FAILED_GET_STRIPE_PUBLISHABLE_KEY';
export const ADD_ORDER = 'ADD_ORDER';
export const FAILED_ADD_ORDER = 'FAILED_ADD_ORDER';
export const FAILED_CARD_ORDER = 'FAILED_CARD_ORDER';
export const ADD_ORDER_PAYMENT_INTENT = 'ADD_ORDER_PAYMENT_INTENT';
export const FAILED_ADD_ORDER_PAYMENT_INTENT = 'FAILED_ADD_ORDER_PAYMENT_INTENT';
export const CLOSE_ORDER_PAYMENT_INTENT = 'CLOSE_ORDER_PAYMENT_INTENT';
export const SET_ORDER_PAYMENT_SUCCESS = 'SET_ORDER_PAYMENT_SUCCESS';

// NOTIFICATIONS
export const GET_NOTIFICATION_MESSAGES = 'GET_NOTIFICATION_MESSAGES';
export const FAILED_GET_NOTIFICATION_MESSAGES = 'FAILED_GET_NOTIFICATION_MESSAGES';
export const CLOSE_NOTIFICATION_MESSAGES = 'CLOSE_NOTIFICATION_MESSAGES';
export const GET_NOTIFICATION_ORDERS = 'GET_NOTIFICATION_ORDERS';
export const FAILED_GET_NOTIFICATION_ORDERS = 'FAILED_GET_NOTIFICATION_ORDERS';
export const CLOSE_NOTIFICATION_ORDERS = 'CLOSE_NOTIFICATION_ORDERS';
export const GET_NOTIFICATION_REVIEWS = 'GET_NOTIFICATION_REVIEWS';
export const FAILED_GET_NOTIFICATION_REVIEWS = 'FAILED_GET_NOTIFICATION_REVIEWS';
export const CLOSE_NOTIFICATION_REVIEWS = 'CLOSE_NOTIFICATION_REVIEWS';

export const GET_NOTIFICATION_CART = 'GET_NOTIFICATION_CART';
export const FAILED_GET_NOTIFICATION_CART = 'FAILED_GET_NOTIFICATION_CART';
export const CLOSE_NOTIFICATION_CART = 'CLOSE_NOTIFICATION_CART';


// PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const FAILED_GET_PROFILE = 'FAILED_GET_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const FAILED_EDIT_PROFILE = 'FAILED_EDIT_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FAILED_DELETE_PROFILE = 'FAILED_DELETE_PROFILE';
export const LOADING_PROFILE = 'LOADING_PROFILE';