import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { authRequest, registerRequest } from "../../actions/authActions";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid,
    Box,
    Chip,
    Avatar,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    List,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
    Backdrop } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// project imports
import gif_loading from 'assets/images/gif_loading.gif';
import nodata from 'assets/images/nodata.png';
import MainCard from 'ui-component/cards/MainCard';
import Product from './Product';
import Summary from './Summary';
import PaymentMethod from './PaymentMethod';
import { gridSpacing } from 'store/constant';

// STRIPE
import CheckoutForm from './Checkout'
import {
  Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function Cart(props) {
  
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [openDeliveryOptions, setOpenDeliveryOptions] = React.useState(false);
    const [deliveryOption, setDeliveryOption] = React.useState({});
    const [openDeliveryDayOptions, setOpenDeliveryDayOptions] = React.useState(false);
    const [deliveryDayOption, setDeliveryDayOption] = React.useState({});
    const [openDeliverySlotOptions, setOpenDeliverySlotOptions] = React.useState(false);
    const [deliverySlotOption, setDeliverySlotOption] = React.useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [openPay, setOpenPay] = React.useState(false);
    const [openTmpSuccess, setOpenTmpSuccess] = React.useState((searchParams.get('redirect_status') === 'succeeded') && (props.orderRed.paymentIntentClientSecret !== null));

    const steps = [
      {
        label:`${deliveryOption.name ? `Choix du lieu: ${deliveryOption.name} (${deliveryOption.address}, ${deliveryOption.zipcode} ${deliveryOption.city})` : `Choisir le lieu de réception.`}`,
        description: `${deliveryOption.name ? deliveryOption.name : `Vous n'avez pas encore choisi.`}`,
      },
      {
        label: `${deliveryDayOption.date ? `Choix de la date: ${moment(deliveryDayOption.date).format('dddd, Do MMMM YYYY')}` : `Choisir la date de réception.`}`,
        description: `${deliveryDayOption.date ? moment(deliveryDayOption.date).format('dddd, Do MMMM YYYY') : `Vous n'avez pas encore choisi.`}`,
      },
      {
        label: `${deliverySlotOption.begin ? `Choix du créneau: ${deliverySlotOption.begin} - ${deliverySlotOption.end}` : `Choisir le créneau de réception`}`,
        description: `${deliverySlotOption.begin ? `${deliverySlotOption.begin} - ${deliverySlotOption.end}` : `Vous n'avez pas encore choisi.`}`,
      },
    ];

    const handleNext = () => {
      if (activeStep === 0) {
        props.getOrderDeliveryLocationOptions(props.dispatch);
        setOpenDeliveryOptions(true)
      } else if (activeStep === 1) {
        setOpenDeliveryDayOptions(true)
      } else {
        setOpenDeliverySlotOptions(true);
      }     
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const handleAuthRequest = () => {
      dispatch(authRequest(dispatch))
    };

    const handleRegisterRequest = () => {
      dispatch(registerRequest(dispatch))
    };

    React.useEffect(() => {
      if (isInitialMount.current && auth.authenticated) {
        isInitialMount.current = false;
        setOpenPay(false)
        props.getCart(props.dispatch);
        props.getCartDetails(props.dispatch);
        props.getPaymentMethodOptions(props.dispatch)
      }
    }, []);

    React.useEffect(() => {
      if (auth.authenticated) {
        isInitialMount.current = false;
        setOpenPay(false)
        props.getCart(props.dispatch);
        props.getCartDetails(props.dispatch);
        props.getPaymentMethodOptions(props.dispatch)
      }
    }, [auth.authenticated]);

    React.useEffect(() => {
        if (props.orderRed.success_edit_product === true) {
            props.getCart(props.dispatch);
            props.getCartDetails(props.dispatch);
            props.getPaymentMethodOptions(props.dispatch)
        } else if (props.orderRed.success_edit_product === false) {
            setVisible(true);
        }
        props.closeAlertCart(props.dispatch)
    }, [props.orderRed.success_edit_product]);

    React.useEffect(() => {
        if (props.orderRed.success_method_payment === true) {
            props.getCart(props.dispatch);
            props.getCartDetails(props.dispatch);
            props.getPaymentMethodOptions(props.dispatch);
            setActiveStep(0)
            setDeliveryOption({});
            setDeliveryDayOption({});
            setDeliverySlotOption({});
        }
    }, [props.orderRed.success_method_payment]);

    React.useEffect(() => {
        if (props.orderRed.success_remove_product === true) {
            props.getCart(props.dispatch);
            props.getCartDetails(props.dispatch);
            props.getPaymentMethodOptions(props.dispatch)
        }
    }, [props.orderRed.success_remove_product]);


    React.useEffect(() => {
        if (props.orderRed.delivery_validate === false) {
          setOpenValidateError(true)
          props.closeAlertCart(props.dispatch)
        } else if (props.orderRed.delivery_validate.id) {
          props.addOrderPaymentIntent({}, props.dispatch)
        }
    }, [props.orderRed.delivery_validate]);

    const handleGetDeliveryDayOptions = (o) => {
      props.getOrderDeliveryDayOptions(o.id, props.dispatch);
      setOpenDeliveryOptions(false);
      setDeliveryOption(o);
      setActiveStep(1)
    }

    const handleGetDeliverySlotOptions = (o) => {
      props.getOrderDeliverySlotOptions(deliveryOption.id, o.day - 1, o.date, props.dispatch);
      setOpenDeliveryDayOptions(false);
      setDeliveryDayOption(o);
      setActiveStep(2);
    }

    const handleValidate = (o) => {
      //props.getOrderDeliverySlotOptions(deliveryOption.id, o.day - 1, o.date, props.dispatch);
      setOpenDeliverySlotOptions(false);
      setDeliverySlotOption(o);
      setActiveStep(3)
    }

    const options = {
      clientSecret: props.orderRed.paymentIntentClientSecret
    };

    const handlePay = () => {
      props.getOrderPaymentIntent(props.dispatch);
      props.getOrderDeliveryValidate(deliverySlotOption.begin, deliverySlotOption.end, props.dispatch);
    }

    const stripePromise = loadStripe(props.orderRed.stripePublishableKey);

    React.useEffect(() => {
      if ((props.orderRed.paymentIntentClientSecret !== null) && (searchParams.get('redirect_status') !== 'succeeded')) {
        if (props.orderRed.cart_details.payment_method_type === false) {
          setOpenPay(true)
        } else {
          setOpenTmpSuccess(true)
          const item = {
            transfert_group: props.orderRed.paymentIntentTransferGroup,
          }
          props.addOrder(item, props.dispatch)
        }
      } else {
        setOpenPay(false)
      }
    }, [props.orderRed.paymentIntentClientSecret]);

    React.useEffect(() => {
      if ((searchParams.get('redirect_status') === 'succeeded') && (props.orderRed.paymentIntentClientSecret !== null)) {
        setOpenPay(false)
        setOpenTmpSuccess(true)
        const item = {
          transfert_group: props.orderRed.paymentIntentTransferGroup,
        }
        props.addOrder(item, props.dispatch)
      }
    }, [searchParams.get('payment_intent')]);

    React.useEffect(() => {
        if (props.orderRed.orderPaymentSuccess === true) {
            setOpenPay(false)
            if (searchParams.get('redirect_status') === 'succeeded' || !searchParams.has('redirect_status')) {
              const orderData = JSON.parse(props.orderRed.orderPaymentSuccessData);
              props.closeOrderPaymentSuccess(props.dispatch);
              navigate(`/commandes/${orderData.id}`);
            }
        } else if (props.orderRed.orderPaymentSuccess === false) {
            alert(props.orderRed.errorsPayment.null.message)
        }
    }, [props.orderRed.orderPaymentSuccess]);

    return ( auth.authenticated ?
      ( props.orderRed.cart.length !== 0 ?
      <Grid container spacing={gridSpacing}>
        <Helmet>
          <title>Votre Panier sur Boutigou - Prêt à Commander</title>
          <meta name="description" content="Consultez votre panier sur Boutigou. Ajoutez ou modifiez vos produits locaux avant de valider votre commande." />
        </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={props.orderRed.loading_step}
        >
          <Box
            component="img"
            sx={{
              height: 250,
              mb: 4
            }}
            alt="Loading..."
            src={gif_loading}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={props.orderRed.loading_method_payment}
        >
          <Box
            component="img"
            sx={{
              height: 250,
              mb: 4
            }}
            alt="Loading..."
            src={gif_loading}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={openTmpSuccess}
        >
          <Box
            component="img"
            sx={{
              height: 250,
              mb: 4
            }}
            alt="Loading..."
            src={gif_loading}
          />
        </Backdrop>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle variant="h2">Le produit n&apos;est plus disponible en quantité suffisante, merci de la diminuer.</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDeliveryOptions}>
          <DialogTitle variant="h2">Choisissez le lieu de réception de la commande parmi la liste ci-dessous:</DialogTitle>
          <DialogContent>
            <List maxWidth={false}>
            {props.orderRed.delivery_location_options.map((o, idx) => (
              <ListItemButton key={idx} onClick={() => handleGetDeliveryDayOptions(o)}>
                <ListItemIcon>
                  <PlaceIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${o.name} auprès de ${o.shop ? o.shop.name : ''}`}
                  secondary={`${o.address}, ${o.zipcode} ${o.city}`}
                />
              </ListItemButton>
            ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeliveryOptions(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeliveryDayOptions}>
          <DialogTitle variant="h2">Choisissez le jours de réception de la commande parmi la liste ci-dessous:</DialogTitle>
          <DialogContent>
            <List maxWidth={false}>
            {props.orderRed.delivery_day_options.map((o, idx) => (
              <ListItemButton key={idx} onClick={() => handleGetDeliverySlotOptions(o)}>
                <ListItemIcon>
                  <PlaceIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${moment(o.date).format('dddd, Do MMMM YYYY')}`}
                  
                />
              </ListItemButton>
            ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeliveryDayOptions(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeliverySlotOptions}>
          <DialogTitle variant="h2">Choisissez le créneau de réception de la commande parmi la liste ci-dessous:</DialogTitle>
          <DialogContent>
            <List maxWidth={false}>
            {props.orderRed.delivery_slot_options.map((o, idx) => (
              <ListItemButton key={idx} onClick={() => handleValidate(o)}>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${o.begin} - ${o.end}`}
                  
                />
              </ListItemButton>
            ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeliverySlotOptions(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>


        <Dialog open={openPay}>
          <DialogTitle variant="h2" alignItems="center" justifyContent="center" sx={{ display: 'flex', }}>Paiement</DialogTitle>
          <DialogContent>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm props={props} />
            </Elements>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {props.closeOrderPaymentIntent(props.dispatch); setOpenPay(false)}}>Fermer</Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={() => setOpenValidateError(false)} open={openValidateError}>
          <DialogTitle variant="h2" alignItems="center" justifyContent="center" sx={{ display: 'flex', }}>{props.orderRed.errors.null ? props.orderRed.errors.null.message : null}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenValidateError(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>
        
        <Grid item xs={12} md={6}>
          <Grid container spacing={gridSpacing}>
            {props.orderRed.cart.map((p, idx) => (
              <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                <Product
                  props={props}
                  loading={props.orderRed.loading}
                  name={p.product_name}
                  picture={p.product ? p.product.picture : null}
                  stock={p.product ? p.product.stock : null}
                  price_unit={p.price_unit}
                  quantity={p.quantity}
                  id={p.product ? p.product.id : null}
                  shop_id={p.product ? p.product.shop : null}
                  shop_name={p.shop_name}
                  cart_id={p.id}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaymentMethod
            loading={props.orderRed.loading}
            payment_method_options={props.orderRed.payment_method_options}
            cart_payment_method={props.orderRed.cart_details.payment_method}
            cart_id={props.orderRed.cart_details.id}
            props={props}
          />
          <Summary
            loading={props.orderRed.loading}
            fees={props.orderRed.cart_details.fees}
            total={props.orderRed.cart_details.total}
            total_products={props.orderRed.cart_details.total_products}
            total_variable={props.orderRed.cart_details.total_variable}
          />
          <Grid item xs={12} container >
            <Box>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel variant="h2"
                      optional={
                        index === 2 ? (
                          <Typography variant="caption">Dernière étape</Typography>
                        ) : null
                      }
                    >
                      <Typography variant="h3">{step.label}</Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Continuer
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Retour
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
          { activeStep === 3 ? (
          <Grid item xs={12} container>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Modifier
              </Button>
              { props.orderRed.cart_details.payment_method_type === false ?
              <Button onClick={handlePay} variant="contained" sx={{ mt: 1, mr: 1 }}>
                Comfirmer et payer
              </Button> :
              <Button onClick={handlePay} variant="contained" sx={{ mt: 1, mr: 1 }}>
                Confirmer
              </Button>}
            </Box>
          </Grid>) : null}
        </Grid>
      </Grid> :
      <MainCard>
      <Helmet>
        <title>Votre Panier sur Boutigou - Prêt à Commander</title>
        <meta name="description" content="Consultez votre panier sur Boutigou. Ajoutez ou modifiez vos produits locaux avant de valider votre commande." />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Typography variant="h4">Votre panier est vide.</Typography>
        </Box>
      </Box>
    </MainCard>) :
    <MainCard>
      <Helmet>
        <title>Votre Panier sur Boutigou - Prêt à Commander</title>
        <meta name="description" content="Consultez votre panier sur Boutigou. Ajoutez ou modifiez vos produits locaux avant de valider votre commande." />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="No Data"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={nodata}
        />
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2} // Ajustez la marge supérieure selon vos besoins
          >
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.dark,
              backgroundColor: theme.palette.grey50,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.dark,
                background: `${theme.palette.secondary.dark}!important`,
                color: '#fff',
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              mr: 2
            }}
            icon={
              <Avatar
                src={PersonIcon}
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label="Connexion"
            variant="outlined"
            onClick={handleAuthRequest}
          />
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.secondary.dark,
              color: '#fff',
              backgroundColor: theme.palette.secondary.dark,
              fontSize: '1rem',
              fontWeight: '700',
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.secondary.main,
                background: `${theme.palette.secondary.main}!important`,
                color: theme.palette.grey50,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              }
            }}
            label="Inscription"
            variant="outlined"
            onClick={handleRegisterRequest}
          />
        </Box>
      </Box>
    </MainCard>
  );
}