// material-ui
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function Kesako() {

    return (
      <>
        <Helmet>
          <title>Blog de Boutigou - Inspirations et Conseils Locaux</title>
          <meta name="description" content="Explorez le blog de Boutigou pour des histoires inspirantes, des conseils et des nouveautés sur la consommation locale." />
        </Helmet>
        <MainCard title="Thématiques" sx={{ mb: 4 }}>

          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
                                           
            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Alimentation et Gastronomie Locale
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/alimentation-gastronomie-locale">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
          
            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Communauté et Culture
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/communaute-culture">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Économie Locale et Développement Durable
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/economie-locale-developpement-durable">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Éducation et Sensibilisation
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/education-sensibilisation">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Les labels
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/labels">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Les métiers
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/les-metiers">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Portraits et Histoires Locales
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/portraits-histoires-locales">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Pratiques et Initiatives Durables
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/pratiques-initiatives-durables">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={6} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Soutien aux Producteurs Locaux
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="kesako/soutien-aux-producteurs-locaux">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </MainCard>
      </>
  );
}